/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC, useState } from 'react'
import { Routes, Route, HashRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import { Homepage } from '../pages/homepage/Homepage'
import { PrivacyPolicy } from '../pages/PrivacyPolicy/PrivacyPolicy'
import { TermsOfService } from '../pages/TermsOfService/TermsOfService'
import React from 'react'

/**
 * Base URL of the website.
*
* @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
*/
// const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  let { currentUser } = useAuth()

  // Check if the user is not authenticated and the current URL contains '/agent/'
  const isAgentPage = !currentUser && window.location.hash.includes('/agent/');

  // If it's an agent page, save the original URL in localStorage
  if (isAgentPage) {
    localStorage.setItem('originalUrl', window.location.hash.slice(1));
  }
  const [agentUrl, setAgentUrl] = useState<string | null>(localStorage.getItem('originalUrl'));
  return (
    <HashRouter >
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/terms-of-use' element={<TermsOfService />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to={ agentUrl ? agentUrl:'/project/agents/agents'} />} />
              <Route path='/admin' element={<Navigate to='/project/agents/agents' />} />
            </>
          ) : (
            <>
              {/* <Route index element={<Homepage />} /> */}
              <Route path='auth/*' element={<AuthPage />} />
              {/* <Route path='*' element={<Navigate to='/auth' />} /> */}
              <Route path='/auth' element={<AuthPage />} />
              <Route path='*' element={<Homepage />} />
            </>
          )}
        </Route>
      </Routes>
    </HashRouter>
  )
}

export { AppRoutes }

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { HeaderWrapper } from './components/header'

import { ScrollTop } from './components/scroll-top'
import { Content } from './components/content'
import { FooterWrapper } from './components/footer'
import { Sidebar } from './components/sidebar'
import {

  InviteUsers,
  UpgradePlan,
  ThemeModeProvider,
} from '../partials'
import { PageDataProvider } from './core'
import { reInitMenu } from '../helpers'
import { ToolbarWrapper } from './components/toolbar'
import { TeamResponse, UserTeamResponseByUser, decryptData } from '../../api'

interface props {
  projectChange: boolean;
  setProjectChange: Dispatch<SetStateAction<boolean>>;
  agentUpdate: boolean;
  setAgentUpdate: Dispatch<SetStateAction<boolean>>;
  updateThreadList: boolean;
  setUpdateChatlog: Dispatch<SetStateAction<boolean>>;
  updatechatlog: boolean;
  enableEdit: boolean;
  agentUpdateAfterDelete: boolean
  agentUpdateAfterEdit: boolean
  setUpdateThreadList:  Dispatch<SetStateAction<boolean>>;
  setIsLoadingChat: Dispatch<SetStateAction<boolean>>;
  isLoadingChat: boolean
  setCurrentTab: Dispatch<SetStateAction<string>>;
  setUpdateThreadName: Dispatch<SetStateAction<boolean>>;
  updateThreadName: boolean;
  setRefreshChatPage: Dispatch<SetStateAction<boolean>>;
  refreshChatPageOnAgentCreation:boolean;
}


const MasterLayout = ({refreshChatPageOnAgentCreation,setRefreshChatPage, updateThreadName, setUpdateThreadName, setIsLoadingChat,  isLoadingChat, setUpdateThreadList, setCurrentTab, agentUpdateAfterEdit, enableEdit, updatechatlog, setUpdateChatlog, updateThreadList, projectChange, setProjectChange, agentUpdate, setAgentUpdate, agentUpdateAfterDelete }: props) => {
  // const cookies = new Cookies();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')))
  const [team, setTeam] = useState<TeamResponse | null>(null);
  const [triggerScrollBottom, setTriggerScrollBottom] = useState(false)

  useEffect(() => {
    reInitMenu()
  }, [location.key])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam === 'chat' && !isLoadingChat) {
      setTriggerScrollBottom(true)
    }
  }, [location.search,isLoadingChat])

  return (
    <PageDataProvider>
      <ThemeModeProvider>
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>

            <HeaderWrapper refreshChatPageOnAgentCreation={refreshChatPageOnAgentCreation} setRefreshChatPage={setRefreshChatPage} setUpdateThreadName={setUpdateThreadName} updateThreadName={updateThreadName} setCurrentTab={setCurrentTab} setIsLoadingChat={setIsLoadingChat} isLoadingChat={isLoadingChat} setUpdateThreadList={setUpdateThreadList} agentUpdateAfterEdit={agentUpdateAfterEdit} agentUpdateAfterDelete={agentUpdateAfterDelete} enableEdit={enableEdit} updatechatlog={updatechatlog} setUpdateChatlog={setUpdateChatlog} updateThreadList={updateThreadList} agentUpdate={agentUpdate} setAgentUpdate={setAgentUpdate} isEmbedding={shareQueryParam !== "Allow-Embed-Only"} />
            <div className='app-wrapper ' id='kt_app_wrapper' >

              {shareQueryParam !== "Allow-Embed-Only" && <Sidebar setProjectChange={setProjectChange} projectChange={projectChange} team={team} setTeam={setTeam} />}
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>

                  <ToolbarWrapper />
                  <Content>

                    <Outlet />

                  </Content>
                </div>
                <FooterWrapper />
              </div>
            </div>
          </div>
        </div>

        {/* begin:: Drawers */}
        {/* <ActivityDrawer /> */}
        {/* <RightToolbar /> */}
        {/* <DrawerMessenger /> */}
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        {/* <InviteUsers  /> */}
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop setTriggerScrollBottom={setTriggerScrollBottom} triggerScrollBottom={triggerScrollBottom}/>
      </ThemeModeProvider>
    </PageDataProvider>
  )
}

export { MasterLayout }


import styles from './style.module.css'
import {useState, useEffect, SetStateAction} from 'react'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import {MostActiveAgents, MostActiveUsers, RecentlyAddedusers} from '../../../../../api/models'
import {getActiveAgents, getActiveUsers, getRecentUsers} from '../../../../../api/api'
import {stringAvatar} from '../../../../../api'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Tooltip,
} from '@mui/material'

export const Tables = () => {
  const [show, setShow] = useState('10')
  const [activeAgents, setActiveAgents] = useState<MostActiveAgents[]>([])
  const [recentUsers, setRecentUsers] = useState<RecentlyAddedusers[]>([])
  const [activeUsers, setActiveUsers] = useState<MostActiveUsers>()

  const [selectedOption, setSelectedOption] = useState('recentUsers')

  const handleChange = (event) => {
    setSelectedOption(event.target.value)
  }
  useEffect(() => {
    getActiveAgent()
    getRecentlyAddedUser()
    getMostActiveUser()
  }, [])

  const getActiveAgent = async () => {
    try {
      const response = await getActiveAgents()
      if (response.length) {
        setActiveAgents(response)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const getRecentlyAddedUser = async () => {
    try {
      const response = await getRecentUsers()
      if (response.length) {
        setRecentUsers(response)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const handleCategoryChange = (event: {target: {value: SetStateAction<string>}}) => {
    setShow(event.target.value)

    if (event.target.value == '10') {
      setShow('10')
    } else if (event.target.value == '20') {
      setShow('20')
    } else {
      setShow('30')
    }
  }
  const getMostActiveUser = async () => {
    try {
      const response = await getActiveUsers(10)
      if (response.user_engagement.length > 0) {
        setActiveUsers(response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const data = {
    recentUsers: recentUsers.map((user, index) => ({
      fullName: `${user.first_name || 'Anonymous User'} ${user.last_name || ''}`,
      email: user.email,
    })),
    mostActiveUsers: activeUsers?.user_engagement.map((user, index) => ({
      fullName: `${user.user_fname || 'Anonymous User'} ${user.user_lname || ''}`,
      email: user.user_email,
    })),
    recentAgents: activeAgents.slice(0, 5).map((agent, index) => ({
      fullName: agent?.agent_name,
      email: agent.domain,
      createdBy: agent.created_by,
    })),
  }

  return (
    <>
      

      <div className={styles.container}>
        <Select
          value={selectedOption}
          onChange={handleChange}
          displayEmpty
          inputProps={{'aria-label': 'Select Option'}}
          sx={{marginBottom: 2, width: '200px'}} // Adjust width as needed
        >
          <MenuItem value='recentUsers'>Recently Added Users</MenuItem>
          <MenuItem value='mostActiveUsers'>Most Active Users</MenuItem>
          <MenuItem value='recentAgents'>Recent Agents</MenuItem>
        </Select>

        <TableContainer
          style={{width: '70%', marginLeft: '12%'}}
          component={Paper}
          sx={{marginTop: 2}}
        >
          <Table>
            <TableHead>
              <TableRow>
                {selectedOption === 'recentAgents' ? (
                  <>
                    <TableCell align='center'>Agent Name</TableCell>
                    <TableCell align='center'>Description</TableCell>
                    <TableCell align='center'>Created By</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>Email</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
            {data[selectedOption] && data[selectedOption].length > 0 ? (
              data[selectedOption].map((row, index) => (
                <TableRow key={index} >
                  <TableCell style={{border: '1px solid lightgrey', padding:"10px"}}>
                    <div style={{display: 'flex', alignItems: 'center', marginLeft: '5%'}}>
                      <Avatar >{`${row.fullName.split(' ')[0][0]?.toUpperCase()}${
                        row.fullName.split(' ')[1]?.[0]?.toUpperCase() || ''
                      }`}</Avatar>

                      <div style={{marginLeft: '8px'}}>
                        <Typography variant='body1'>{row.fullName}</Typography>
                      </div>
                    </div>
                  </TableCell>

                  <TableCell style={{border: '1px solid lightgrey', textAlign: 'center'}}>
                    <Typography variant='body2'>{row.email}</Typography>
                  </TableCell>
                  {selectedOption === 'recentAgents' && (
                    <>
                   
                      <TableCell align='center' style={{border: '1px solid lightgrey'}}>
                        {row.createdBy}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              )))
              : (
                <TableRow>
                  <TableCell colSpan={selectedOption === 'recentAgents' ? 3 : 2} align='center'>
                    No data available
                  </TableCell>
                </TableRow>
              )
            }
              
            </TableBody>
          </Table>
        </TableContainer>
      </div>


      <div className={`  ${styles.tablesSm} mt-8 `}>
        <div className={`justify-content-evenly flex-wrap`}>
          <Box className={`${styles.dropdown} me-3`}>
            <FormControl fullWidth>
              <InputLabel id='Categorylabel'>Category</InputLabel>
              <Select
                labelId='Categorylabel'
                id='Category'
                value={show}
                label='Category'
                onChange={handleCategoryChange}
              >
                <MenuItem value={10}>Recently Added</MenuItem>
                <MenuItem value={20}>Most Active Users</MenuItem>
                <MenuItem value={30}>Recent Agents</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>

        {show == '10' && (
          <div className={`mt-8 ${styles.table}`}>
            <p className={`${styles.header}`}>Recently </p>
            <span className={`${styles.prompt}`}>These are the recently added users.</span>
            <div className={`${styles.hr}`}></div>
            {recentUsers.map((user, index) => {
              const fullName = `${user.first_name} ${user.last_name}`
              const lineHeight = fullName.length > 20 ? '10px' : '0'
              const marginBottom = fullName.length > 20 ? '0px' : '1rem'
              const marginTop = fullName.length > 20 ? '-9px' : '0'
              return (
                <div key={index} className={`d-flex `}>
                  <Avatar {...stringAvatar(`${user?.first_name} ${user?.last_name}`)} />
                  <div
                    className={`mt-3 ms-2 d-flex flex-column justify-content-center`}
                    style={{flexShrink: 0, flexGrow: 1}}
                  >
                    <p
                      style={{
                        lineHeight: lineHeight,
                        marginBottom: marginBottom,
                        marginTop: marginTop,
                      }}
                    >
                      {fullName}
                    </p>
                    <p className={styles.email}>{user.email}</p>
                  </div>
                  <div className={`${styles.hr}`}></div>
                </div>
              )
            })}
          </div>
        )}

        <div className={`${styles.vr}`}></div>
        {show == '20' && (
          <div className={`mt-8 ${styles.table}`}>
            <p className={`${styles.header}`}>Most Active Users</p>
            <span className={`${styles.prompt}`}>These are the users that are most active.</span>
            <div className={`${styles.hr}`}></div>
            {activeUsers?.user_engagement.map((user, index) => {
              const fullName = `${user.user_fname || 'User'} ${user.user_lname || ''}`
              const lineHeight = fullName.length > 20 ? '10px' : '0'
              const marginBottom = fullName.length > 20 ? '0px' : '1rem'
              const marginTop = fullName.length > 20 ? '-9px' : '0'
              return (
                <div key={index} className={`d-flex `}>
                  <Avatar {...stringAvatar(`${user?.user_fname} ${user?.user_lname}`)} />

                  <div
                    className={`mt-3 ms-2 d-flex flex-column justify-content-center`}
                    style={{flexShrink: 0, flexGrow: 1}}
                  >
                    <p
                      style={{
                        lineHeight: lineHeight,
                        marginBottom: marginBottom,
                        marginTop: marginTop,
                      }}
                    >
                      {fullName}
                    </p>
                    <span className={styles.email}>{user.user_email}</span>
                  </div>
                  <div className={`${styles.hr}`}></div>
                </div>
              )
            })}
          </div>
        )}

        <div className={`${styles.vr}`}></div>
        {show == '30' && (
          <div className={`mt-8 ${styles.table}`}>
            <p className={`${styles.header}`}>Recent Agents</p>
            <span className={`${styles.prompt}`}>These are the agents that are most active.</span>
            <div className={`${styles.hr}`}></div>
            {activeAgents.slice(0, 5).map((agent, index) => {
              const lineHeight = agent?.agent_name.length > 32 ? '15px' : '0'
              const marginBottom = agent?.agent_name.length > 32 ? '0px' : '1rem'
              const marginTop = agent?.agent_name.length > 32 ? '-9px' : '0'
              return (
                <div key={index}>
                  <div className={`d-flex m-3`}>
                    <Avatar>{agent?.agent_name[0]?.toUpperCase()}</Avatar>
                    <div className={`mt-3 ms-2 d-flex flex-column justify-content-center`}>
                      <p
                        style={{
                          lineHeight: lineHeight,
                          marginBottom: marginBottom,
                          marginTop: marginTop,
                        }}
                      >
                        {agent?.agent_name}
                      </p>
                      {/* <span className={styles.email}>job Function</span> */}
                      <Tooltip title={`${agent?.domain}`}>
                        <span className={styles.email}>
                          {agent.domain.length > 43
                            ? agent.domain.substring(0, 43) + '...'
                            : agent.domain}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={`${styles.hr}`}></div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}

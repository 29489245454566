import clsx from 'clsx'
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'
import { ILayout, useLayout } from '../../core'
import { SidebarMenu } from './sidebar-menu/SidebarMenu'
import { SidebarFooter } from './SidebarFooter'
import { SidebarLogo } from './SidebarLogo'
import { TeamResponse } from '../../../../api'
import { Link } from 'react-router-dom'
import styles from './sidebar-menu/Sidebar.module.css'

interface Props {
  team: TeamResponse | null;
  setTeam: Dispatch<SetStateAction<TeamResponse | null>>
  projectChange: boolean;
  setProjectChange: Dispatch<SetStateAction<boolean>>
}

interface Team {
  teamData: TeamResponse | null;
  setTeam: Dispatch<SetStateAction<TeamResponse | null>>
}

// Create the context
export const UserContext = createContext<Team | undefined>(undefined);

const Sidebar = ({ team, setTeam, projectChange, setProjectChange }: Props) => {
  const { config } = useLayout()
  const [toggle, setToggle] = useState<boolean>(false);



  useEffect(() => {
    updateDOM(config)
  }, [config])

  if (!config.app?.sidebar?.display) {
    return null
  }
  const contextValue: Team = {

    teamData: team,
    setTeam: setTeam
  };


  return (


    <div id='kt_app_sidebar' className={clsx('app-sidebar', config.app?.sidebar?.default?.class)} style={{ width: "19.5rem", maxHeight: '100%', backgroundColor:"#f9f9f9" }}>
      <div style={{backgroundColor:"white"}}>

      <SidebarLogo setToggle={setToggle} toggle={toggle} />
      </div>
      <div style={{
        height: 'fit-content', maxHeight: '100vh', overflowY: 'scroll', overflowX: 'hidden', backgroundColor: "#f9f9f9"}}>
        <UserContext.Provider value={contextValue}>
          <SidebarMenu setToggle={setToggle} toggle={toggle} projectChange={projectChange} setProjectChange={setProjectChange} />
          {/* <SidebarFooter /> */}
        </UserContext.Provider>
      </div>
      {toggle ? <></> :

<div style={{ backgroundColor: "#f9f9f9" }} className={`pb-7 pt-3 ps-2 pe-14 p-fixed bottom-0 ${styles.bar}`}>
  <span style={{ color: '#9D9DA6', fontSize: '12px' }}>Version: 1.10.0</span>
  <div className=' text-muted' style={{ fontSize: '0.8rem' }}><Link to='/privacy-policy'>Privacy Policy</Link> | <Link to='/terms-of-use'>Terms of Use</Link></div>
</div>
}
      
    </div>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.sidebar?.default?.minimize?.desktop?.enabled) {
    if (config.app?.sidebar?.default?.minimize?.desktop?.default) {
      document.body.setAttribute('data-kt-app-sidebar-minimize', 'on')
    }

    if (config.app?.sidebar?.default?.minimize?.desktop?.hoverable) {
      document.body.setAttribute('data-kt-app-sidebar-hoverable', 'false')
    }
  }

  if (config.app?.sidebar?.default?.minimize?.mobile?.enabled) {
    if (config.app?.sidebar?.default?.minimize?.mobile?.default) {
      document.body.setAttribute('data-kt-app-sidebar-minimize-mobile', 'on')
    }

    if (config.app?.sidebar?.default?.minimize?.mobile?.hoverable) {
      document.body.setAttribute('data-kt-app-sidebar-hoverable-mobile', 'true')
    }
  }

  if (config.app?.sidebar?.default?.collapse?.desktop?.enabled) {
    if (config.app?.sidebar?.default?.collapse?.desktop?.default) {
      document.body.setAttribute('data-kt-app-sidebar-collapse', 'on')
    }
  }

  if (config.app?.sidebar?.default?.collapse?.mobile?.enabled) {
    if (config.app?.sidebar?.default?.collapse?.mobile?.default) {
      document.body.setAttribute('data-kt-app-sidebar-collapse-mobile', 'on')
    }
  }

  if (config.app?.sidebar?.default?.push) {
    if (config.app?.sidebar?.default?.push?.header) {
      document.body.setAttribute('data-kt-app-sidebar-push-header', 'true')
    }

    if (config.app?.sidebar?.default?.push?.toolbar) {
      document.body.setAttribute('data-kt-app-sidebar-push-toolbar', 'true')
    }

    if (config.app?.sidebar?.default?.push?.footer) {
      document.body.setAttribute('data-kt-app-sidebar-push-footer', 'true')
    }
  }

  if (config.app?.sidebar?.default?.stacked) {
    document.body.setAttribute('app-sidebar-stacked', 'true')
  }

  document.body.setAttribute('data-kt-app-sidebar-enabled', 'true')
  document.body.setAttribute(
    'data-kt-app-sidebar-fixed',
    config.app?.sidebar?.default?.fixed?.desktop?.toString() || ''
  )

  const appSidebarDefaultDrawerEnabled = config.app?.sidebar?.default?.drawer?.enabled
  let appSidebarDefaultDrawerAttributes: { [attrName: string]: string } = {}
  if (appSidebarDefaultDrawerEnabled) {
    appSidebarDefaultDrawerAttributes = config.app?.sidebar?.default?.drawer?.attributes as {
      [attrName: string]: string
    }
  }

  const appSidebarDefaultStickyEnabled = config.app?.sidebar?.default?.sticky?.enabled
  let appSidebarDefaultStickyAttributes: { [attrName: string]: string } = {}
  if (appSidebarDefaultStickyEnabled) {
    appSidebarDefaultStickyAttributes = config.app?.sidebar?.default?.sticky?.attributes as {
      [attrName: string]: string
    }
  }

  setTimeout(() => {
    const sidebarElement = document.getElementById('kt_app_sidebar')
    // sidebar
    if (sidebarElement) {
      const sidebarAttributes = sidebarElement
        .getAttributeNames()
        .filter((t) => t.indexOf('data-') > -1)
      sidebarAttributes.forEach((attr) => sidebarElement.removeAttribute(attr))

      if (appSidebarDefaultDrawerEnabled) {
        for (const key in appSidebarDefaultDrawerAttributes) {
          if (appSidebarDefaultDrawerAttributes.hasOwnProperty(key)) {
            sidebarElement.setAttribute(key, appSidebarDefaultDrawerAttributes[key])
          }
        }
      }

      if (appSidebarDefaultStickyEnabled) {
        for (const key in appSidebarDefaultStickyAttributes) {
          if (appSidebarDefaultStickyAttributes.hasOwnProperty(key)) {
            sidebarElement.setAttribute(key, appSidebarDefaultStickyAttributes[key])
          }
        }
      }
    }
  }, 0)
}

export { Sidebar }

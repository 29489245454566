import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CodeIcon from '@mui/icons-material/Code';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { toAbsoluteUrl } from '../../../helpers'

import { encryptData, getOneAgent, decryptData, getUserAgentPermission, fetchLoginCookie, getChatEmbedDomain, getShareAgentFlag, getUserRoleByAgentId ,getOneAgentForMagicLink} from '../../../../api';

import './ShareButton.css';
import { useLocation, useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Link, Info } from "@phosphor-icons/react";


interface Props {
  agentAccess: string;
}

export const ShareButton = ({ agentAccess }: Props) => {
  const { agentId } = useParams()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const OpenInNewTabText = 'Open in chat widget'
  const embedText = 'Embed this agent'
  const [AudienceText, setAudienceText] = useState<string>('Anyone with the link can');
  const [embedCopied, setEmbedCopied] = useState(false);
  const [isSystemAgent, setIsSystemAgent] = useState<boolean>(false);
  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const [userEditPermission, setUserEditPermission] = useState<boolean>(false)
  const [agentCreator, setAgentCreator] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isSuper, setIsSuper] = useState(false);
  const [chatEmbedDomain, setChatEmbedDomain] = useState<string>("");
  const [shareFlag, setShareFlag] = useState(false)
  const disabledTooltipMessage = "To enable this option, please set the access level to 'Public'.";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));
  const [userRole, setUserRole] = useState<string>('')


  useEffect(() => {
    fetchChatDomain()
    fetchShareAgentFlag()
  }, [])

  const getUserRoleByAgent = async (activeAgentID: number) => {
    try {
      const response = await getUserRoleByAgentId(activeAgentID)
      if (response) {
        setUserRole(response.role)
      }
    }
    catch (e) {
      console.error(e)
    }
  }
  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const agentID = decodeURIComponent(decryptData(agentId));
    const activeAgentID = parseInt(agentID, 10);
    getUserRoleByAgent(activeAgentID)
  }, [agentId])


  const fetchShareAgentFlag = async () => {

    const response = await getShareAgentFlag()
    setShareFlag(response)
  }

  const fetchChatDomain = async () => {
    const response = await getChatEmbedDomain()
    setChatEmbedDomain(response)
  }

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userEmail = decryptedRes?.user;
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";

    if (isSuperUser) {
      setIsSuper(true);
    }
    // checkIfSytemAgent();
    fetchUserActiveAgentConfig()
    setEmail(userEmail)
  }, []);


  // const checkIfSytemAgent = async () => {
  //   if (agentId) {
  //     const agentID = decodeURIComponent(decryptData(agentId));
  //     const parsedAgentID = parseInt(agentID, 10);
  //     try {
  //       const response = await getOneAgent(parsedAgentID);
  //       if (response) {
  //         setIsSystemAgent(response.created_by === "system" || response.created_by === "System")
  //       }
  //     } catch {
  //       console.error("Can not fetch the agent.")
  //     }

  //   }
  // }

  
  const isDropdownDisabled = agentAccess === "Only me" || agentAccess === "Only team";
  const toggleDropdown = (event: any) => {
    if (!isDropdownDisabled) { // Prevent dropdown from toggling if disabled
      setDropdownOpen(!dropdownOpen);
      event.preventDefault();
    }
  };

  const resetAudienceText = () => {
    setAudienceText("Anyone with the link can")
  };

  const resetEmbedText = () => {
    setEmbedCopied(false);
  };

  const handleViewButtonClick = async (event: any) => {
    try {
      event.preventDefault();
      const textToCopy = window.location.href + "&share=" + encodeURIComponent(encryptData("Allow-View-Only"));
      await navigator.clipboard.writeText(textToCopy);
      setDropdownOpen(false);
      setAudienceText("View Only link Copied!");
      setTimeout(resetAudienceText, 3000);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };
  const handleEditButtonClick = async (event: any) => {
    try {
      event.preventDefault();
      const textToCopy = window.location.href + "&share=" + encodeURIComponent(encryptData("Allow-Edit-Only"));
      await navigator.clipboard.writeText(textToCopy);
      setDropdownOpen(false);
      setAudienceText("Edit Only link Copied!");
      setTimeout(resetAudienceText, 3000);
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  };

  const handleEmbedButtonClick = async (event: any) => {
    try {
      if (chatEmbedDomain) {
        event.preventDefault();
        const currentHost = window.location.host;

        const copyHost = chatEmbedDomain;


        const updatedURL = window.location.href.replace(currentHost, copyHost).replace('/#', '');
        const updatedURLnew = updatedURL.replace('http://', 'https://');
        const agentURL = `https://${chatEmbedDomain}/agent/` + agentId
        const cssURL = `https://${chatEmbedDomain}/style.css`;
        const jsURL = `https://${chatEmbedDomain}/style.js`;
        const textToCopy = `
    <div id="chatWidget">
    <div id="chatBtn" onmouseover="this.style.transform = 'scale(1.1)';" onmouseout="this.style.transform = 'scale(1)';" onclick="toggleChat()">
    <img id="chatImg" src="https://chat.ejento.ai/chat_embed_image.png" alt="" />
    </div>
    
    <div id="mainChatContainer">
    <div id="resize-btn" onclick="resizeWidget()" title="Resize">
            <svg id="normal-svg" xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 448 512" onmouseover="this.style.transform = 'scale(1.1)';"
         onmouseout="this.style.transform = 'scale(1)';">
                <path d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"/>
            </svg>
            <svg id="resized-svg" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 448 512" style="display:none;" onmouseover="this.style.transform = 'scale(1.1)';"
         onmouseout="this.style.transform = 'scale(1)';">
                <path d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z"/>
            </svg>
        </div>

        <div id="chatContainer">
          <iframe id="chatIframe" src="${agentURL}" allow="clipboard-read; clipboard-write"></iframe>
        </div>
    </div>

    <link rel="stylesheet" type="text/css" href="${cssURL}">
    <script src="${jsURL}"></script>
    </div>
    `;

        await navigator.clipboard.writeText(textToCopy);
        setDropdownOpen(false);
        setEmbedCopied(true);
        setTimeout(resetEmbedText, 3000);
      }
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  }

  const handleOpenInNewTabButtonClick = async (event: any) => {
    try {
      if (chatEmbedDomain) {

        event.preventDefault();

        const embedejentolink = `https://${chatEmbedDomain}/agent/` + agentId
        window.open(embedejentolink, '_blank');
        setDropdownOpen(false);
        setTimeout(resetEmbedText, 3000);
      }
    } catch (error) {
      console.error('Error copying to clipboard:', error);
    }
  }
  const fetchUserActiveAgentConfig = async () => {
    try {
      if (agentId) {
        const agentID = decodeURIComponent(decryptData(agentId));
        const parsedAgentID = parseInt(agentID, 10);
        let agent;
        if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
          agent = await getOneAgentForMagicLink(parsedAgentID)
        }
        else {
          agent = await getOneAgent(parsedAgentID)
        }
        if (agent) {
          setIsSystemAgent(agent.created_by === "system" || agent.created_by === "System")
          setAgentCreator(agent.created_by)
        }
        getUserEditPermission(parsedAgentID);
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getUserEditPermission = async (agent_id: number) => {
    try {
      const response = await getUserAgentPermission(agent_id);
      if (response.length > 0) {
        setUserEditPermission(true);
      }
    } catch (error) {
      console.error(error);
    }
  }



  return (
    <React.Fragment>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column w-300px w-lg-350px'
        data-kt-menu='true'
      >
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          style={{ backgroundImage: `url('${toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`, cursor: "default" }}
        >
          <h4 style={{ marginRight: "auto" }} className='text-white px-9 mt-10 mb-6 fw-light'>Share</h4>
        </div>
        <div className='tab-content' style={{ minHeight: "6rem" }}>
          <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div style={{ display: "flex", flexDirection: "column", margin: "1rem", padding: "1rem" }}>
              <Tooltip title={isDropdownDisabled ? disabledTooltipMessage : ''}>
                <div className={`glow-element ${isDropdownDisabled ? 'disabled' : ''}`} onClick={toggleDropdown}
                  title={isDropdownDisabled ? disabledTooltipMessage : undefined}>

                  <Dropdown autoClose="inside" show={dropdownOpen}>
                    <LanguageOutlinedIcon />
                    <span style={{ marginLeft: "1rem", color: AudienceText !== "Anyone with the link can" ? 'rgb(32,128,141)' : activeTheme === 'dark' ? 'white' : 'initial' }}>{AudienceText}</span>
                    <KeyboardArrowDownOutlinedIcon style={{ marginLeft: "0.3rem", color: "blue", cursor: "pointer" }} />
                    <Dropdown.Menu style={{ position: "relative", left: "0", marginTop: "0.5rem" }}>

                      <Dropdown.Item disabled={isDropdownDisabled} onClick={handleViewButtonClick}><Link size={14} weight="light" style={{ marginRight: "0.5rem" }} /> View Only  <Tooltip title='Anyone with this link can only chat with your agent after sign-in'><Info size={16} weight="light" style={{ marginLeft: "0.5rem" }} /></Tooltip></Dropdown.Item>

                      {(!isSystemAgent) && (userEditPermission === true || agentCreator === email || isSuper || userRole === 'Owner')

                        &&
                        <Dropdown.Item disabled={isDropdownDisabled} onClick={handleEditButtonClick}><Link size={14} weight="light" style={{ marginRight: "0.5rem" }} /> Edit Only <Tooltip title='Anyone with this link can chat, edit agent and access knowledge base after sign-in'><Info size={16} weight="light" style={{ marginLeft: "0.5rem" }} /></Tooltip></Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Tooltip>
              {shareFlag && <div>
                <div className="separator-line" style={{ marginTop: "3rem", padding: "1rem" }}>
                  <span className="or-text">OR</span>
                </div>

                {/* <div style={{ display: "flex", alignItems: "center" }}> */}
                <Tooltip title={isDropdownDisabled ? disabledTooltipMessage : ''}>
                  <div style={{ display: "flex", alignItems: "center" }} className={`glow-element ${isDropdownDisabled ? 'disabled' : ''}`}
                    onClick={!isDropdownDisabled ? handleEmbedButtonClick : undefined}>
                    <CodeIcon />

                    <span style={{ marginLeft: '0.8rem', color: embedCopied ? 'rgb(32,128,141)' : activeTheme === 'dark' ? 'white' : 'initial' }}> {embedCopied ? 'Embed Code Copied!' : embedText}</span>
                    <Tooltip title='Unlock integration with your website by pasting the embed script into your HTML code to chat with agent from anywhere, anytime'><Info size={16} weight="light" style={{ marginLeft: "0.5rem" }} /></Tooltip>


                  </div>
                </Tooltip>
                <br></br>
                {/* <div style={{ borderRight: '1px solid #ccc', height: '30px', marginRight: '1rem' }}></div> */}


                <Tooltip title={isDropdownDisabled ? disabledTooltipMessage : ''}>
                  <div style={{ display: "flex", alignItems: "center" }} className={`glow-element ${isDropdownDisabled ? 'disabled' : ''}`}
                    onClick={!isDropdownDisabled ? handleOpenInNewTabButtonClick : undefined}>
                    <OpenInNewIcon />
                    <span style={{ marginLeft: '0.8rem', color: activeTheme === 'dark' ? 'white' : 'initial' }}>
                      {OpenInNewTabText}
                    </span>

                    <Tooltip title='Engage with agent effortlessly in a sleek, user-friendly, public interface designed for seamless interactions'><Info size={16} weight="light" style={{ marginLeft: "0.5rem" }} /></Tooltip>
                  </div>
                </Tooltip></div>}

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

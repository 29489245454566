import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { KTSVG } from "../../../../_metronic/helpers"
import { Card2 } from "../../../../_metronic/partials/content/cards/Card2";
import { StarredAgentResponse, getStarredAgents, RatingsResponse, decryptData, fetchLoginCookie, getAllRatings } from '../../../../api';
import bot from '../../../../media_dsd/bot.png'
import CircularProgress from '@mui/material/CircularProgress';
import BotDark from '../../../../media_dsd/bot_white.png'
import styles from '../../profile/ProfileStyle.module.css';
import style from './agents.module.css'

interface props {
    setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>;
}
const StarredAgent = ({setRefreshChatPageOnAgentCreation}:props) => {

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [loggedInUserId, setLoggedInUserId] = useState<number>();
    const [ispromptDataLoading, setispromptDataLoading] = useState(true);
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [allRatings, setRatingsData] = useState<RatingsResponse[]>([]);
    const [starredAgents, setStarredAgents] = useState<StarredAgentResponse[]>([]);
    const width = window.innerWidth;


    useEffect(() => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);

        if (userId) {
            setLoggedInUserId(userId);
            getStarredAgent(userId);
            getAllAgentRatings()
        }

    }, []);

    const getAllAgentRatings = async () => {
        try {
            const response = await getAllRatings();
            setRatingsData(response);
        } catch (error) {
            console.error(error);

        }
    };

    const getStarredAgent = async (id: any) => {

        try {
            const response = await getStarredAgents(id);

            setStarredAgents(response);
            setispromptDataLoading(false)
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className={style.mb3}>

            <p className={`mt-6 text-muted ${style.smHeading}`}>Browse Your Starred Agents</p>
            <div className={`${styles.agentCardMain} row g-6 g-xl-9`} style={{flexDirection: "column", alignItems: "center"}} >
            <div className='row g-3 g-xl-9' style={{ justifyContent: "center", display: "flex", flexWrap: "wrap" }}>

                {!ispromptDataLoading && loggedInUserId ? (
                    <>
                        {starredAgents.length > 0 && starredAgents

                            .filter((item) => {
                                // Filter agents based on search query
                                return item.agent.agent_name.toLowerCase().includes(searchTerm.toLowerCase());

                            })
                            .map((item: StarredAgentResponse, index: number) => {
                                // Render other items except the activated one
                                return (
                                    <div  className={`${styles.agentCardContainer} row-cols-1 row-cols-sm-2 row-cols-lg-3`} >
                                        <Card2
                                            setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                                            agentUpdateAfterDelete={false}
                                            key={index}
                                            data={item.agent}
                                            currentUserId={loggedInUserId!}
                                            viewMode='default'
                                            allRatings={allRatings}
                                            setRatingsData={setRatingsData}
                                        /></div>
                                );
                            })}</>) : (
                    <div style={{ width: '5rem', margin: 'auto', marginTop: '6rem' }}>
                        <CircularProgress /></div>
                )}

            </div>
            </div>

        </div>
    )
}
export { StarredAgent }
import { lazy, FC, Suspense, useState, useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import ProfilePage from '../modules/profile/ProfilePage'
import Configure from '../modules/configure/configure'
import { LandingProjectPage } from '../modules/profile/LandingProjectPage'
import { TeamAgent } from '../modules/apps/TeamAgents/TeamAgents'
import { RecentAgent } from '../modules/apps/TeamAgents/RecentAgents'
import { StarredAgent } from '../modules/apps/TeamAgents/StarredAgents'
import { Faq } from '../pages/FAQ/faqs'
import { CategorizedQuestion } from '../pages/FAQ/CategorizedQuestion'
import { Tutorial } from '../../_metronic/layout/components/header/Tutorial'
import { ConfigureAgent } from '../modules/profile/components/ConfigureAgent'
import { decryptData, fetchLoginCookie } from '../../api'


const PrivateRoutes = () => {
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const [projectChange, setProjectChange] = useState<boolean>(false);
  const [agentUpdate, setAgentUpdate] = useState<boolean>(false);
  const [agentUrl, setAgentUrl] = useState<string | null>(localStorage.getItem('originalUrl'));
  const [updateThreadList, setUpdateThreadList] = useState<boolean>(false);
  const [updatechatlog, setUpdateChatlog] = useState<boolean>(false);
  const [enableEdit, setEnableEdit] = useState<boolean>(false);
  const [agentUpdateAfterDelete, setAgentUpdateAfterDelete] = useState<boolean>(false);
  const [agentUpdateAfterEdit, setAgentUpdateAfterEdit] = useState<boolean>(false);
  const [isLoadingChat, setIsLoadingChat] = useState(true);
  const [currentTab, setCurrentTab] = useState<string>('chat')
  const [hasNameError, setHasNameError] = useState(false)
  const [isNameExists, setIsNameExists] = useState<boolean>(false)
  const [fetchChatlogs, setFetchChatlogs] = useState<boolean>(false)
  const [isSuper, setIsSuper] = useState<string | null>(null);
  const [isStaff, setIsStaff] = useState(false);
  const [updateThreadName, setUpdateThreadName] = useState<boolean>(false);
  const [refreshChatPage, setRefreshChatPage] = useState<boolean>(false)
  const [refreshChatPageOnAgentCreation, setRefreshChatPageOnAgentCreation] = useState<boolean>(false)


  
  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
    const isStaffUser = decryptedRes?.is_staff?.toLowerCase() === "true";
    if (isStaffUser) {
      setIsStaff(true);
    }
    if (isSuperUser) {
      setIsSuper('yes');
    } else {
      setIsSuper('no');
    }

  }, []);



  return (
    <Routes>

      <Route element={<MasterLayout refreshChatPageOnAgentCreation={refreshChatPageOnAgentCreation} setRefreshChatPage={setRefreshChatPage} updateThreadName={updateThreadName} setUpdateThreadName={setUpdateThreadName} setCurrentTab={setCurrentTab} setIsLoadingChat={setIsLoadingChat} isLoadingChat={isLoadingChat} setUpdateThreadList={setUpdateThreadList} agentUpdateAfterEdit={agentUpdateAfterEdit} agentUpdateAfterDelete={agentUpdateAfterDelete} enableEdit={enableEdit} updatechatlog={updatechatlog} setUpdateChatlog={setUpdateChatlog} updateThreadList={updateThreadList} setAgentUpdate={setAgentUpdate} agentUpdate={agentUpdate} projectChange={projectChange} setProjectChange={setProjectChange} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={agentUrl ? agentUrl : '/project/agents/agents'} />} />
        {/* Pages */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='projects/' element={<LandingProjectPage setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} />} />

        {/* Lazy Modules */}

        <Route
          path='project/agents/*'
          element={
            <SuspensedView>
              <ProfilePage setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setCurrentTab={setCurrentTab} setAgentUpdateAfterDelete={setAgentUpdateAfterDelete} agentUpdateAfterDelete={agentUpdateAfterDelete} setProjectChange={setProjectChange} />
            </SuspensedView>
          }
        />
        <Route
          path='agent/:agentId'
          element={
            <SuspensedView>
              <ChatPage setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} refreshChatPageOnAgentCreation={refreshChatPageOnAgentCreation} refreshChatPage={refreshChatPage} setRefreshChatPage={setRefreshChatPage} updateThreadName={updateThreadName} setUpdateThreadName={setUpdateThreadName} currentTab={currentTab} setIsLoadingChat={setIsLoadingChat} isLoadingChat={isLoadingChat} agentUpdateAfterEdit={agentUpdateAfterEdit} setAgentUpdateAfterEdit={setAgentUpdateAfterEdit} setEnableEdit={setEnableEdit} updateThreadList={updateThreadList} setUpdateChatlog={setUpdateChatlog} updatechatlog={updatechatlog} setUpdateThreadList={setUpdateThreadList} agentUpdate={agentUpdate} setAgentUpdate={setAgentUpdate} />
            </SuspensedView>
          }
        />
        <Route
          path='templates/'
          element={
            <SuspensedView>
              <LandingProjectPage setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} />
            </SuspensedView>
          }
        />
        <Route
          path='teamagents/'
          element={
            <SuspensedView>
              <TeamAgent setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} />
            </SuspensedView>
          }
        />
        <Route
          path='recentagents/'
          element={
            <SuspensedView>
              <RecentAgent setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} />
            </SuspensedView>
          }
        />
        <Route
          path='starredagents/'
          element={
            <SuspensedView>
              <StarredAgent setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='faq/*'
          element={
            <SuspensedView>
              <Faq></Faq>
            </SuspensedView>
          }
        />
        <Route
          path='faq/:categoryName/:categoryId'
          element={
            <SuspensedView>
              <CategorizedQuestion />
            </SuspensedView>
          }
        />
        <Route
          path='customerSupport/:title/:tutorialId'
          element={
            <SuspensedView>
              <Tutorial />
            </SuspensedView>
          }
        />
        <Route
          path='admin/*'
          element={
            <SuspensedView>
              <Configure isStaff={isStaff} isSuper={isSuper} />
            </SuspensedView>
          } />

        {/* for new agent creation ui */}
        <Route path="/configureAgent" element={<ConfigureAgent setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setCurrentTab={setCurrentTab} isEditView={false} isNameExists={isNameExists} setIsNameExists={setIsNameExists} setHasNameError={setHasNameError} />} />
        {/* <Route path={`/agent/${agentId}`} element={<EditAgent />} /> */}

        <Route
          path='edit/:agentId'
          element={
            <SuspensedView>
              <ConfigureAgent setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setCurrentTab={setCurrentTab} isEditView={true} isNameExists={isNameExists} setIsNameExists={setIsNameExists} setHasNameError={setHasNameError} />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
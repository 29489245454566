/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import { IconUserModel } from '../../../../app/modules/profile/ProfileModels'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { createRecentAgent, AgentCardResponse, deleteAgent, RatingsResponse, RatingsRequest, setRating, deleteRating, decryptData, encryptData, fetchLoginCookie, UserAgentResponseByAgent, getUserAgent, addUsersToAgent, UserAgentRequest, deleteUserFromAgent, EditUserAgentRequest, getTeamUsersByProject, EditAgentAccessRequest, editAgentAccess, getAgentAccess, getAUser, getAllUsers, AgentCardResponse2 } from '../../../../api'
import Swal from "sweetalert2";
import { Typography, Tooltip, IconButton, ToggleButtonGroup, ToggleButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from './Card.module.css';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { Button, Checkbox, TextField, Autocomplete } from '@mui/material'
import { Modal } from 'react-bootstrap'
import DeleteIcon from '@mui/icons-material/Delete';
import { UserTeamResponseByTeam } from '../../../../api'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import { ConfigureAgent } from '../../../../app/modules/profile/components/ConfigureAgent';
import {toast } from 'react-toastify';
import defaultBotImg from '../../../../media_dsd/defaultBotImg.png'
import { ThumbsUp, ThumbsDown, DotsThree, Pencil, EnvelopeSimple } from "@phosphor-icons/react";
const activeTheme = localStorage.getItem("kt_theme_mode_value");
const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const dynamicStyle = {
  color: activeTheme === 'dark' ? 'white' : 'text.secondary',
};

type Props = {
  users?: Array<IconUserModel>
  data: AgentCardResponse2;
  currentUserId: number;
  viewMode: 'default' | 'template';
  onCloneClick?: (id: number) => void;
  allRatings: RatingsResponse[];
  setAgentData?: Dispatch<SetStateAction<AgentCardResponse2[]>>
  setAgentActiveConfig?: Dispatch<SetStateAction<number | undefined>>
  setRatingsData?: Dispatch<SetStateAction<RatingsResponse[]>>
  agentUpdateAfterDelete: boolean
  setAgentUpdateAfterDelete?: Dispatch<SetStateAction<boolean>>
  setCurrentTab?: Dispatch<SetStateAction<string>>
  userRole?: string;
  setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>
}

const Card2: FC<Props> = ({
  users = undefined,
  data,
  currentUserId,
  viewMode,
  onCloneClick,
  setAgentData,
  setAgentActiveConfig,
  allRatings,
  setRatingsData,
  setAgentUpdateAfterDelete,
  agentUpdateAfterDelete,
  setCurrentTab,
  userRole,
  setRefreshChatPageOnAgentCreation
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const navigate = useNavigate();
  const [loggedInUserId, setLoggedInUserId] = useState<number>();
  const createdOnDate = new Date(data.created_on).toLocaleDateString();
  const activeTheme = localStorage.getItem("kt_theme_mode_value");
  const [openAgentSettingsDialog, setOpenAgentSettingsDialog] = useState(false);
  const [agentUsersEmailsToAdd, setAgentUsersEmailsToAdd] = useState<string[]>([]);
  const [teamUsersData, setTeamUsersData] = useState<UserTeamResponseByTeam[]>([]);
  const [agentUsersData, setAgentUsersData] = useState<UserAgentResponseByAgent[]>([]);
  const [isSuper, setIsSuper] = useState<boolean>(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const url = window.location.href;
  const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>('')
  const [renderEditComponent, setRenderEditComponent] = useState(false);
  const [hasNameError, setHasNameError] = useState(false)
  const [isNameExists, setIsNameExists] = useState<boolean>(false)
  const [agentAccess, setAgentAccess] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [userFirstName, setUserFirstName] = useState<string>("");
  const [userLastName, setUserLastName] = useState<string>("");

  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const userId = parseInt(decryptedRes?.id);
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
    const userEmail = decryptedRes?.user;

    if(userId){
      setLoggedInUserId(userId!)
    }

    if (userEmail) {
      setLoggedInUserEmail(userEmail)
    }

    if(userId)
    {
      setLoggedInUserId(userId!)
    }

    if (isSuperUser) {
      setIsSuper(true);
    }

  },)

  const addUsersToMyAgent = async () => {
    try {
      if (agentUsersEmailsToAdd.length == 0) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please select the users from the list first ',
        });

        return;
      }
      const selectedUserIdSet = new Set(
        teamUsersData
          .filter((teamUser) => agentUsersEmailsToAdd.includes(teamUser.user.email))
          .map((teamUser) => teamUser.user.id)
      );
      const selectedUserId = [...selectedUserIdSet];
      const request: UserAgentRequest = {
        agent_id: data.id!,
        users: selectedUserId,
        created_by: data.created_by
      };
      const response = await addUsersToAgent(request);
      if (response.length) {
        setAgentUsersData(response);
        setAgentUsersEmailsToAdd([]);
        let element: HTMLElement = document.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
        element.click();
      }

    } catch (error) {
      console.error(error);
    }
  }

  const removeOneAgentUser = async (user_id: number) => {
    try {
      const request: EditUserAgentRequest = {
        agent_id: data.id!,
        user_id: user_id
      };
      const response = await deleteUserFromAgent(request);

      setAgentUsersData(response);

    } catch (error) {
      console.error(error);
    }
  }

  const getUsersOfTeamByProject = async (team_id: number) => {
    try {
      const response = await getTeamUsersByProject(team_id);
      if (response.length) {
        setTeamUsersData(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const getUsersOfAgent = async (agent_id: number) => {
    try {
      const response = await getUserAgent(agent_id);
      if (response.length) {
        setAgentUsersData(response);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleAgentActivateClick = async (id: number) => {
    const sel = teamUsersData
      .filter((teamUser) => agentUsersEmailsToAdd.includes(teamUser.user.email))
      .map((teamUser) => teamUser.user.id);
    try {
      if (setAgentActiveConfig) {
        setAgentActiveConfig(id);
      }
      const agentID = encodeURIComponent(encryptData(id));

      // Create a custom event with data
      navigate(`/agent/${agentID}?tab=chat`)
      const eventData = { agentId: id };
      setRefreshChatPageOnAgentCreation(true)
      const customEvent = new CustomEvent("activeAgent", { detail: eventData });
      // Dispatch the custom event
      window.dispatchEvent(customEvent);

    }
    catch (error) {
      console.error(error);
    }
  }

  const addRecentAgent = async (id: number) => {

    try {

      if (loggedInUserId) {
        const response = await createRecentAgent(loggedInUserId, id)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleAgentSettingsDialogOpen = () => {
    handlePopoverClose();
    const active_project = localStorage.getItem("Active-Project")?.split('-')[0]
    getUsersOfTeamByProject(parseInt(active_project!))
    getUsersOfAgent(data.id)
    getAgentAccessValue(data.id)
    setOpenAgentSettingsDialog(true);
  };

  const handleAgentSettingsDialogClose = () => {
    setOpenAgentSettingsDialog(false);
  };

  const handleAgentDeleteClick = async (id: number) => {
    const response = await deleteAgent(id);
    if (setAgentData) {
      setAgentData(response.agents);
      setAgentUpdateAfterDelete && setAgentUpdateAfterDelete(!agentUpdateAfterDelete);
    }

  };

  const handleAlertClick = () => {
    handlePopoverClose();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        handleAgentDeleteClick(data.id);
        Swal.fire(
          'Deleted!',
          'Your agent has been deleted.',
          'success'
        )
      }
    })

  };

  const handleCloneClick = () => {
    onCloneClick!(data.id)
  };

  const myAgent = useMemo(() => {
    return data?.user?.id === currentUserId ? true : false;
  }, [data.id]);


  const totalVotes = allRatings.filter((item) => item.agent === data.id && item.is_upvote).length - allRatings.filter((item) => item.agent === data.id && !item.is_upvote).length;
  const isUserUpvoted = allRatings.some((item) => item.agent === data.id && item.user === currentUserId && item.is_upvote);
  const isUserDownvoted = allRatings.some((item) => item.agent === data.id && item.user === currentUserId && !item.is_upvote);

  const handleUpvoteClick = () => {
    handleAgentUpvoteClick(data.id);
  };

  const handleDownvoteClick = () => {
    handleAgentDownvoteClick(data.id);
  };
  const handleAgentUpvoteClick = async (id: number) => {
    try {
      const existingUpvote = allRatings.find(item =>
        item.user === loggedInUserId && item.agent === id && item.is_upvote
      );

      if (existingUpvote) {
        // Make a delete call to undo the upvote
        const result = await deleteRating(existingUpvote.id);
        if (setRatingsData) {
          setRatingsData(result);
        }

        return; // Exit the function early
      }
      const userInfo = fetchLoginCookie();
      const decryptedRes = decryptData(userInfo);
      const userEmail = decryptedRes?.user;
      const request: RatingsRequest = {
        agent_id: id,
        user_id: loggedInUserId!,
        is_upvote: true,
        created_by: userEmail
      };
      const result = await setRating(request);

      if (setRatingsData) {
        setRatingsData(result);
      }
    }
    catch (error) {
      console.error(error);
    }
  };
  const handleAgentDownvoteClick = async (id: number) => {
    try {

      const existingDownvote = allRatings.find(item =>
        item.user === loggedInUserId && item.agent === id && !item.is_upvote
      );

      if (existingDownvote) {
        // Make a delete call to undo the upvote
        const result = await deleteRating(existingDownvote.id);
        if (setRatingsData) {
          setRatingsData(result);
        }
        return; // Exit the function early
      }

      const userInfo = fetchLoginCookie();
      const decryptedRes = decryptData(userInfo);
      const userEmail = decryptedRes?.user;
      const request: RatingsRequest = {
        agent_id: id,
        user_id: loggedInUserId!,
        is_upvote: false,
        created_by: userEmail
      };
      const result = await setRating(request);
      if (setRatingsData) {
        setRatingsData(result);
      }
    }
    catch (error) {
      console.error(error);

    }
  };
  const maxLength = 18; // Define the maximum length

  // Truncate the domain and industry_area if they exceed maxLength
  const truncatedDomain = data.domain.length >= 92
    ? data.domain.substring(0, 92) + '...' // Add '...' if truncated
    : data.domain;

  const truncatedIndustryArea = data.industry_area.length > maxLength
    ? data.industry_area.substring(0, maxLength) + '...' // Add '...' if truncated
    : data.industry_area;

  const truncatedAgentName = data.agent_name.length >= 37
    ? data.agent_name.substring(0, 38) + '...'
    : data.agent_name;

    const fullName = `${data?.user?.first_name} ${data?.user?.last_name}`;
    const userName = fullName.length > 23 ? fullName.slice(0, 23) + '...' : fullName;

  const handlePopoverOpen = (event) => {
    event.stopPropagation(); // Prevent event propagation to parent div
    setPopoverAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
  };

  const handleEmailClick = () => {
    navigator.clipboard.writeText(data.alias);
    handleShowToast();
  };

  const handleShowToast = () => {
    toast.success('Email Alias copied!', {
      style: {
        fontSize: '14px',

      },
    });
  };
  const handleAgentEdit = async (id: number) => {
    try {
      setRenderEditComponent(true);

      if (setAgentActiveConfig) {
        setAgentActiveConfig(id);
      }
      const agentID = encodeURIComponent(encryptData(id));
      navigate(`/edit/${agentID}`)

    }
    catch (error) {
      console.error(error);
    }
  }
  const handleAgentClick = () => {
    if (data.status === "Draft") {
      handleAgentEdit(data.id);
    } else if (viewMode === 'template') {
      handleCloneClick();
    } else {
      handleAgentActivateClick(data.id);
    }
  };
  const getAgentAccessValue = async (id: any) => {
    try {
      const response = await getAgentAccess(id);
      setAgentAccess(response.access);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEditAgentAccess = async (agentAccess: any) => {
    const request: EditAgentAccessRequest = {
      id: data.id,
      access: agentAccess
    };
    const result = await editAgentAccess(request);
    if (result) {
      setOpen(false);
    }
  }
  useEffect(() => {
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const orgId = parseInt(decryptedRes?.org_id);
  }, []);

  
  return (
    <div style={{ width: "0px", height: "0px", }}
    >
      <Modal show={openAgentSettingsDialog} onHide={handleAgentSettingsDialogClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '16px', fontWeight: '400', }}>Edit Access For Agent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-2'>
            <div className={styles.toggleButtonGroup}>
              <div className={styles.accessText}>Agent Accessibility</div>
              <Stack alignItems="left">
                <ToggleButtonGroup
                  size="small"
                  value={agentAccess}
                  exclusive
                  onChange={(event, newAccess) => {
                    if (newAccess !== null) {
                      setAgentAccess(newAccess);
                      handleEditAgentAccess(newAccess);
                    }
                  }}
                  aria-label="Access"
                  style={{ width: '100%' }}
                >
                  <ToggleButton value="Public" aria-label="public" style={{ textTransform: 'none' }}>
                    Public
                  </ToggleButton>
                  <ToggleButton value="Only team" aria-label="only-team" style={{ textTransform: 'none' }}>
                    Only team
                  </ToggleButton>
                  <ToggleButton value="Only me" aria-label="only-me" style={{ textTransform: 'none' }}>
                    Only me
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Autocomplete
                size="small"
                limitTags={3}
                disableCloseOnSelect
                style={{
                  cursor: 'pointer',
                  width: "93%",
                  backgroundColor: activeTheme === 'dark' ? '#f0f0f0' : 'inherit',
                  pointerEvents: agentAccess === 'Only me' ? 'none' : 'auto',
                  opacity: agentAccess === 'Only me' ? 0.5 : 1,
                }}
                multiple
                onChange={(event, selectedOptions) => {
                  const uniqueSelectedOptions = [...new Set(selectedOptions)];
                  if (
                    JSON.stringify(agentUsersData.sort()) !==
                    JSON.stringify(uniqueSelectedOptions.sort())
                  ) {
                    setAgentUsersEmailsToAdd(uniqueSelectedOptions);
                  }
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  const trimmedValue = newInputValue.replace(/^\s+|\s+$/g, '');
                  setInputValue(trimmedValue);
                }}
                options={teamUsersData
                  .filter((user) => user.user.email !== data.created_by)
                  .filter((user) => !agentUsersData.some((agentUser) => agentUser.user.id === user.user.id))
                  .map((user) => user.user.email)}
                sx={{
                  marginLeft: "2%",
                  ".MuiInputBase-root": {
                    borderRadius: "7px !important",
                    width: "99%",
                  },

                  
                }}
                renderInput={(params) => (




                  <TextField
                    {...params}
                    label="Add users by email"
                    size="small"
                    style={{ width: "100%" }}
                    variant={activeTheme === 'dark' ? 'filled' : 'outlined'}
                    disabled={agentAccess === 'Only me'} // Disabling input when 'Only me'

                    />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ padding: "0px", borderBottom: "0.2px solid lightgray" }}>
                    <Checkbox
                      icon={uncheckedIcon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 3 }}
                      checked={selected}
                    />
                    <span style={{ display: "flex", color: "Grey", fontSize: "1rem" }}>
                      {teamUsersData
                        .filter((user) => option.includes(user.user.email))
                        .map((user) => user.user.first_name + " " + user.user.last_name).map((name, index) => (
                          <li key={index} style={{ fontFamily: "Lexend Deca" }}>{name}</li>
                        ))} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {option}
                    </span>
                  </li>
                )}
              />
              {agentAccess === 'Only me' && (
                <div style={{ fontSize: '0.8rem', marginTop: '4px', color: 'grey', marginLeft: '2%' }}>
                  Editors cannot be added to agents with 'Only me' access.
                </div>
              )}
            </div>
          </div>
          {agentAccess !== 'Only me' && (
            <Button className='btn btn-sm' style={{

              marginTop: "2.5%",
              cursor: 'pointer',
              color: 'white',
              backgroundColor: '#20808d',
              marginLeft: "2%"
            }}

              onClick={addUsersToMyAgent}>
              Add
            </Button>
          )}
          <br />
          <br />
          <div className='mb-10' >
            <h3 style={{ marginLeft: "3%", fontSize: "16px", fontWeight: "400", lineHeight: "20px" }} className='mb-5'>
              Editors <span className="text-muted ">{"(" + agentUsersData.length + ")"}</span>
            </h3>

            <div className=' mh-300px scroll-y me-n7 pe-7'>
              {agentUsersData && agentUsersData.length > 0 && agentUsersData.map((useritem, index) => {
                return (
                  <div
                    className='d-flex flex-stack py-1 border-bottom border-gray-300 border-bottom-dashed'
                    key={index}
                  >
                    <div className='d-flex align-items-center'>
                      <div style={{ border: "2px solid #d3d3d3" }} className='symbol symbol-15px symbol-circle'>

                        {useritem && (
                          <div className='symbol symbol-25px symbol-circle'>
                            <span
                              className={`symbol-label bg-light-info text-info fw-bold`}
                            >
                              {useritem.user.email.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className='ms-5'>
                        <p style={{ fontSize: "14px", fontWeight: "500", }}
                          className=' text-hover-primary mb-1 '
                          color={activeTheme === 'dark' ? 'white' : 'text.secondary'}

                        >{useritem.user.first_name + " " + useritem.user.last_name}
                        </p>
                        <p style={{
                          fontSize: "12px", fontWeight: "400", color: "rgb(109, 110, 111)"
                        }}
                          className='fs-7 text-hover-primary mb-1'
                        >
                          <div >{useritem.user.email}</div>
                        </p>

                      </div>
                    </div>

                    <div className='ms-2 w-100px' >
                      <IconButton sx={{ color: "grey" }} edge="end" aria-label="delete" onClick={() => removeOneAgentUser(useritem.user.id)} >
                        <DeleteIcon />
                      </IconButton>
                    </div>

                  </div>
                );

              })}
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>

          <Button className='btn'
            style={{
              cursor: 'pointer',
              color: 'white',
              backgroundColor: '#20808d'
            }} onClick={handleAgentSettingsDialogClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className={styles.cardMainContainer} onClick={handleAgentClick}>
        <div className='d-flex' style={{ minWidth: "100%" }}>
          {renderEditComponent && setCurrentTab && (<ConfigureAgent setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} setCurrentTab={setCurrentTab} isEditView={false} isNameExists={isNameExists} setIsNameExists={setIsNameExists} setHasNameError={setHasNameError} />)}
          <div className={styles.agentCardImg} >
            <img loading='lazy' className={`rounded-circle ${styles.agentImage}`} src={data.agent_image ? data.agent_image : defaultBotImg} alt="Agent Image" />
          </div>


          <div className={`d-flex flex-column ${styles.agentDetails}`}>
            <div className={styles.agentNameDesc}>

              <span>
                <Tooltip title={`${data.agent_name}`}>
                  <span className={styles.agentCardName}>{truncatedAgentName}</span>
                </Tooltip>
                {data.status === "Live" ? (
                  <span className={styles.liveDot}></span>
                ) : data.status === "Draft" ? (
                  <span className={styles.draftDot}></span>
                ) : null}
              </span>

              <Tooltip title={`${data.domain}`}>
                <span
                  className={`${styles.agentDesc}`}
                >
                  {truncatedDomain}
                </span>
              </Tooltip>

              <Tooltip title={fullName}>
              <span
                className={`${styles.agentCreatedBy}`}
              >
                By {userName}
              </span>
              </Tooltip>
            </div>

            <div className={` ${styles.cardIcons} d-flex align-items-center`} >
                <Typography
                  component="span"
                  variant="body2"
                  color={activeTheme === 'dark' ? 'white' : 'text.secondary'}
                >
                  {totalVotes}
                </Typography>

                <IconButton size="medium" aria-label="Upvote" title="Upvote agent" sx={{ color: isUserUpvoted ? 'green' : 'grey', padding: "5px"}} onClick={(e) => { e.stopPropagation(); handleUpvoteClick(); }}>
                  <ThumbsUp size={16} color={isUserUpvoted ? 'green' : 'gray'} weight="light" />
                </IconButton>
                <IconButton size="medium" aria-label="Downvote" title="Downvote agent" sx={{ color: isUserDownvoted ? 'red' : 'grey', padding: "5px"}} onClick={(e) => { e.stopPropagation(); handleDownvoteClick(); }}>
                  <ThumbsDown size={16} color={isUserDownvoted ? 'red' : 'gray'} weight="light" />
                </IconButton>


                <div className={styles.threeDotsDiv} >
                  {
                    url.includes('teamagents') || url.includes('recentagents') || url.includes('starredagents') ? <></> :
                      <>
                        {
                          (isSuper || data.created_by === loggedInUserEmail || userRole === "Owner") ? <>
                            {viewMode === 'default' && (
                              <div className={styles.threeDots} onClick={(e) => { e.stopPropagation(); }}>
                                <IconButton
                                  onClick={handlePopoverOpen}
                                >
                                  <DotsThree size={17} color="gray" weight="bold" />
                                </IconButton>

                                <Popover
                                  open={Boolean(popoverAnchor)}
                                  anchorEl={popoverAnchor}
                                  onClose={handlePopoverClose}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >

                   



                                  <MenuItem onClick={(e) => { e.stopPropagation(); handleAlertClick(); }} >
                                    <DeleteOutlinedIcon fontSize="medium" sx={{ mr: 0.5 }} />

                                    Delete
                                  </MenuItem>



                                  <MenuItem onClick={handleAgentSettingsDialogOpen} >

                                    <PersonAddAltOutlinedIcon fontSize="medium" sx={{ mr: 0.5 }} />
                                    Edit Access
                                  </MenuItem>

                                </Popover>
                              </div>
                            )}</> : <></>
                        }
                      </>
                  }
                </div>
              </div>

          </div>
        </div>
      </div>
    </div >
  )
}

export { Card2 }

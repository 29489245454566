import {
    AskResponse,
    ChatRequest,
    LoginRequest,
    LoginResponse,
    LookFeelCardResponse,
    JobFunctionsResponse,
    AgentCardResponse,
    LookFeelCardRequest,
    AgentCardRequest,
    EditLookFeelCardRequest,
    EditAgentCardRequest,
    ActiveAgentResponse,
    RatingsRequest,
    RatingsResponse,
    ActiveAgentRequest,
    ReviewRequest,
    ReviewResponse,
    ChatLogResponse,
    FileIndexResponse,
    WeblinkIndexRequest,
    WeblinkIndexResponse,
    YoutubeIndexRequest,
    YoutubeIndexResponse,
    YoutubeTableResponse,
    FileTableResponse,
    WeblinkTableResponse,
    TextIndexResponse,
    TextIndexRequest,
    TextTableResponse,
    UserTeamResponseByUser,
    UserTeamResponseByTeam,
    EditTeamRequest,
    TeamResponse,
    TeamRequest,
    UserTeamRequest,
    EditUserTeamRequest,
    UserProjectResponseByUser,
    UserProjectResponseByProject,
    EditProjectRequest,
    ProjectResponse,
    ProjectRequest,
    UserProjectRequest,
    EditUserProjectRequest,
    User,
    NotificationResponse,
    AgentCategoryResponse,
    UserAgentResponseByAgent,
    UserAgentRequest,
    EditUserAgentRequest,
    SupportRequest,
    StarredAgentResponse,
    RecentAgentResponse,
    VerifyTokenRequest,
    VerifyTokenResponse,
    getTokenRequest,
    getTokenResponse,
    EditUserRoleRequest,
    EditUserRoleResponse,
    OrganizationResponse,
    ConfigurationResponse,
    EditModelConfigurationRequest,
    TotalCounts,
    MostActiveAgents,
    RecentlyAddedusers,
    UserToken,
    AgentToken,
    AgentTokenData,
    TeamTokenData,
    TeamTokenResponse,
    DailyTokensData,
    WeeklyTokensData,
    MonthlyTokensData,
    userTokenData,
    userEngagementData,
    AgentEngagementData,
    TeamEngagementResponse,
    DailyEngagementResponse,
    WeeklyEngagementResponse,
    MonthlyEngagementResponse,
    MostActiveUsers,
    FAQCategories,
    Questions,
    RecentTeamResponse,
    RecentProjectResponse,
    FileIndexRequest,
    ChatUpvoteRequest,
    ChatDownvoteRequest,
    ChatCommentRequest,
    ChatCommentResponse,
    Tutorials,
    OneTutorial,
    ChatThreads,
    ChatByThread,
    FileByAgentResponse,
    WeblinksByAgentResponse,
    YTLinksByAgentResponse,
    TextblobsByAgentResponse,
    ChatLogByPageResponse,
    AgentsByPageResponse,
    AgentAliasResponse,
    LinksResponse,
    AllDataResponse,
    LLMConfigResponse,
    ActiveConfigResponse,
    ActiveConfigRequest,
    NewChatRequest,
    NewChatResponse,
    PastPromptResponse,
    AllEvaluationTests,
    CreateEvaluationRequest,
    EvaluationDataset,
    CreateDatasetRequest,
    EvaluationTest,
    AllOrgs,
    ActiveAgentAccessResponse,
    AgentType,
    FeatureFlag,
    sharePointLink,
    EditAgentAccessRequest,
    EditAgentAccessResponse,
    UserRole,
    userRoleInTeam,
    AgentCardResponse2,
    AgentCardResponseWithTeam,
    CreateCustomInstructionRequest,
    CreateCustomInstructionResponse,
    ChatLogWithAgentResponse,
    ExportChatLogResponses,
} from "./models";
import Cookies from "universal-cookie";


const cookies = new Cookies();


//new Agent creation api

export async function createAgentChat(requestData: NewChatRequest): Promise<NewChatResponse> {
    try {
        const response = await fetch("agent/chatcreate", {
            method: "POST",
            headers: {
                "X-CSRFToken": cookies.get("csrftoken"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                history: requestData.history,
                agent_id: requestData.agent_id,
                created_by: requestData.created_by,
                project_id: requestData.project_id,
                flags: requestData.flags,
                user_id:requestData.user_id
            })
        });

        if (!response.ok) {
            throw new Error('Failed to create agent chat');
        }

        return response.json() as Promise<NewChatResponse>;
    }
    catch (error) {
        console.error('Error:', error);
        throw error;
    }
}




// Chat CRUD services


export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch("/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            category: options.category,
            user_id: options.user_id,
            created_by: options.created_by,
            chat_thread_id: options.chat_thread_id,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                sources: options.overrides?.sources,
                cache_skip: options.overrides?.cache_skip,
                mode_override: options.overrides?.mode_override,
            },
            query_source: "web",
            model: options.model
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}


  export async function getnewChat(userid: any, agentid: any, allFlag?: any): Promise<ChatLogResponse[]> {
    const response = await fetch(`/chat/get/${allFlag}/?user_id=${userid}&agent_id=${agentid}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Chats for the given agent and user.");
    }
    const parsedResponse: ChatLogResponse[] = await response.json();
    return parsedResponse;
}


export async function getChat(userid: any, agentid: any, allFlag?: any): Promise<ChatLogResponse[]> {
    const response = await fetch(`/chat/get/${allFlag}/?user_id=${userid}&agent_id=${agentid}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Chats for the given agent and user.");
    }
    const parsedResponse: ChatLogResponse[] = await response.json();
    return parsedResponse;
}

export async function getChatLogByUser(userIds: number[], page: number): Promise<ChatLogByPageResponse> {
    const response = await fetch(`/chat/user?page=${page}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body:JSON.stringify({
            user_ids:userIds
        })
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Chats for the given agent and user.");
    }
    const parsedResponse: ChatLogByPageResponse = await response.json();
    return parsedResponse;
}

export async function getChatLogByAgents(agentIds: number[], page: number): Promise<ChatLogByPageResponse> {
    const response = await fetch(`/chat/agents?page=${page}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body:JSON.stringify({
            agent_ids:agentIds
        })
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Chats for the given agent and user.");
    }
    const parsedResponse: ChatLogByPageResponse = await response.json();
    return parsedResponse;
}


export async function getChatLogByAgent(agentid: any, page: number): Promise<ChatLogByPageResponse> {
    const response = await fetch(`/chat/agent/${agentid}/?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Chats for the given agent and user.");
    }
    const parsedResponse: ChatLogByPageResponse = await response.json();
    return parsedResponse;
}


export async function deleteChat(userid: any, agentid: any): Promise<ChatLogResponse[]> {
    const response = await fetch(`/chat/delete/?user_id=${userid}&agent_id=${agentid}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to delete Chats for the given agent and user.");
    }
    const parsedResponse: ChatLogResponse[] = await response.json();
    return parsedResponse;
}

export async function getChatLogByProject(projectIds: number[], page: number): Promise<ChatLogByPageResponse> {
    const response = await fetch(`/chat/project?page=${page}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body:JSON.stringify({
            project_id:projectIds
        })
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Chats for the given project.");
    }
    const parsedResponse: ChatLogByPageResponse = await response.json();
    return parsedResponse;
}


// Index CRUD services




export async function dynamicIndex(agent_id: number, user_id: number, source_type: string, source: string | File): Promise<any> {
    const formData = new FormData();

    formData.append('agent_id', agent_id.toString());
    formData.append('user_id', user_id.toString());
    formData.append('request_origin', "web");

    switch (source_type) {
        case 'file':
            formData.append('source', source);
            if (source instanceof File) {
                const fileName = source.name;

                // Get the file extension (assuming it's the part after the last dot)
                const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
                if (fileExtension.toLowerCase() === "pdf") {
                    formData.append('content_type', "pdf");
                } else {
                    formData.append('content_type', "document");
                }
            }
            break;
        case 'web':
            formData.append('source', source);
            formData.append('content_type', "website");
            break;
        case 'yt':
            formData.append('source', source);
            formData.append('content_type', "youtube");
            break;
        case 'text':
            formData.append('source', source);
            formData.append('content_type', "text");
            break;

        default:
            throw new Error('Invalid data source type');
    }
    const response = await fetch(`/indexer/`, {
        method: 'POST',
        headers: {
            'X-CSRFToken': cookies.get('csrftoken'),
        },
        body: formData,
    });

    if (response.status > 299 || !response.ok) {
        const errorData = await response.json();
        throw new Error(
            errorData.message || `Failed to index the ${source_type}. Please try again.`
        );
    }

    const parsedResponse: any = await response.json();
    return parsedResponse;
}

export async function upvoteChat(options: ChatUpvoteRequest): Promise<ChatLogResponse> {
    const response = await fetch(`/chat/upvote/${options.chat_id}`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            is_upvote: options.is_upvote,
            created_by: options.created_by,
        })
    });

    if (!response.ok) {
        throw new Error("Failed to upvote the chat response.");
    }
    const parsedResponse: ChatLogResponse = await response.json();
    return parsedResponse;
}

export async function downvoteChat(options: ChatDownvoteRequest): Promise<ChatLogResponse> {
    const response = await fetch(`/chat/downvote/${options.chat_id}`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            is_downvote: options.is_downvote,
            created_by: options.created_by,
        })
    });

    if (!response.ok) {
        throw new Error("Failed to downvote the chat response.");
    }
    const parsedResponse: ChatLogResponse = await response.json();
    return parsedResponse;
}
export async function commentChat(options: ChatCommentRequest): Promise<ChatLogResponse> {
    const response = await fetch(`/chat/comment/${options.chat_id}`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            comment: options.comment,
            created_by: options.created_by,
        })
    });

    if (!response.ok) {
        throw new Error("Failed to downvote the chat response.");
    }
    const parsedResponse: ChatLogResponse = await response.json();
    return parsedResponse;
}

export async function getChatComments(id: number): Promise<ChatCommentResponse[]> {
    const response = await fetch(`/comment/get/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Chat Comment.");
    }
    const parsedResponse: ChatCommentResponse[] = await response.json();
    return parsedResponse;
}


export async function indexFiles(agent_id: string, user_id: number, file_name: string, created_by: string, file: File, upload_source: string): Promise<FileIndexResponse> {

    const sanitizedFileName = file_name.replace(/,/g, '-');
    const formData = new FormData();
    formData.append("pdf", file);
    formData.append("agent_id", agent_id);
    formData.append("user_id", user_id.toString());
    formData.append("file_name", sanitizedFileName);
    formData.append("created_by", created_by);
    formData.append("upload_source", upload_source)
    const response = await fetch("/index/pdf", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: formData
    });

    const parsedResponse: FileIndexResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function indexWeblinks(options: WeblinkIndexRequest): Promise<WeblinkIndexResponse> {
    const response = await fetch(`/index/web`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            agent_id: options.agent_id,
            user_id: options.user_id,
            web_url: options.web_url,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to index the Weblink. Please try again.");
    }
    const parsedResponse: WeblinkIndexResponse = await response.json();
    return parsedResponse;
}

export async function indexYoutube(options: YoutubeIndexRequest): Promise<YoutubeIndexResponse> {
    const response = await fetch(`/index/yt`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            agent_id: options.agent_id,
            user_id: options.user_id,
            yt_url: options.yt_url,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to index the Youtube Link. Please try again.");
    }
    const parsedResponse: YoutubeIndexResponse = await response.json();
    return parsedResponse;
}

export async function indexTextApi(options: TextIndexRequest): Promise<TextIndexResponse> {
    const response = await fetch("/index/text", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            agent_id: options.agent_id,
            user_id: options.user_id,
            text: options.text,
            created_by: options.created_by,
            upload_source: options.upload_source
        })
    });

    const parsedResponse: TextIndexResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}



// User CRUD services




export async function userLogin(options: LoginRequest): Promise<LoginResponse> {
    const response = await fetch("/login", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            email: options.email,
            password: options.password,
            deviceId: options.deviceId
        })
    });

    const parsedResponse: LoginResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function userLogout(): Promise<any> {
    const response = await fetch("/logout", {
        method: "GET"
    });
    const parsedResponse: any = await response.json();
    cookies.remove("__info");
    localStorage.clear()
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function getAllUsers(org_id: number): Promise<User[]> {
    const response = await fetch('/users/all', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch organizational users.");
    }
    const parsedResponse: User[] = await response.json();
    return parsedResponse;
}

export async function getUser(): Promise<LoginResponse> {
    const response = await fetch("/getuser", {
        method: "GET"
    });
    const parsedResponse: LoginResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}


export async function createUser(first_name: string, last_name: string, email: string, password: string, role: string): Promise<User> {
    const response = await fetch(`/users/create`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            first_name: first_name,
            last_name: last_name,
            email: email,
            password: password,
            role: role
        })
    });

    if (!response.ok) {
        throw new Error("Failed to fetch organizational users.");
    }
    const parsedResponse: User = await response.json();
    return parsedResponse;
}

export async function getAUser(user_id: number): Promise<User> {
    const response = await fetch(`/users/getUser/${user_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch user data.");
    }
    const parsedResponse: User = await response.json();
    return parsedResponse;
}

export async function editUser(user_id: number, first_name: string, last_name: string, email: string, role: string): Promise<User> {
    const response = await fetch(`/users/edit/${user_id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            first_name: first_name,
            last_name: last_name,
            email: email,
            role: role
        })
    });

    if (!response.ok) {
        throw new Error("Failed to fetch organizational users.");
    }
    const parsedResponse: User = await response.json();
    return parsedResponse;
}

export async function deleteAUser(user_id: number): Promise<User[]> {
    const response = await fetch(`/users/delete/${user_id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch organizational users.");
    }
    const parsedResponse: User[] = await response.json();
    return parsedResponse;
}

export async function deleteUsers(user_ids: number[]): Promise<User[]> {
    const response = await fetch(`/users/delete`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_ids: user_ids
        })
    });

    if (!response.ok) {
        throw new Error("Failed to fetch organizational users.");
    }
    const parsedResponse: User[] = await response.json();
    return parsedResponse;
}

// Look Feel Config CRUD services




export async function createLF(options: LookFeelCardRequest): Promise<LookFeelCardResponse> {
    const formData = new FormData();
    formData.append("config_name", options.config_name);
    formData.append("header_logo", options.header_logo);
    formData.append("square_logo", options.square_logo);
    formData.append("sample_q1", options.sample_q1);
    formData.append("sample_q2", options.sample_q2);
    formData.append("sample_q3", options.sample_q3);
    formData.append("tagline", options.tagline);
    formData.append("chat_placeholder", options.chat_placeholder);
    formData.append("navbar_bg_color", options.navbar_bg_color);
    formData.append("navbar_text_color", options.navbar_text_color);
    formData.append("layout_bg_color", options.layout_bg_color);
    formData.append("created_by", options.created_by);

    const response = await fetch("/lf/create", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: formData
    });

    const parsedResponse: LookFeelCardResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function getActiveLF(): Promise<LookFeelCardResponse> {
    const response = await fetch("/lf/activeconfig", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch LookFeel configuration.");
    }
    const parsedResponse: LookFeelCardResponse = await response.json();
    return parsedResponse;
}

export async function getAllLF(): Promise<LookFeelCardResponse[]> {
    const response = await fetch("/lf/configs", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch LookFeel configurations.");
    }
    const parsedResponse: LookFeelCardResponse[] = await response.json();
    return parsedResponse;
}

export async function setActiveLf(id: any): Promise<LookFeelCardResponse> {
    const response = await fetch(`/lf/activate/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update the LookFeel configuration. Please try again.");
    }
    const parsedResponse: LookFeelCardResponse = await response.json();
    return parsedResponse;
}

export async function deleteLf(id: any): Promise<LookFeelCardResponse[]> {
    const response = await fetch(`/lf/delete/${id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the LookFeel configuration. Please try again.");
    }
    const parsedResponse: LookFeelCardResponse[] = await response.json();
    return parsedResponse;
}

export async function getOneLF(id: any): Promise<LookFeelCardResponse> {
    const response = await fetch(`/lf/config/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch LookFeel configuration.");
    }
    const parsedResponse: LookFeelCardResponse = await response.json();
    return parsedResponse;
}

export async function editLF(options: EditLookFeelCardRequest): Promise<LookFeelCardResponse> {
    const formData = new FormData();
    formData.append("config_name", options.config_name);
    formData.append("header_logo", options.header_logo);
    formData.append("square_logo", options.square_logo);
    formData.append("sample_q1", options.sample_q1);
    formData.append("sample_q2", options.sample_q2);
    formData.append("sample_q3", options.sample_q3);
    formData.append("tagline", options.tagline);
    formData.append("chat_placeholder", options.chat_placeholder);
    formData.append("navbar_bg_color", options.navbar_bg_color);
    formData.append("navbar_text_color", options.navbar_text_color);
    formData.append("layout_bg_color", options.layout_bg_color);

    const response = await fetch(`/lf/edit/${options.id}`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken")!
            // "Content-Type": "multipart/form-data",
        },
        body: formData
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create the LookFeel configuration. Please try again.");
    }
    const parsedResponse: LookFeelCardResponse = await response.json();
    return parsedResponse;
}




// Agent CRUD services

export async function createAgent(options: AgentCardRequest): Promise<AgentCardResponse> {
    const clonedFromString = options.cloned_from !== null ? options.cloned_from.toString() : '';
    const formData = new FormData();
    formData.append("agent_name", options.agent_name);
    formData.append("user_id", options.user_id.toString());
    formData.append("prefix_prompt", options.prefix_prompt);
    formData.append("project_id", options.project_id.toString());
    formData.append("domain", options.domain);
    formData.append("sample_q1", options.sample_q1);
    formData.append("sample_q2", options.sample_q2);
    formData.append("sample_q3", options.sample_q3);
    formData.append("created_by", options.created_by);
    formData.append("cloned_from", clonedFromString);
    formData.append("need_alias", options.need_alias.toString());
    formData.append("agent_image", options.agent_image);
    formData.append("access",options.access)
    formData.append("status",options.status)
    formData.append("custom_instructions",JSON.stringify(options.custom_instructions))

    formData.append("agent_type",options.agent_type.toString())
    formData.append("llm_config",options.llm_config.toString())


    const response = await fetch("/agent/create", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: formData

    });

    const parsedResponse: AgentCardResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

export async function createAgentPrompt(agent_id: Number, data_source: string): Promise<void> {
    const response = await fetch(`/agent/createprompt/?agent_id=${agent_id}&data_source=${data_source}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });

    if (!response.ok) {
        throw new Error("Failed create agent prompt.");
    }
    return
}


export async function getAllAgents(): Promise<AgentCardResponse[]> {
    const response = await fetch("/agent/configs", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Agent configurations.");
    }
    const parsedResponse: AgentCardResponse[] = await response.json();
    return parsedResponse;
}

export async function deleteAgent(id: any): Promise<AgentsByPageResponse> {
    const response = await fetch(`/agent/delete/${id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the Agent configuration. Please try again.");

    }
    const parsedResponse: AgentsByPageResponse = await response.json();
    return parsedResponse;
}

export async function getOneAgent(id: any): Promise<AgentCardResponse> {
    const response = await fetch(`/agent/config/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent configuration.");

    }
    const parsedResponse: AgentCardResponse = await response.json();
    return parsedResponse;
}

export async function getOneAgentForMagicLink(id: any): Promise<AgentCardResponse> {
    const response = await fetch(`agent/magiclink/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent configuration.");

    }
    const parsedResponse: AgentCardResponse = await response.json();
    return parsedResponse;
}

export async function getAgentUserForMagicLink(id: any): Promise<AgentCardResponse2> {
    const response = await fetch(`agent/user/magiclink/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent configuration.");

    }
    const parsedResponse: AgentCardResponse2 = await response.json();
    return parsedResponse;
}

export async function getOneAgentUser(id: any): Promise<AgentCardResponse2> {
    const response = await fetch(`agent/user/config/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent configuration.");

    }
    const parsedResponse: AgentCardResponse2 = await response.json();
    return parsedResponse;
}

export async function editAgent(options: EditAgentCardRequest): Promise<AgentCardResponse> {
    const clonedFromString = options.cloned_from !== null ? options.cloned_from.toString() : '';
    const formData = new FormData();
    formData.append("agent_name", options.agent_name);
    formData.append("prefix_prompt", options.prefix_prompt);
    formData.append("domain", options.domain);
    formData.append("sample_q1", options.sample_q1);
    formData.append("sample_q2", options.sample_q2);
    formData.append("sample_q3", options.sample_q3);
    formData.append("cloned_from", clonedFromString);
    formData.append("need_alias", options.need_alias.toString());
    formData.append("agent_image", options.agent_image);
    formData.append("access", options.access);
    formData.append("status", options.status);
    formData.append("created_by", options.created_by);
    formData.append("custom_instructions",JSON.stringify(options.custom_instructions))
    formData.append("agent_type",options.agent_type.toString())
    formData.append("llm_config",options.llm_config.toString())

    const response = await fetch(`/agent/edit/${options.id}`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken")

        },
        body: formData
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to edit the Agent configuration. Please try again.");

    }
    const parsedResponse: AgentCardResponse = await response.json();
    return parsedResponse;
}

export async function createCustomInstructions(options: CreateCustomInstructionRequest): Promise<CreateCustomInstructionResponse[]> {
    const formData = new FormData();
    const response = await fetch("instruction/update", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            agent: options.agent,
            created_by: options.created_by,
            custom_instructions: options.custom_instructions,
        })

    });

    const parsedResponse: CreateCustomInstructionResponse[] = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}


export async function editAgentAccess(options: EditAgentAccessRequest): Promise<EditAgentAccessResponse> {

    const response = await fetch(`/agent/editAccess/${options.id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            access: options.access,
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to edit the Agent configuration. Please try again.");

    }
    const parsedResponse: EditAgentAccessResponse = await response.json();
    return parsedResponse;
}

export async function getPastPromptsByAgent(id: any): Promise<PastPromptResponse[]> {
    const response = await fetch(`/agent/prompt/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent Instructions.");
    }
    const parsedResponse: PastPromptResponse[] = await response.json();
    return parsedResponse;
}

export async function getAgentByProject(id: any): Promise<AgentCardResponse[]> {
    const response = await fetch(`/agent/project/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent configuration from Project.");

    }
    const parsedResponse: AgentCardResponse[] = await response.json();
    return parsedResponse;
}

export async function getAgentsByProject(id: any, page: number): Promise<AgentsByPageResponse> {
    const response = await fetch(`/agent/project/${id}/?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent configuration from Project.");

    }
    const parsedResponse: AgentsByPageResponse = await response.json();
    return parsedResponse;
}

export async function getAgentsByUserProject(id: any, page: number): Promise<AgentsByPageResponse> {
    const response = await fetch(`/agent/userproject/${id}/?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent configuration from Project.");

    }
    const parsedResponse: AgentsByPageResponse = await response.json();
    return parsedResponse;
}

export async function getAgentByCategory(): Promise<AgentCategoryResponse[]> {
    const response = await fetch("/agentcategory", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Agents by category.");
    }
    const parsedResponse: AgentCategoryResponse[] = await response.json();
    return parsedResponse;
}

export async function getAgentByTeam(id: any): Promise<AgentCardResponse[]> {
    const response = await fetch(`agent/team/all/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Agents by team.");
    }
    const parsedResponse: AgentCardResponse[] = await response.json();
    return parsedResponse;
}
export async function getUserAgent(id: any): Promise<UserAgentResponseByAgent[]> {
    const response = await fetch(`/agent/getuser/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent Users.");

    }

    const parsedResponse: UserAgentResponseByAgent[] = await response.json();
    return parsedResponse;
}

export async function addUsersToAgent(options: UserAgentRequest): Promise<UserAgentResponseByAgent[]> {
    const response = await fetch(`/agent/adduser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            agent_id: options.agent_id,
            users: options.users,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add users in the agent. Please try again.");
    }
    const parsedResponse: UserAgentResponseByAgent[] = await response.json();
    return parsedResponse;
}

export async function deleteUserFromAgent(options: EditUserAgentRequest): Promise<UserAgentResponseByAgent[]> {
    const response = await fetch(`/agent/deleteuser`, {
        method: "DELETE",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            agent_id: options.agent_id,
            user_id: options.user_id
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the User from agent. Please try again.");

    }
    const parsedResponse: UserAgentResponseByAgent[] = await response.json();
    return parsedResponse;

}

export async function getUserAgentPermission(id: any): Promise<UserAgentResponseByAgent[]> {
    const response = await fetch(`agent/getuserpermission/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent permission.");

    }

    const parsedResponse: UserAgentResponseByAgent[] = await response.json();
    return parsedResponse;
}
export async function getAllAgentsFromUserTeams(id: any): Promise<AgentCardResponseWithTeam[]> {
    const response = await fetch(`/agent/user/all/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Agent configurations.");
    }
    const parsedResponse: AgentCardResponseWithTeam[] = await response.json();
    return parsedResponse;
}


export async function getAllAgentsPageFromUserTeams(id: any, page: number): Promise<AgentsByPageResponse> {
    const response = await fetch(`/agent/user/all/${id}/?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Agent configurations.");
    }
    const parsedResponse: AgentsByPageResponse = await response.json();
    return parsedResponse;
}

export async function getAllAgentsByPage(page: number): Promise<AgentsByPageResponse> {
    const response = await fetch(`/agent/configs/?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Agent configurations.");
    }
    const parsedResponse: AgentsByPageResponse = await response.json();
    return parsedResponse;
}

export async function getAgentsWithAlias(): Promise<AgentAliasResponse[]> {
    const response = await fetch(`/agent/alias/all`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Agent configurations.");
    }
    const parsedResponse: AgentAliasResponse[] = await response.json();
    return parsedResponse;
}


export async function createAgentAlias(agent_id: number, alias: string): Promise<AgentAliasResponse[]> {
    const response = await fetch(`/agent/alias/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            agent_id: agent_id,
            alias: alias,
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create agent alias. Please try again.");
    }
    const parsedResponse: AgentAliasResponse[] = await response.json();
    return parsedResponse;
}


export async function editAgentAlias(agent_id: number, alias: string): Promise<AgentAliasResponse[]> {
    const response = await fetch(`/agent/alias/edit`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            agent_id: agent_id,
            alias: alias,
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to edit agent alias. Please try again.");
    }
    const parsedResponse: AgentAliasResponse[] = await response.json();
    return parsedResponse;
}

export async function deleteAgentAlias(agent_id: number): Promise<AgentAliasResponse[]> {
    const response = await fetch(`/agent/alias/delete/${agent_id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete agent alias. Please try again.");
    }
    const parsedResponse: AgentAliasResponse[] = await response.json();
    return parsedResponse;
}



// Job Function CRUD services




export async function getAllJobFunctions(): Promise<JobFunctionsResponse> {
    const response = await fetch('/jobfunction/getall', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Job Functions.");
    }
    const parsedResponse: JobFunctionsResponse = await response.json();
    return parsedResponse;
}




// Misc CRUD services
export function getCitationFilePath(citation: string): string {
    return `/citation_content/${citation}`;
}


export function getDataFilePath(file: string): string {
    return `/file_content/${file}`;
}






// ActiveAgent CRUD services






export async function getUserActiveAgent(id: any): Promise<ActiveAgentResponse> {
    const response = await fetch(`/activeagent/user/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Active User Agent configuration.");
    }
    const parsedResponse: ActiveAgentResponse = await response.json();
    return parsedResponse;
}

export async function setUserActiveAgent(options: ActiveAgentRequest): Promise<ActiveAgentResponse> {
    const response = await fetch(`/activeagent/activate`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id: options.user_id,
            agent_id: options.agent_id,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to activate User Active Agent. Please try again.");
    }
    const parsedResponse: ActiveAgentResponse = await response.json();
    return parsedResponse;
}




// Rating CRUD services





export async function setRating(options: RatingsRequest): Promise<RatingsResponse[]> {
    const response = await fetch(`/rating/vote`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            agent_id: options.agent_id,
            user_id: options.user_id,
            is_upvote: options.is_upvote,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to set the Rating. Please try again.");
    }
    const parsedResponse: RatingsResponse[] = await response.json();
    return parsedResponse;
}

export async function getAllRatings(): Promise<RatingsResponse[]> {
    const response = await fetch("/rating/all", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch All Ratings.");
    }
    const parsedResponse: RatingsResponse[] = await response.json();
    return parsedResponse;
}

export async function deleteRating(upvoteid: any): Promise<RatingsResponse[]> {
    const response = await fetch(`/rating/delete/${upvoteid}`, {
        method: "DELETE",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the Agent configuration. Please try again.");

    }
    const parsedResponse: RatingsResponse[] = await response.json();
    return parsedResponse;
}






// Review CRUD services

export async function submitReview(options: ReviewRequest): Promise<ReviewResponse> {
    const response = await fetch(`/review/create`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            agent_id: options.agent_id,
            user_id: options.user_id,
            user_review: options.user_review,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit the Feedback. Please try again.");
    }
    const parsedResponse: ReviewResponse = await response.json();
    return parsedResponse;
}

export async function getReviews(id: any): Promise<ReviewResponse[]> {
    const response = await fetch(`/review/agent/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch All Reviews.");
    }
    const parsedResponse: ReviewResponse[] = await response.json();
    return parsedResponse;
}
export async function getAllReviews(): Promise<ReviewResponse[]> {
    const response = await fetch("/review/all", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch All Reviews.");
    }
    const parsedResponse: ReviewResponse[] = await response.json();
    return parsedResponse;
}

export async function deleteReview(id: any): Promise<ReviewResponse[]> {
    const response = await fetch(`/review/delete/${id}`, {
        method: "DELETE",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
    });

    if (!response.ok) {
        throw new Error("Failed to fetch All Reviews.");
    }
    const parsedResponse: ReviewResponse[] = await response.json();
    return parsedResponse;
}





// Pdf Data CRUD services




export async function getFiles(id: any, page: number, query: string = ''): Promise<FileByAgentResponse> {
    const response = await fetch(`/file/agent/${id}/?page=${page}&query=${query}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch All Files.");
    }
    const parsedResponse: FileByAgentResponse = await response.json();
    return parsedResponse;
}

export async function deleteFile(id: any): Promise<FileTableResponse[]> {
    const response = await fetch(`/file/delete/${id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the File. Please try again.");
    }
    const parsedResponse: FileTableResponse[] = await response.json();
    return parsedResponse;
}

export async function pollingFiles(fileIds: number[]): Promise<FileTableResponse[]> {
    const response = await fetch("/polling/files", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            file_ids: fileIds,
        })
    });

    const parsedResponse: FileTableResponse[] = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}


export async function checkDuplicateFiles(files: File[], agent_id: number): Promise<any> {
    const formData = new FormData();
    formData.append('agent_id', agent_id.toString())

    files.forEach(file => {
        formData.append('files', file.name);
    });

    const response = await fetch("/duplicate/files", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: formData
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}

// Weblinks Data CRUD services





export async function getWeblinks(id: any, page: number, query = ''): Promise<WeblinksByAgentResponse> {
    const response = await fetch(`/weblink/agent/${id}/?page=${page}&query=${query}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch All Weblinks.");
    }
    const parsedResponse: WeblinksByAgentResponse = await response.json();
    return parsedResponse;
}

export async function deleteWeblink(id: any): Promise<WeblinkTableResponse[]> {
    const response = await fetch(`/weblink/delete/${id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the Weblink. Please try again.");
    }
    const parsedResponse: WeblinkTableResponse[] = await response.json();
    return parsedResponse;
}

export async function checkDuplicateLinks(ytLinks: string[], webLinks: string[], agent_id: number): Promise<any> {
    const formData = new FormData();
    formData.append('agent_id', agent_id.toString())

    ytLinks.forEach(link => {
        formData.append('links', link);
    });
    webLinks.forEach(link => {
        formData.append('links', link);
    });

    const response = await fetch("/duplicate/links", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: formData
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}


// YouTube Data CRUD services

export async function getYTLinks(id: any, page: number, query = ''): Promise<YTLinksByAgentResponse> {
    const response = await fetch(`/yt/agent/${id}/?page=${page}&query=${query}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",

        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch All Youtube Links.");
    }
    const parsedResponse: YTLinksByAgentResponse = await response.json();
    return parsedResponse;
}

export async function deleteYT(id: any): Promise<YoutubeTableResponse[]> {
    const response = await fetch(`/yt/delete/${id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the Youtube Link. Please try again.");
    }
    const parsedResponse: YoutubeTableResponse[] = await response.json();
    return parsedResponse;
}

export async function pollingLinks(linkIds: { id: number; type: "youtube" | "weblink" }[]): Promise<LinksResponse[]> {
    const response = await fetch("/polling/links", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            link_ids: linkIds,
        })
    });

    const parsedResponse: LinksResponse[] = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}


// Text Data CRUD services




export async function getTextBlob(id: any, page: number, query = ''): Promise<TextblobsByAgentResponse> {
    const response = await fetch(`/text/agent/${id}/?page=${page}&query=${query}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",

        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch All Text.");
    }
    const parsedResponse: TextblobsByAgentResponse = await response.json();
    return parsedResponse;
}

export async function deleteTextBlob(id: any): Promise<TextTableResponse[]> {
    const response = await fetch(`/text/delete/${id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the Text Blob. Please try again.");
    }
    const parsedResponse: TextTableResponse[] = await response.json();
    return parsedResponse;
}

export async function pollingTexts(textIds: number[]): Promise<TextTableResponse[]> {
    const response = await fetch("/polling/texts", {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            text_ids: textIds,
        })
    });

    const parsedResponse: TextTableResponse[] = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return parsedResponse;
}



// Get all indexed data


export async function getAllData(id: any, page: number, query: string = ''): Promise<AllDataResponse> {
    const response = await fetch(`/allkb/agent/${id}/?page=${page}&query=${query}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch All Data.");
    }
    const parsedResponse: AllDataResponse = await response.json();
    return parsedResponse;
}


// Team CRUD services



export async function getTeam(team_id: number): Promise<TeamResponse> {
    const response = await fetch(`team/getOne/${team_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Teams.");
    }
    const parsedResponse: TeamResponse = await response.json();
    return parsedResponse;
}

export async function getAllTeams(): Promise<TeamResponse[]> {
    const response = await fetch(`team/all`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Teams.");
    }
    const parsedResponse: TeamResponse[] = await response.json();
    return parsedResponse;
}


export async function getTeamsByUser(user_id: any): Promise<UserTeamResponseByUser[]> {
    const response = await fetch(`team/userteams/${user_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Teams.");
    }
    const parsedResponse: UserTeamResponseByUser[] = await response.json();
    return parsedResponse;
}

export async function getUsersByTeam(team_id: any): Promise<UserTeamResponseByTeam[]> {
    const response = await fetch(`team/teamuser/${team_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",

        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Users.");
    }
    const parsedResponse: UserTeamResponseByTeam[] = await response.json();
    return parsedResponse;
}

export async function editTeam(options: EditTeamRequest): Promise<TeamResponse> {
    const response = await fetch(`/team/edit`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            team_id: options.team_id,
            team_name: options.team_name,
            modified_by: options.modified_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to edit team. Please try again.");
    }
    const parsedResponse: TeamResponse = await response.json();
    return parsedResponse;
}

export async function deleteTeam(team_id: any): Promise<TeamResponse> {
    const response = await fetch(`team/delete/${team_id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete team. Please try again.");
    }
    const parsedResponse: TeamResponse = await response.json();
    return parsedResponse;
}

export async function createTeam(options: TeamRequest): Promise<TeamResponse> {
    const response = await fetch(`/team/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            org_id: options.org_id,
            user_id: options.user_id,
            team_name: options.team_name,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create team. Please try again.");
    }
    const parsedResponse: TeamResponse = await response.json();
    return parsedResponse;
}

export async function addUsersToTeam(options: UserTeamRequest): Promise<UserTeamResponseByTeam[]> {
    const response = await fetch(`/team/adduser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            team_id: options.team_id,
            users: options.users,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add users. Please try again.");
    }
    const parsedResponse: UserTeamResponseByTeam[] = await response.json();
    return parsedResponse;
}

export async function deleteUserFromTeam(options: EditUserTeamRequest): Promise<UserTeamResponseByTeam[]> {
    const response = await fetch(`/team/deleteuser`, {
        method: "DELETE",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            team_id: options.team_id,
            user_id: options.user_id
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the User from Team. Please try again.");

    }
    const parsedResponse: UserTeamResponseByTeam[] = await response.json();
    return parsedResponse;
}




// Project CRUD services




export async function getProjectsByUser(user_id: any): Promise<UserProjectResponseByUser[]> {
    const response = await fetch(`/project/user/${user_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",

        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Projects.");
    }
    const parsedResponse: UserProjectResponseByUser[] = await response.json();
    return parsedResponse;
}

export async function getProjectsByUserAndTeam(user_id: any, team_id: any): Promise<UserProjectResponseByUser[]> {
    const response = await fetch(`/project/user-team?team_id=${team_id}&user_id=${user_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Projects.");
    }
    const parsedResponse: UserProjectResponseByUser[] = await response.json();
    return parsedResponse;
}

export async function getTeamUsersByProject(project_id: any): Promise<UserTeamResponseByTeam[]> {
    const response = await fetch(`project/teamuser/${project_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Users.");
    }
    const parsedResponse: UserTeamResponseByTeam[] = await response.json();
    return parsedResponse;
}

export async function editProject(options: EditProjectRequest): Promise<ProjectResponse> {
    const response = await fetch(`/project/edit`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            project_id: options.project_id,
            project_name: options.project_name,
            modified_by: options.modified_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to edit project. Please try again.");
    }
    const parsedResponse: ProjectResponse = await response.json();
    return parsedResponse;
}

export async function deleteProject(project_id: any): Promise<ProjectResponse> {
    const response = await fetch(`project/delete/${project_id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete project. Please try again.");
    }
    const parsedResponse: ProjectResponse = await response.json();
    return parsedResponse;
}

export async function createProject(options: ProjectRequest): Promise<ProjectResponse> {
    const response = await fetch(`/project/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            team_id: options.team_id,
            user_id: options.user_id,
            project_name: options.project_name,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create project. Please try again.");
    }
    const parsedResponse: ProjectResponse = await response.json();
    return parsedResponse;
}

export async function addUsersToProject(options: UserProjectRequest): Promise<UserProjectResponseByProject[]> {
    const response = await fetch(`/project/adduser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            project_id: options.project_id,
            users: options.users,
            created_by: options.created_by
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to add users. Please try again.");
    }
    const parsedResponse: UserProjectResponseByProject[] = await response.json();
    return parsedResponse;
}

export async function deleteUserFromProject(options: EditUserProjectRequest): Promise<UserProjectResponseByProject[]> {
    const response = await fetch(`/project/deleteuser`, {
        method: "DELETE",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            project_id: options.project_id,
            user_id: options.user_id
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete the User from Project. Please try again.");

    }
    const parsedResponse: UserProjectResponseByProject[] = await response.json();
    return parsedResponse;
}

export async function getProject(id: any): Promise<ProjectResponse> {
    const response = await fetch(`/project/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Project info.");

    }
    const parsedResponse: ProjectResponse = await response.json();
    return parsedResponse;
}

export async function getProjectsByTeam(team_id: any): Promise<ProjectResponse[]> {
    const response = await fetch(`/project/team/${team_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",

        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Projects by Team.");
    }
    const parsedResponse: ProjectResponse[] = await response.json();
    return parsedResponse;
}



// Notification CRUD services




export async function getUserNotifications(id: any): Promise<NotificationResponse[]> {
    const response = await fetch(`/notification/user/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Project info.");

    }
    const parsedResponse: NotificationResponse[] = await response.json();
    return parsedResponse;
}

export async function readNotification(id: any): Promise<NotificationResponse> {
    const response = await fetch(`/notification/read/${id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Project info.");

    }
    const parsedResponse: NotificationResponse = await response.json();
    return parsedResponse;
}

export async function readAllNotifications(id: any): Promise<NotificationResponse[]> {
    const response = await fetch(`/notification/readall/${id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Project info.");

    }
    const parsedResponse: NotificationResponse[] = await response.json();
    return parsedResponse;
}

// Starred Agents CRUD services

export async function getStarredAgents(id: any): Promise<StarredAgentResponse[]> {
    const response = await fetch(`/starredagents/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Starred Agents.");
    }
    const parsedResponse: StarredAgentResponse[] = await response.json();
    return parsedResponse;
}

export async function getOneStarredAgent(user_id: number, agent_id: number): Promise<StarredAgentResponse[]> {
    const response = await fetch(`/starredagents/getOne?user_id=${user_id}&agent_id=${agent_id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Starred Agent.");
    }
    const parsedResponse: StarredAgentResponse[] = await response.json();
    return parsedResponse;
}

export async function deleteStarredAgent(user_id: number, agent_id: number): Promise<StarredAgentResponse[]> {
    const response = await fetch(`/starredagents/delete/`, {
        method: "DELETE",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: user_id,
            agent: agent_id
        })
    });

    if (!response.ok) {
        throw new Error("Failed to delete Starred Agent.");
    }
    const parsedResponse: StarredAgentResponse[] = await response.json();
    return parsedResponse;
}

export async function createStarredAgent(user_id: number, agent_id: number): Promise<StarredAgentResponse> {
    const response = await fetch(`/starredagents/create`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: user_id,
            agent: agent_id
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Project info.");

    }
    const parsedResponse: StarredAgentResponse = await response.json();
    return parsedResponse;
}


//Recent Agents CRUD services

export async function getRecentAgents(id: any): Promise<StarredAgentResponse[]> {
    const response = await fetch(`/recentagents/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch Recent Agents.");
    }
    const parsedResponse: StarredAgentResponse[] = await response.json();
    return parsedResponse;
}

export async function createRecentAgent(user_id: number, agent_id: number): Promise<RecentAgentResponse[]> {

    const response = await fetch(`/recentagents/create`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: user_id,
            agent: agent_id
        })
    });
    if (!response.ok) {
        throw new Error("Failed to create Recent Agent.");
    }
    const parsedResponse: RecentAgentResponse[] = await response.json();
    return parsedResponse;
}

//Recent Teams services
export async function getRecentTeam(id: any): Promise<RecentTeamResponse[]> {
    const response = await fetch(`/recentteam/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch Recent Teams.");
    }
    const parsedResponse: RecentTeamResponse[] = await response.json();
    return parsedResponse;
}
export async function createRecentTeam(user_id: number, team_id: number): Promise<RecentTeamResponse> {
    const response = await fetch(`/recentteam/create`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: user_id,
            team: team_id,
        })
    });
    if (!response.ok) {
        throw new Error("Failed to create Recent Team.");
    }
    const parsedResponse: RecentTeamResponse = await response.json();
    return parsedResponse;
}
//Recent Project services
export async function getRecentProject(id: any): Promise<RecentProjectResponse[]> {
    const response = await fetch(`/recentproject/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch Recent Projects.");
    }
    const parsedResponse: RecentProjectResponse[] = await response.json();
    return parsedResponse;
}

export async function createRecentProject(user_id: number, project_id: number): Promise<RecentProjectResponse> {
    const response = await fetch(`/recentproject/create`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user: user_id,
            project: project_id,
        })
    });
    if (!response.ok) {
        throw new Error("Failed to create Recent Projects.");
    }
    const parsedResponse: RecentProjectResponse = await response.json();

    return parsedResponse;
}


// Hubspot Support services

export async function hubSpotFormSubmit(options: SupportRequest): Promise<any> {
    const response = await fetch(`/hubspot/form/submit`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            agent_id: options.agent_id,
            description_ejento: options.description_ejento,
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit support ticket");
    }

    const parsedResponse: any = await response.json();
    return parsedResponse;
}
export async function verifyToken(options: VerifyTokenRequest): Promise<VerifyTokenResponse> {
    const response = await fetch(`/login/verify`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            email: options.email,
            token: options.token,
            password: options.password,
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to verify token. Please try again.");
    }
    const parsedResponse: VerifyTokenResponse = await response.json();
    return parsedResponse;
}

export async function generateOTP(options: getTokenRequest): Promise<getTokenResponse> {
    const response = await fetch(`/login/token`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            email: options.email,

        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to generate token. Please try again.");
    }
    const parsedResponse: getTokenResponse = await response.json();
    return parsedResponse;
}

export async function editUserRole(options: EditUserRoleRequest): Promise<EditUserRoleResponse> {
    const response = await fetch(`/team/editrole/${options.team_id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            team_id: options.team_id,
            user_id: options.user_id,
            role: options.role,
            modified_by: options.modified_by
        })
    });

    if (!response.ok) {
        throw new Error("Failed to update user role.");
    }
    const parsedResponse: EditUserRoleResponse = await response.json();
    return parsedResponse;
}

// Analytics

// Get total count of agents,teams, users and projects

export async function getTotalCounts(): Promise<TotalCounts> {
    const response = await fetch(`/analytics/totalcount`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch count.");
    }
    const parsedResponse: TotalCounts = await response.json();
    return parsedResponse;
}

//Get most active agents

export async function getActiveAgents(): Promise<MostActiveAgents[]> {
    const response = await fetch(`/analytics/recentagents`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch count.");
    }
    const parsedResponse: MostActiveAgents[] = await response.json();
    return parsedResponse;
}

//Get most active users

export async function getActiveUsers(count: number): Promise<MostActiveUsers> {
    const response = await fetch(`/analytics/user-engagement/${count}/`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch active users.");
    }
    const parsedResponse: MostActiveUsers = await response.json();
    return parsedResponse;
}

//Get Recently added users

export async function getRecentUsers(): Promise<RecentlyAddedusers[]> {
    const response = await fetch(`/analytics/recentusers`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch count.");
    }
    const parsedResponse: RecentlyAddedusers[] = await response.json();
    return parsedResponse;
}
//Get tokens of users
export async function getTokensOfUsers(): Promise<UserToken[]> {
    const response = await fetch(`/analytics/user-token`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch user tokens.");
    }
    const parsedResponse: UserToken[] = await response.json();
    return parsedResponse;
}

//Get tokens of agents
export async function getTokensOfAgents(): Promise<AgentTokenData> {
    const response = await fetch(`/analytics/agent-token`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch agent tokens.");
    }
    const parsedResponse: AgentTokenData = await response.json();
    return parsedResponse;
}

//Get tokens of teams
export async function getTokensOfTeams(): Promise<TeamTokenResponse> {
    const response = await fetch(`/analytics/team-token`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch team tokens.");
    }
    const parsedResponse: TeamTokenResponse = await response.json();
    return parsedResponse;
}

//Get daily tokens
export async function getDailyTokens(): Promise<DailyTokensData> {
    const response = await fetch(`/analytics/dailytokens`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch daily tokens.");
    }
    const parsedResponse: DailyTokensData = await response.json();
    return parsedResponse;
}

//Get weekly tokens
export async function getWeeklyTokens(): Promise<WeeklyTokensData> {
    const response = await fetch(`/analytics/weeklytokens`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch weekly tokens.");
    }
    const parsedResponse: WeeklyTokensData = await response.json();
    return parsedResponse;
}

//Get monthly tokens
export async function getMonthlyTokens(): Promise<MonthlyTokensData> {
    const response = await fetch(`/analytics/monthlytokens`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch monthly tokens.");
    }
    const parsedResponse: MonthlyTokensData = await response.json();
    return parsedResponse;
}

//Get user tokens
export async function getTokensByUser(): Promise<userTokenData> {
    const response = await fetch(`/analytics/user-token`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch user tokens.");
    }
    const parsedResponse: userTokenData = await response.json();
    return parsedResponse;
}

//Get user engagements
export async function getEngagementByUser(): Promise<userEngagementData> {
    const response = await fetch(`/analytics/user-engagement`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch user engagement.");
    }
    const parsedResponse: userEngagementData = await response.json();
    return parsedResponse;
}

//Get agent engagements
export async function getEngagementByAgents(): Promise<AgentEngagementData> {
    const response = await fetch(`/analytics/agent-engagement`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch agent engagement.");
    }
    const parsedResponse: AgentEngagementData = await response.json();
    return parsedResponse;
}

//Get team engagements
export async function getEngagementByTeams(): Promise<TeamEngagementResponse> {
    const response = await fetch(`/analytics/team-engagement`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch team engagement.");
    }
    const parsedResponse: TeamEngagementResponse = await response.json();
    return parsedResponse;
}

//Get daily engagements
export async function getDailyEngagements(): Promise<DailyEngagementResponse> {
    const response = await fetch(`/analytics/dailyengagement`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch daily engagements.");
    }
    const parsedResponse: DailyEngagementResponse = await response.json();
    return parsedResponse;
}

//Get weekly engagements
export async function getWeeklyEngagements(): Promise<WeeklyEngagementResponse> {
    const response = await fetch(`/analytics/weeklyengagement`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch weekly engagements.");
    }
    const parsedResponse: WeeklyEngagementResponse = await response.json();
    return parsedResponse;
}

//Get monthly engagements
export async function getMonthlyEngagements(): Promise<MonthlyEngagementResponse> {
    const response = await fetch(`/analytics/monthlyengagement`, {
        method: "GET",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch monthly engagements.");
    }
    const parsedResponse: MonthlyEngagementResponse = await response.json();
    return parsedResponse;
}

export async function getOrgOfUser(id: any): Promise<OrganizationResponse> {
    const response = await fetch(`/organization/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch Organization Name");
    }
    const parsedResponse: OrganizationResponse = await response.json();
    return parsedResponse;
}

export async function getConfigOfOrg(id: any): Promise<ConfigurationResponse> {
    const response = await fetch(`/configuration/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch Configuration");
    }
    const parsedResponse: ConfigurationResponse = await response.json();
    return parsedResponse;
}

export async function editModelConfig(options: EditModelConfigurationRequest): Promise<ConfigurationResponse> {
    const response = await fetch(`/configuration/editmodel/${options.org_id}`, {
        method: "PUT",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            org_id: options.org_id,
            model_deployment: options.model_deployment,
            modified_by: options.modified_by
        })
    });

    if (!response.ok) {
        throw new Error("Failed to update model deployment.");
    }
    const parsedResponse: ConfigurationResponse = await response.json();
    return parsedResponse;
}

//Get FAQ Categories
export async function getFAQCategories(): Promise<FAQCategories[]> {
    const response = await fetch(`/faq/categories`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch FAQ categories.");
    }
    const parsedResponse: FAQCategories[] = await response.json();
    return parsedResponse;
}

//Get FAQs
export async function getFAQquestions(id: number): Promise<Questions[]> {
    const response = await fetch(`faq/category/${id}`, {

        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch FAQs.");
    }
    const parsedResponse: Questions[] = await response.json();
    return parsedResponse;
}



//Get all Tutorials
export async function getTutorials(): Promise<Tutorials[]> {

    const response = await fetch(`tutorial/getall`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch Tutorials.");
    }
    const parsedResponse: Tutorials[] = await response.json();
    return parsedResponse;
}

//Get one Tutorial
export async function getOneTutorial(id: number): Promise<OneTutorial[]> {

    const response = await fetch(`tutorial/graphics/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch Tutorial.");
    }
    const parsedResponse: OneTutorial[] = await response.json();
    return parsedResponse;
}//Get one thread

export async function getAChatThread(thread_id: number): Promise<ChatThreads> {
    const response = await fetch(`thread/get/${thread_id}`, {

        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },

    });
    if (!response.ok) {
        throw new Error("Failed to fetch chat thread.");
    }
    const parsedResponse: ChatThreads = await response.json();
    return parsedResponse;
}

//Get all Chat Threads

export async function getAllChatThreads(agent_id: number, user_id: number): Promise<ChatThreads[]> {
    const response = await fetch(`thread/getall?user=${user_id}&agent=${agent_id}`, {

        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },

    });
    if (!response.ok) {
        throw new Error("Failed to fetch chat threads.");
    }
    const parsedResponse: ChatThreads[] = await response.json();
    return parsedResponse;
}

//Get Chat by Thread

export async function getChatByThread(thread_id: number): Promise<ChatByThread[]> {
    const response = await fetch(`${thread_id}`, {

        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }

    });
    if (!response.ok) {
        throw new Error("Failed to fetch chats.");
    }
    const parsedResponse: ChatByThread[] = await response.json();
    return parsedResponse;
}

//Create Thread

export async function createThread(agent_id: number, created_by: string, user_id: number): Promise<ChatThreads[]> {
    const response = await fetch(`thread/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            agent: agent_id,
            user: user_id,
            created_by: created_by

        })
    });
    if (!response.ok) {
        throw new Error("Failed to create thread.");
    }
    const parsedResponse: ChatThreads[] = await response.json();
    return parsedResponse;
}

//Delete Thread

export async function deleteThread(thread_id: number): Promise<ChatThreads[]> {
    const response = await fetch(`thread/delete/${thread_id}`, {

        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },

    });
    if (!response.ok) {
        throw new Error("Failed to delete thread.");
    }
    const parsedResponse: ChatThreads[] = await response.json();
    return parsedResponse;
}

//Edit Thread

export async function editThread(chat_thread_id: number, modified_by: string, title: string): Promise<ChatThreads> {
    const response = await fetch(`thread/edit`, {

        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            chat_thread_id: chat_thread_id,
            title: title,
            modified_by: modified_by

        })
    });
    if (!response.ok) {
        throw new Error("Failed to edit thread.");
    }
    const parsedResponse: ChatThreads = await response.json();
    return parsedResponse;
}

// get chatlogs by thread


export async function getChatlogsByChatThread(thread_id: number): Promise<ChatLogResponse[]> {
    const response = await fetch(`chat/thread/${thread_id}`, {

        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });
    if (!response.ok) {
        throw new Error("Failed to get chatlogs by chat thread.");
    }
    const parsedResponse: ChatLogResponse[] = await response.json();
    return parsedResponse;
}

//Evaluation testing

export async function getAllEvaluationTests(agent_id: number): Promise<AllEvaluationTests[]> {
    const response = await fetch(`evaltest/all/${agent_id}`, {

        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });
    if (!response.ok) {
        throw new Error("Failed to get evaluation tests by agent.");
    }
    const parsedResponse: AllEvaluationTests[] = await response.json();
    return parsedResponse;
}

export async function getOneEvaluationTest(eval_id: number): Promise<EvaluationTest> {
    const response = await fetch(`evaltest/${eval_id}`, {

        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });
    if (!response.ok) {
        throw new Error("Failed to get evaluation test.");
    }
    const parsedResponse: EvaluationTest = await response.json();
    return parsedResponse;
}

export async function createEvaluation(options: CreateEvaluationRequest): Promise<AllEvaluationTests[]> {
    const response = await fetch(`evaltest/create`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            agent: options.agent_id,
            dataset: options.dataset,
        })
    });
    if (!response.ok) {
        throw new Error("Failed to get evaluation test.");
    }
    const parsedResponse: AllEvaluationTests[] = await response.json();
    return parsedResponse;
}

export async function getEvalDataset(agent_id: number): Promise<EvaluationDataset> {
    const response = await fetch(`evaldataset/agent/${agent_id}`, {

        method: "GET",
        headers: {
            "Content-Type": "application/json"
        },
    });
    if (!response.ok) {
        throw new Error("Failed to get evaluation dataset.");
    }
    const parsedResponse: EvaluationDataset = await response.json();
    return parsedResponse;
}

export async function createEvaluationDataset(options: CreateDatasetRequest): Promise<EvaluationDataset> {
    const response = await fetch(`evaldataset/create`, {
        method: "POST",
        headers: {
            "X-CSRFToken": cookies.get("csrftoken"),
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            agent: options.agent_id,
            dataset: options.dataset,
        })
    });
    if (!response.ok) {
        throw new Error("Failed to create evaluation test.");
    }
    const parsedResponse: EvaluationDataset = await response.json();
    return parsedResponse;
}

export async function getAllConfigs(): Promise<LLMConfigResponse[]> {
    const response = await fetch(`/config/all`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent Users.");
    }
    const parsedResponse: LLMConfigResponse[] = await response.json();
    return parsedResponse;
}

export async function getActiveConfig(): Promise<ActiveConfigResponse> {
    const response = await fetch(`/config/active`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Active Org LLM model.");
    }
    const parsedResponse: ActiveConfigResponse = await response.json();
    return parsedResponse;
}

export async function setActiveConfig(options: ActiveConfigRequest): Promise<ActiveConfigResponse> {
    const response = await fetch(`/config/agent/${options.agent_id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            llm_config_id: options.llm_config_id
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update the LLM configuration. Please try again.");
    }
    const parsedResponse: ActiveConfigResponse = await response.json();
    return parsedResponse;
}


export async function getOrgByUser(userId:number): Promise<AllOrgs[]> {
    const response = await fetch(`users/userorg/get/${userId}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch organization.");
    }
    const parsedResponse: AllOrgs[] = await response.json();
    return parsedResponse;
}

export async function switchUserOrg(options): Promise<any> {
    const response = await fetch(`users/userorg/switch`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            user_id:options.user_id,
            organization_id:options.organization_id,
        })

    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to switch organization.");
    }
    const parsedResponse: any = await response.json();
    return parsedResponse;
}

export async function getAgentAccess(id: number): Promise<ActiveAgentAccessResponse> {
    const response = await fetch(`/agent/access/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Agent Users.");
    }
    const parsedResponse: ActiveAgentAccessResponse = await response.json();
    return parsedResponse;
}


// getting agent types
export async function getAllAgentTypes(): Promise<AgentType[]> {
    const response = await fetch('/agenttype/all', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch Agent Types.');
    }

    const agentTypes: AgentType[] = await response.json();
    return agentTypes;
}

// Config And Feature Flag

export async function getChatEmbedDomain(): Promise<string> {
    const response = await fetch('/chatembeddomain', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch Chat Embed Domain.');
    }

    const result: {domain: string} = await response.json();
    return result.domain;
}

export async function getShareAgentFlag(): Promise<boolean> {
    const response = await fetch('/featureflag/share_agent', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch Share Agent Flag.');
    }

    const result: FeatureFlag = await response.json();
    return result.flag;
}

export async function getAgentAliasFlag(): Promise<boolean> {
    const response = await fetch('/featureflag/agent_alias', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch Agent Alias.');
    }

    const result: FeatureFlag = await response.json();
    return result.flag;
}

export async function getSharePointIndexFlag(): Promise<boolean> {
    const response = await fetch('/featureflag/sharepoint_index', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch SharePoint index flag.');
    }

    const result: FeatureFlag = await response.json();
    return result.flag;
}

export async function getChatAgentCreationFlag(): Promise<boolean> {
    const response = await fetch('/featureflag/chat_agent_creation', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch SharePoint index flag.');
    }

    const result: FeatureFlag = await response.json();
    return result.flag;
}


export async function getOAuthFlags(): Promise<FeatureFlag[]> {
    const response = await fetch('/featureflag/oauth', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch OAuth flags.');
    }

    const result: FeatureFlag[] = await response.json();
    return result;
}




// Sharepoint indexing

export async function getSharepointLinkByAgent(id:number): Promise<sharePointLink> {
    const response = await fetch(`sharepointfolder/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch sharepoint folder URL.');
    }

    const URL: sharePointLink = await response.json();
    return URL;
}


export async function editSharepoint(options): Promise<sharePointLink> {
    const response = await fetch(`sharepointfolder/edit`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            id:options.id,
            url:options.url,
            modified_by:options.modified_by
        })

    });
    if (!response.ok) {
        throw new Error('Failed to edit sharepoint folder URL.');
    }

    const URL: sharePointLink = await response.json();
    return URL;
}

export async function createSharepoint(options): Promise<sharePointLink> {
    const response = await fetch(`sharepointfolder/create`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body: JSON.stringify({
            user:options.user,
            agent:options.agent,
            url:options.url,
            created_by:options.created_by
        })

    });
    if (!response.ok) {
        throw new Error('Failed to create sharepoint folder URL.');
    }

    const URL: sharePointLink = await response.json();
    return URL;
}

export async function getUserRole(id:number): Promise<UserRole> {
    const response = await fetch(`project/userRole/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch the user role.');
    }

    const result: UserRole = await response.json();
    return result;
}
export async function getUserRoleByAgentId(id:number): Promise<userRoleInTeam> {
    const response = await fetch(`team/userRole/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch user role by agent.');
    }

    const URL: userRoleInTeam = await response.json();
    return URL;
}
export async function getAllProjects(): Promise<ProjectResponse[]> {
    const response = await fetch('/project/all', {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (!response.ok) {
        throw new Error("Failed to fetch organizational users.");
    }
    const parsedResponse: ProjectResponse[] = await response.json();
    return parsedResponse;
}

export async function getChatLog(userIds: number[], projectIds: number[], agentIds: number[], page: number, startDate: string, endDate: string): Promise<ChatLogByPageResponse> {
    const response = await fetch(`/chat/get?page=${page}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookies.get("csrftoken")
        },
        body:JSON.stringify({
            user_ids:userIds,
            project_ids:projectIds,
            agent_ids:agentIds,
            from_date: startDate,
            to_date: endDate,
        })
    });

    if (!response.ok) {
        throw new Error("Failed to fetch Chats for the given agent and user.");
    }
    const parsedResponse: ChatLogByPageResponse = await response.json();
    return parsedResponse;
}

export async function getChatLogExport(userIds: number[], projectIds: number[], agentIds: number[],  page: number, startDate: string, endDate: string ): Promise<ExportChatLogResponses> {
    const response = await fetch(`chat/get/all`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookies.get("csrftoken")
      },
      body: JSON.stringify({
        user_ids: userIds,
        project_ids: projectIds,
        agent_ids: agentIds,
        from_date: startDate,
        to_date: endDate,
      })
    });
  
    if (!response.ok) {
      throw new Error("Failed to export chatlogs.");
    }
    const parsedResponse: ExportChatLogResponses = await response.json();
    return parsedResponse;
  }



// Fetch Envs

export async function getEnv(name:string): Promise<any> {
    const response = await fetch(`/env/get?name=${name}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if (!response.ok) {
        throw new Error('Failed to fetch env.');
    }

    const result: any = await response.json();
    return result;
}

export async function allAgentWithUser(): Promise<AgentCardResponseWithTeam[]> {
    const response = await fetch("agent/user/configs", {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    if (!response.ok) {
        throw new Error("Failed to fetch Agent configurations.");
    }
    const parsedResponse: AgentCardResponseWithTeam[] = await response.json();
    return parsedResponse;
}

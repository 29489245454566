import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { KTSVG } from "../../../../_metronic/helpers"
import { Card2 } from "../../../../_metronic/partials/content/cards/Card2";
import { AgentCardResponse, RatingsResponse, decryptData, fetchLoginCookie, getAllRatings, getAllAgentsPageFromUserTeams, getAllAgentsByPage, AgentCardResponse2 } from '../../../../api';
import CircularProgress from '@mui/material/CircularProgress';
import styles from '../../profile/ProfileStyle.module.css';
import style from './agents.module.css';
import { Pagination, Stack } from "@mui/material";

interface props {
    setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>;
}
const TeamAgent = ({ setRefreshChatPageOnAgentCreation }: props) => {

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [loggedInUserId, setLoggedInUserId] = useState<number>();
    const [ispromptDataLoading, setispromptDataLoading] = useState(true);
    const [allRatings, setRatingsData] = useState<RatingsResponse[]>([]);
    const [agents, setAgents] = useState<AgentCardResponse2[]>([]);
    const width = window.innerWidth;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState<number>();

    useEffect(() => {

        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);
        if (userId) {
            setLoggedInUserId(userId);
            getAllAgentRatings()
            handlePageChange(currentPage)
        }

    }, []);

    const handlePageChange = async (page) => {
        setCurrentPage(page);
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);
        const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
        if (userId) {
            if (isSuperUser) {
                getAllAgentsForSU(page);
            }
            else {
                getAgentsFromMyTeams(userId, page);
            }
        }
    }

    const getAllAgentRatings = async () => {
        try {
            const response = await getAllRatings();
            setRatingsData(response);
        } catch (error) {
            console.error(error);

        }
    };
    const getAllAgentsForSU = async (page: number) => {
        try {
            const response = await getAllAgentsByPage(page);
            setTotalPages(response.num_pages);
            setAgents(response.agents);
            setispromptDataLoading(false)
        } catch (error) {
            console.error(error);
        }
    };
    const getAgentsFromMyTeams = async (id: any, page: number) => {
        try {
            const response = await getAllAgentsPageFromUserTeams(id, page);
            setTotalPages(response.num_pages);
            setAgents(response.agents);
            setispromptDataLoading(false)
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className={style.mb3}>
    

            <p className={`mt-6 text-muted ${style.smHeading}`}>Browse Agents of all your teams</p>

            <div className={`${styles.agentCardMain} row g-6 g-xl-9`} style={{flexDirection: "column", alignItems: "center"}} >
            <div className='row g-3 g-xl-9' style={{ justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
                    {!ispromptDataLoading && loggedInUserId ? (
                        <>
                            {agents.length > 0 ? agents

                                .filter((item) => {
                                    // Filter agents based on search query

                                    return item.agent_name.toLowerCase().includes(searchTerm.toLowerCase());

                                })
                                .filter((item) => {
                                    return item.created_by.trim() != "System"

                                })

                                .map((item: AgentCardResponse2, index: number) => {
                                    // Render other items except the activated one
                                    return (
                                        <div key={index} className={`${styles.agentCardContainer} row-cols-1 row-cols-sm-2 row-cols-lg-3`}>
                                            <Card2
                                                setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                                                agentUpdateAfterDelete={false}
                                                key={index}
                                                data={item}
                                                currentUserId={loggedInUserId!}
                                                viewMode='default'
                                                allRatings={allRatings}
                                                setRatingsData={setRatingsData}
                                            /></div>
                                    );
                                }) : <p className={style.muted}>No Agents Found</p>
                            }
                        </>
                    ) :
                        (
                            <div className={style.CircularProgress}>
                                <CircularProgress />
                            </div>
                        )}
                </div>
            </div>

            {agents.length > 0 && (
                <Stack className='pagination' style={{ position: agents.length > 0 ? 'relative' : 'fixed', left: agents.length > 0 ? '50%' : '60%' }} spacing={2} justifyContent="center" alignItems="center" mt={2}>
                    <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={(event, value) => handlePageChange(value)}
                        sx={{ button: { color: 'grey' } }}
                        variant='outlined'
                    />
                </Stack>
            )}

        </div>
    )
}
export { TeamAgent }

import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { KTSVG } from "../../../_metronic/helpers"
import { Card2 } from "../../../_metronic/partials/content/cards/Card2";
import { getTeamsByUser, AgentCardResponse, AgentCategoryResponse, RatingsResponse, decryptData, fetchLoginCookie, getAgentByCategory, getAllRatings, AgentCardResponse2 } from '../../../api';
import bot from '../../../media_dsd/bot.png'
import CircularProgress from '@mui/material/CircularProgress';
import BotDark from '../../../media_dsd/bot_white.png'
import styles from './ProfileStyle.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import style from '../apps/TeamAgents/agents.module.css'

interface props {
    setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>;
}
const LandingProjectPage = ({ setRefreshChatPageOnAgentCreation }: props) => {

    const [searchTerm, setSearchTerm] = useState<string>('')
    const [loggedInUserId, setLoggedInUserId] = useState<number>();
    const [ispromptDataLoading, setispromptDataLoading] = useState(true);
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [allRatings, setRatingsData] = useState<RatingsResponse[]>([]);
    const [Isteam, setIsTeam] = useState<boolean>(false)
    const [show, setShow] = useState(false);
    const [staffUser, setStaffUser] = useState(false);
    const [agentsByCategoryData, setAgentsByCategoryData] = useState<AgentCategoryResponse[]>([]);
    const agentResponses = agentsByCategoryData.map((item) => item.agent);
    const width = window.innerWidth;

    useEffect(() => {
        const userInfo = fetchLoginCookie();

        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);
        // const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
        const isStaffUser = decryptedRes?.is_staff?.toLowerCase() === "true";


        if (isStaffUser) {
            setStaffUser(true)
        }

        if (userId) {
            setLoggedInUserId(userId);
            getAgentsByCategory();
            getAllAgentRatings()
            getTeamByUser(userId)
        }

    }, []);

    const getAllAgentRatings = async () => {
        try {
            const response = await getAllRatings();
            setRatingsData(response);
        } catch (error) {
            console.error(error);

        }
    };
    const getAgentsByCategory = async () => {
        try {
            const response = await getAgentByCategory();
            setAgentsByCategoryData(response);
            setispromptDataLoading(false)
        } catch (error) {
            console.error(error);
        }
    };

    const getTeamByUser = async (id: any) => {
        try {
            const response = await getTeamsByUser(id);

            if (response.length === 0) {

                setIsTeam(true)
                setShow(true)
            }

        } catch (error) {
            console.error(error);
        }
    };

    const handleClose = () => setShow(false);

    const handleSupportClick = () => {
        setShow(false);
        let element: HTMLElement | null = document.getElementById('support');

        if (element !== null) {
            element.click()
        }
    }

    return (
        <div className={style.mb3}>
            {
                Isteam && (staffUser === false) &&
                <Modal className={styles.welcomeModal} centered aria-labelledby="contained-modal-title-vcenter" show={show} handleClose={handleClose} >
                    <Modal.Header className={styles.welcomeModalHeader}>
                        <span >Welcome to Ejento AI</span>
                    </Modal.Header>
                    <Modal.Body>
                        Please contact an admin to get yourself enrolled in a team. For that, you can create a support ticket by clicking <a href="javascript:void(0);" onClick={handleSupportClick}>here</a>.<br /><br />
                        Meanwhile, have fun with our template agents! You can start chatting by clicking on their name.<br /><br />
                    </Modal.Body>
                    <Modal.Footer className={styles.welcomeModalFooter}>
                        <Button className={styles.okayBtn} size="sm" variant="primary" onClick={handleClose}>
                            Okay
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <div className={styles.header} >
                Welcome To Ejento AI
            </div>

            <p className={styles.fadeInText} >Where Data Meets Conversation</p>
            <p  className={`text-muted ${styles.smallHeader}`}>Browse Templates</p>
            <div className={`${styles.agentCardMain} row g-6 g-xl-9`} style={{flexDirection: "column", alignItems: "center"}} >
            <div className='row g-3 g-xl-9' style={{ justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
                    {!ispromptDataLoading && loggedInUserId ? (
                        <>
                            {agentResponses.length > 0 && agentResponses
                                // .filter((item) => item.created_by === "System")
                                .filter((item) => {
                                    // Filter agents based on search query
                                    return item.agent_name.toLowerCase().includes(searchTerm.toLowerCase());

                                })

                            .map((item: AgentCardResponse2, index: number) => {
                                    // Render other items except the activated one
                                    return (
                                        <div className={`${styles.agentCardContainer} row-cols-1 row-cols-sm-2 row-cols-lg-3`}>
                                            <Card2
                                         setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation}
                                         agentUpdateAfterDelete={false}

                                                key={index}
                                         data={item}
                                         currentUserId={loggedInUserId!}
                                         viewMode='default'

                                         allRatings={allRatings}
                                         setRatingsData={setRatingsData}
                                            /></div>
                                    );
                                })}</>) : (
                        <div className={styles.CircularProgress}>
                            <CircularProgress /></div>
                    )}

                </div>
            </div>

        </div>
    )
}
export { LandingProjectPage }

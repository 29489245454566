import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { createSharepoint, getOneAgent, getOneAgentForMagicLink, getSharepointLinkByAgent } from '../../../../../api/api';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { decryptData, fetchLoginCookie, sharePointLink } from '../../../../../api';
import styles from './Step.module.scss'
import { ArrowSquareOut } from '@phosphor-icons/react';
import { Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

export const SharePointInput = () => {
    const { agentId } = useParams()
    const [sharepointURL, setSharePointURL] = useState<string>('')
    const [isLinkExist, setIsLinkExist] = useState<boolean>(false)
    const [userEmail, setUserEmail] = useState('')
    const [activeAgentId, setActiveAgentId] = useState(0)
    const [loggedInUserId, setLoggedInUserId] = useState<number>();
    const [sharepointFolder, setSharePointFolder] = useState<sharePointLink>()
    const [isSuper, setIsSuper] = useState<boolean>(false);
    const [isStaff, setIsStaff] = useState(false);
    const [createdBy, setCreatedBy] = useState<string | null>("");
    const [isLoading, setIsLoading] = useState(false)
    const [enableSaveBtn, setEnableSaveBtn] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));

    useEffect(() => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const email = decryptedRes?.user
        const userId = parseInt(decryptedRes?.id);
        const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
        const isStaffUser = decryptedRes?.is_staff?.toLowerCase() === "true";
        if (isStaffUser) {
            setIsStaff(true);
        }
        if (isSuperUser) {
            setIsSuper(true);
        }
        if (email) {
            setUserEmail(email)
        }
        if (userId) {
            setLoggedInUserId(userId)
        }
        if (agentId) {
            const agentID = decodeURIComponent(decryptData(agentId));
            const parsedAgentID = parseInt(agentID, 10);
            getsharepointFolderLink(parsedAgentID)
            setActiveAgentId(parsedAgentID)
            fetchActiveAgentCard(parsedAgentID)
        }
    }, [])

    useEffect(() => {
        if (sharepointURL.length > 0) {
            setEnableSaveBtn(true)
        } else {
            setEnableSaveBtn(false)
        }
    }, [sharepointURL])
    const handleSharePointURLchange = (e: any) => {
        setSharePointURL(e.target.value)
    }
    const fetchActiveAgentCard = async (id: number) => {
        try {
            let response;
            if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
                 response = await getOneAgentForMagicLink(id);
            } else {
                 response = await getOneAgent(id)
            }
            if(response){
                setCreatedBy(response.created_by);
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    const getsharepointFolderLink = async (agent_id: number) => {
        try {
            setIsLoading(true)
            const response = await getSharepointLinkByAgent(agent_id)
            if (response) {
                setSharePointURL(response.url)
                setSharePointFolder(response)
                if (response.url.length > 0) {
                    setIsLinkExist(true)
                }
            }
            else {
                setIsLinkExist(false)
            }
        }
        catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    const createLink = async () => {
        setIsLoading(true)
        try {
            const options = {
                user: loggedInUserId,
                agent: activeAgentId!,
                url: sharepointURL,
                created_by: userEmail
            }
            const response = await createSharepoint(options)
            if (response) {
                setSharePointURL(response.url)
                setIsLinkExist(true)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    // const editLink = async () => {
    //     try {
    //         setIsLoading(true)
    //         const options = {
    //             id: sharepointFolder?.id,
    //             url: sharepointURL,
    //             modified_by: userEmail
    //         }
    //         const response = await editSharepoint(options)
    //         if (response) {
    //             setSharePointURL(response.url)
    //         }
    //     } catch (e) {
    //         console.error(e)
    //     } finally {
    //         setIsLoading(false)
    //     }
    // }
    const openLinkInNewTab = () => {
        window.open(sharepointURL, '_blank', 'noopener,noreferrer');
    }

    return (


        <div style={{minHeight:"190px", paddingTop:"2%"}}>
            <InputGroup className="mb-3" >
                <div className={styles.scrollableInputBox} >
                    <textarea
                        className={styles.scrollableInput}
                        value={sharepointURL}
                        onChange={(e) => handleSharePointURLchange(e)}

                        placeholder="SharePoint folder link"
                        readOnly={!(isSuper || isStaff || (userEmail === createdBy)) || isLinkExist}
                        aria-label="SharePoint folder link"
                        aria-describedby="basic-addon2"
                    />
                </div>
                <>
                    {
                        isLoading ?
                            <button
                                type='button'
                                className={`btn btn-light-primary me-1 ${styles.btnText}`}
                                data-kt-menu-placement='bottom-end'
                            >
                                <span className='indicator-progress d-block' >
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            </button> : isLinkExist ?
                                <Tooltip title='Open in new tab'><button
                                    type='button'
                                    className={`btn btn-light-primary me-1 ${styles.btnText}`}
                                    onClick={openLinkInNewTab}
                                    data-kt-menu-placement='bottom-end'
                                >
                                    <span id='saveButton' className={`${styles.KBbtn}`}><ArrowSquareOut size={20} weight="light" /></span>
                                </button></Tooltip> : (enableSaveBtn && (isSuper || isStaff || createdBy === userEmail)) ?

                                    <button
                                        type='button'
                                        className={`btn btn-light-primary me-1 ${styles.btnText}`}
                                        onClick={createLink}
                                        data-kt-menu-placement='bottom-end'
                                    >
                                        <span id='saveButton' className={`${styles.KBbtn}`}>Save</span>
                                    </button> : <></>
                    }
                </>
            </InputGroup>
        </div>
    )
}
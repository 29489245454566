import { useState, useEffect, Dispatch, SetStateAction, useRef } from 'react'
import styles from '../ProfileStyle.module.css'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PersonIcon from '@mui/icons-material/Person';

import { getChatAgentCreationFlag, getAllConfigs, AgentCardRequest, createAgent, decryptData, fetchLoginCookie, createAgentChat, ChatRequest, NewChatRequest, NewChatResponse, EditAgentCardRequest, editAgent, encryptData, getOneAgent, getPastPromptsByAgent, PastPromptResponse, getAgentByCategory, AgentCategoryResponse, ChatTurn, ChatCommentResponse, AskResponse, RatingsResponse, AgentCardResponse, Approaches, chatApi, getAgentByProject, NewChatTurn, InstructionResponse, getAllAgentTypes, AgentType, LLMConfigResponse, getOneAgentForMagicLink, createRecentAgent, CreateCustomInstructionRequest, createCustomInstructions } from '../../../../api';



import 'react-toastify/dist/ReactToastify.css';
import { IndexData } from '../../../../_metronic/layout/components/AgentToolPanel.tsx/IndexData';
import { ChatInterface, Message } from './ChatInterface';
import Tooltip from "@mui/material/Tooltip";
import React from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Swal from "sweetalert2";
import { useNavigate } from "react-router";
import { useLocation, useParams } from 'react-router-dom';
import { Checkbox, DialogProps, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, IconButton, Typography, Grid, } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnalysisPanelTabs } from '../../../../_metronic/partials/chat/AnalysisPanelTabs';
import BuildIcon from '@mui/icons-material/Build';
import defaultImg from '../../../../media_dsd/NewAgent.jpg'
import { PreconfigAgents } from '../../../../_metronic/partials/modals/create-app-stepper/steps/PreconfigAgents';
import LoopOutlinedIcon from '@mui/icons-material/Loop';
import { Form, Modal } from 'react-bootstrap';
import { Menu, List, ListItem, Divider, Radio } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Users, Lock, UsersThree } from "@phosphor-icons/react"; import { Check, X, Warning, WarningOctagon, Info, PencilSimple, Plus } from "@phosphor-icons/react";
import Chip from '@mui/material/Chip';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useMediaQuery } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress'

import defaultBotImg from '../../../../media_dsd/defaultBotImg.png'
import defaultBotImg1 from '../../../../media_dsd/defaultBotImg1.png';
import defaultBotImg2 from '../../../../media_dsd/defaultBotImg2.png';
import defaultBotImg3 from '../../../../media_dsd/defaultBotImg3.png';
import defaultBotImg4 from '../../../../media_dsd/defaultBotImg4.jpg';
import defaultBotImg5 from '../../../../media_dsd/defaultBotImg5.jpg';
import defaultBotImg6 from '../../../../media_dsd/defaultBotImg6.png';
import defaultBotImg7 from '../../../../media_dsd/defaultBotImg7.png';
import defaultBotImg8 from '../../../../media_dsd/defaultBotImg8.png';

interface Props {
    isEditView: boolean;
    setHasNameError: Dispatch<SetStateAction<boolean>>
    isNameExists: boolean
    setIsNameExists: Dispatch<SetStateAction<boolean>>
    setCurrentTab?: Dispatch<SetStateAction<string>>
    setRefreshChatPageOnAgentCreation: Dispatch<SetStateAction<boolean>>
}


const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0275d8',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const ConfigureAgent = ({ setRefreshChatPageOnAgentCreation, isEditView, setHasNameError, isNameExists, setIsNameExists, setCurrentTab }: Props) => {

    const [myagentName, setMyAgentName] = useState('New Agent');
    const [myagentDesc, setMyAgentDesc] = useState('');
    const [newUrl, setNewUrl] = useState('');
    const [projectId, setProjectId] = useState<number>(-1);
    const [domain, setDomain] = useState("");
    const [sampleQuestion1, setSampleQuestion1] = useState("");
    const [sampleQuestion2, setSampleQuestion2] = useState("");
    const [sampleQuestion3, setSampleQuestion3] = useState("");
    const [clonedFrom, setClonedFrom] = useState<number | null>(null);
    const [needAlias, setNeedAlias] = useState<boolean>(true);
    const [isConfigureDivVisible, setIsConfigureDivVisible] = useState(false);
    const [isCreateDivVisible, setIsCreateDivVisible] = useState(true);
    const [showIndexModal, setShowIndexModal] = useState<boolean>(false)
    const [activeButton, setActiveButton] = useState<string | null>("chat");
    const [selectedAgent, setSelectedAgent] = useState<number>(-1);
    const [chatHistory, setChatHistory] = useState<NewChatTurn[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [userBotChat, setUserBotChat] = useState<UserBotChatInterface[]>([]);
    const [userMessage, setUserMessage] = useState('');
    const [agentName, setAgentName] = useState('New Agent');
    const [agentDesc, setAgentDesc] = useState('');
    const [category, setCategory] = useState<number | null>(null);
    
    // Array of default images
    const defaultImages = [
        defaultBotImg,
        defaultBotImg1,
        defaultBotImg2,
        defaultBotImg3,
        defaultBotImg4,
        defaultBotImg5,
        defaultBotImg6,
        defaultBotImg7,
        defaultBotImg8
    ];
    const getRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * defaultImages.length);
        return defaultImages[randomIndex];
    };

    const [myagentImageUrl, setMyAgentImageUrl] = useState(getRandomImage());
    const [agentImageUrl, setAgentImageUrl] = useState<string | File>(getRandomImage())
    const [showImg, setShowImg] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loggedInUserId, setLoggedInUserId] = useState<number>(-1);
    const [loggedInUserEmail, setLoggedInUserEmail] = useState<string>('');
    const [saveOrUpdateLoading, setSaveOrUpdateLoading] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const navigate = useNavigate();
    const { agentId } = useParams()
    const [promptsLoading, setPromptsLoading] = useState<boolean>(true);
    const [usePastPrompts, setUsePastPrompts] = useState<boolean>(false);
    const [selectedPastPrompt, setSelectedPastPrompt] = useState<string | null>(null);
    const [pastInstructions, setPastInstructions] = useState<Array<{ prompt: string, date: string }>>([]);
    const [showTemplates, setShowTemplates] = useState(false);
    const [agentsByCategoryData, setAgentsByCategoryData] = useState<AgentCategoryResponse[]>([]); const [chatBtnClicked, setChatBtnClicked] = useState<boolean>(false);
    const [agents, setAgents] = useState<AgentCardResponse[]>([])
    const [promptValue, setPromptValue] = useState('');
    const [comparedAgentName, setComparedAgentName] = useState('');
    //chatApi states
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    const [agentActiveConfig, setAgentActiveConfig] = useState<number>();
    const [isAgentConfigLoading, setIsAgentConfigLoading] = useState<boolean>(true);
    const [agentConfigData, setAgentConfigData] = useState<AgentCardResponse>();
    const [agentConfigName, setAgentConfigName] = useState("");
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const lastQuestionRef = useRef<string>("");
    const [isLoadings, setIsLoadings] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [activeCitation, setActiveCitation] = useState<string>();
    const [answers, setAnswers] = useState<[user: string, response: AskResponse, id: number, is_upvote: boolean, is_downvote: boolean, comments: ChatCommentResponse[]][]>([]);
    const [loggedInUser, setLoggedInUser] = useState<boolean>(false);
    const [responseIndex, setResponseIndex] = useState(0);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [useSources, setUseSources] = useState<boolean>(true);
    const [toggleUseSources, setToggleUseSources] = useState<boolean>(false);
    const [question, setQuestion] = useState('');
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [empty, setEmpty] = useState<boolean>(true);
    const [agentAlias, setAgentAlias] = useState("");
    const [boterror, setBotError] = useState<boolean>(false);
    const [previousUserQuery, setPreviousUserQuery] = useState('');
    const chatBotContainerRef = useRef<HTMLDivElement>(null);
    const [starterClick, setStarterClick] = useState<boolean>(false);
    const [disabledSendButton, setDisabledSendButton] = useState<boolean>(false);
    const [regenResp, setRegenResp] = useState<boolean>(false);
    const [access, setAccess] = useState("Only me");
    const [showAccessModal, setShowAccessModal] = useState(false);
    const [agentStatus, setAgentStatus] = useState('Draft');
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [custom_instructions, setCustomInstructions] = useState<string[]>([""]);
    const [chipCustomInstructions, setChipCustomInstructions] = useState<string[]>([""]);
    const [emptyInputIndex, setEmptyInputIndex] = useState(-1);
    const [agentTypes, setAgentTypes] = useState<AgentType[]>([]);
    const [agentLLMConfigs, setAgentLLMConfigs] = useState<LLMConfigResponse[]>([]);
    const [selectedAgentType, setSelectedAgentType] = useState<string>("QnA")
    const [selectedAgentTypeId, setSelectedAgentTypeId] = useState<number>(1)

    const [chatAgentCreationFlag, setChatAgentCreationFlag] = useState<boolean>(false)
    const [agentCreationLoading, setAgentCreationLoading] = useState<boolean>(true)
    const [selectedAgentLLM, setSelectedAgentLLM] = useState<string|null>()
    const [selectedAgentLLMId, setSelectedAgentLLMId] = useState<number|null>()

    const [selectedAgentLLMDeployement, setSelectedAgentLLMDeployement] = useState<string | null>()

    const [isSuperUser, setIsSuperUser] = useState<boolean>(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));


    useEffect(() => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);
        if (userId) {
            setLoggedInUser(true);
            setLoggedInUserId(userId);
        }
    }, []);


    useEffect(() => {
        const url = window.location.href;
        const regex = /tab=(chat|configure|knowledgeBase|evaluation)/;
        const match = url.match(regex);
        if (match && match[1] && loggedInUserId != -1) {
            const extractedId = decodeURIComponent(match[1]);
            addRecentAgent(extractedId);
        }
    }, [loggedInUserId, agentId]);

    const addRecentAgent = async (id) => {
        const agentID = decodeURIComponent(decryptData(agentId));
        const parsedAgentID = parseInt(agentID, 10);
        try {
            if (loggedInUserId) {
                const response = await createRecentAgent(loggedInUserId, parsedAgentID)
            }
        } catch (error) {
            console.error(error)
        }
    }


    const handleTypeRadioClick = (typeId, typeName, event) => {
        event.stopPropagation();
        setSelectedAgentTypeId(typeId);
        setSelectedAgentType(typeName);
    };

    const handleLLMRadioClick = (llmId, llmName, llmModel, event) => {
        event.stopPropagation();
        setSelectedAgentLLMId(llmId);
        setSelectedAgentLLM(llmName);
        setSelectedAgentLLMDeployement(llmModel[0])

    };

    const fetchAgentTypes = async () => {
        try {
            const response = await getAllAgentTypes();
            setAgentTypes(response);
        }
        catch (error: any) {
            console.error('Failed to fetch agent types:', error);
        }

    };

    const fetchAgentLLM = async (agentConfigData: AgentCardResponse) => {
        try {
            const response = await getAllConfigs();
            setAgentLLMConfigs(response);
            if (agentConfigData?.llm_config) {
                setSelectedAgentLLMId(agentConfigData?.llm_config)
                setSelectedAgentLLM(response.filter((model) => model.id === agentConfigData?.llm_config)[0]?.model_name)
                setSelectedAgentLLMDeployement(response.filter((model) => model.id === agentConfigData?.llm_config)[0]?.model_deployment[0])

            }
            else {
                await fetchDefaultLLM();
            }

        }
        catch (error: any) {
            console.error('Failed to fetch agent LLM:', error);
        }
    };
    const fetchDefaultLLM = async () => {
        try {
            const response = await getAllConfigs();
            setAgentLLMConfigs(response);
            // if (!agentId){
            setSelectedAgentLLM(response.filter((model) => model.is_default)[0].model_name)
            setSelectedAgentLLMDeployement(response.filter((model) => model.is_default)[0].model_deployment[0])
            setSelectedAgentLLMId(response.filter((model) => model.is_default)[0].id)
            // }
        }
        catch (error: any) {
            console.error('Failed to fetch LLM:', error);
        }
    };

    useEffect(() => {
        fetchAgentTypes();
        fetchChatAgentCreationFlag()
        fetchAgentInfo(agentId)
    }, []);


    const fetchChatAgentCreationFlag = async () => {
        setAgentCreationLoading(true)
        const response = await getChatAgentCreationFlag()
        setChatAgentCreationFlag(response)
        if (!response) {
            setIsCreateDivVisible(false)
            setIsConfigureDivVisible(true)
        }
        setAgentCreationLoading(false)
    }


    interface UserBotChatInterface {
        role: string;        //either user or bot 
        message: string;     //reponse of bot
    }
    const handleInstructionChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;
        const updatedInstructions = [...custom_instructions];
        updatedInstructions[index] = value;
        setCustomInstructions(updatedInstructions);

        // If user is typing in the last input and value is not empty, add a new empty input field
        if (index === custom_instructions.length - 1 && value.trim() !== "") {
            setCustomInstructions(prevInstructions => [...prevInstructions, ""]);
        }
    };

    const openCustomInstModal = () => {
        if (isEditView) {
            if (!custom_instructions.includes("")) {
                const instructionsWithEmptyBar = [...custom_instructions, ""];
                setCustomInstructions(instructionsWithEmptyBar);
                const emptyIndex = instructionsWithEmptyBar.findIndex(instruction => instruction === "");
                setEmptyInputIndex(emptyIndex);
            }
        }

        else {
            if (custom_instructions.length >= 0 && custom_instructions.every(instruction => instruction.trim() !== "")) {
                const instructionsWithEmptyBar = [...custom_instructions, ""];
                setCustomInstructions(instructionsWithEmptyBar);

            }
            setEmptyInputIndex(-1);
        }
        setModalOpen(true)
    };

    const handleSaveClick = () => {
        const nonEmptyInstructions = custom_instructions.filter(instruction => instruction.trim() !== "");
        setCustomInstructions(nonEmptyInstructions);
        setModalOpen(false)
    };

    const removeInstruction = (index: number) => {
        const updatedInstructions = [...custom_instructions];
        updatedInstructions.splice(index, 1);
        setCustomInstructions(updatedInstructions);
    };

    const scrollToBottom = () => {
        chatBotContainerRef.current?.scrollTo(0, chatBotContainerRef.current.scrollHeight);
    };

    useEffect(() => {
        scrollToBottom();
    }, [userBotChat]);



    useEffect(() => {
        handleNameChange(agentName)
    }, [agentName])

    useEffect(() => {
        if (isLoadings) {
            setBotError(false)
        }
    }, [isLoadings])

    useEffect(() => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);
        const userEmail = decryptedRes?.user;
        if (userId) {
            setLoggedInUserId(userId);
            getAgentsByCategory();
        }
        if (userEmail) {
            setLoggedInUserEmail(userEmail);
            getAgentsByCategory();
        }
        const activeProject = localStorage.getItem("Active-Project")
        const parts = activeProject?.split('-');
        if (parts) {
            const projectId = parseInt(parts[0]);
            setProjectId(projectId)
        }
        setIsSuperUser(decryptedRes?.is_superuser?.toLowerCase() === "true")

    }, []);

    useEffect(() => {
        if (isEditView) {
            setEdit(true)
        }
        else {
            setEdit(false)
        }
    }, [])

    useEffect(() => {
        if (regenResp) {
            handleChatWithDoc();
            setRegenResp(false)
        }
    }, [regenResp]);

    useEffect(() => {
        if (isEditView) {
            fetchAgentInfo(agentId);
            const agentID = decodeURIComponent(decryptData(agentId));
            const parsedAgentID = parseInt(agentID, 10);
            getPromptsByAgent(parsedAgentID);
            isEditView = false;

        }
    }, [isEditView, agentId])

    useEffect(() => {
        const activeProject = localStorage.getItem("Active-Project");

        if (activeProject) {
            const parts = activeProject.split('-');
            const projectId = parseInt(parts[0]);

            if (!isNaN(projectId)) {
                getAgentsOfProject(projectId);
            }
        }
    }, [])

    useEffect(() => {
        if (starterClick) {
            handleChatWithDoc()
        }

    }, [starterClick])

    useEffect(() => {
        const userInfo = fetchLoginCookie();
        const decryptedRes = decryptData(userInfo);
        const userId = parseInt(decryptedRes?.id);
        if (userId) {
            fetchUserActiveAgentConfig(userId);
            setLoggedInUser(true);
            setLoggedInUserId(userId);
        }
    }, [agentId]);

    const handleNameChange = (newValue) => {
        const nameExists = agents.find(agent => agent.agent_name === agentName && agent.id !== category);

        if (nameExists && newValue !== comparedAgentName && newValue !== "") {
            setIsNameExists(true);
            setHasNameError(true);
        }
        else {
            setIsNameExists(false);
            setHasNameError(false);
        }
    }

    const updateAgentName = (name) => {
        setMyAgentName(name);
    };
    const updateAgentDesc = (desc) => {
        setMyAgentDesc(desc);
    };

    const updateAgentImageUrl = (imageUrl) => {
        setMyAgentImageUrl(imageUrl);
        setNewUrl(imageUrl)
    };

    const handleConversationStarter1 = (event) => {
        setSampleQuestion1(event.target.value);
    };

    const handleRemove = (event?) => {
        setSampleQuestion1('');
        setSampleQuestion1(sampleQuestion2 ? sampleQuestion2 : '');
        setSampleQuestion2(sampleQuestion3 ? sampleQuestion3 : '');
        setSampleQuestion3('');

    };

    const handleConversationStarter2 = (event) => {
        setSampleQuestion2(event.target.value);
    };
    const handlesecRemove = (event?) => {
        setSampleQuestion2('');
        setSampleQuestion2(sampleQuestion3 ? sampleQuestion3 : '')
        setSampleQuestion3('');

    };

    const handleConversationStarter3 = (event) => {
        setSampleQuestion3(event.target.value);
    };
    const handlethirdRemove = (event) => {
        setSampleQuestion3('');
    };

    const handleButtonClick = (buttonName: string) => {
        setActiveButton(buttonName);
    };
    const handleImgFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setNewUrl(imageUrl);
            updateAgentImageUrl(imageUrl)
            setAgentImageUrl(file)
        }
    };
    const handleSaveOrUpdateClick = async () => {
        const agentExists = agents.find(agent => agent.agent_name === agentName && agent.id !== category);
        if (isNameExists || agentExists) {
            Swal.fire({
                icon: 'error',
                title: 'Oops..',
                text: `Agent name "${agentName}" already exists in the current project. Please choose another name.`,
            });
            return;
        }
        else if (agentStatus === 'Draft') {
            setShowAccessModal(true);
        }
        else {
            if (category) {
                editAgentConfig();
            }
            else {
                createAgentConfig();
            }
        }
    }

    const createAgentConfig = async () => {
        setSaveOrUpdateLoading(true);
        try {
            const request: AgentCardRequest = {
                agent_name: agentName,
                user_id: loggedInUserId!,
                project_id: projectId,
                domain: domain,
                sample_q1: sampleQuestion1,
                sample_q2: sampleQuestion2,
                sample_q3: sampleQuestion3,
                prefix_prompt: promptValue,
                agent_image: agentImageUrl,
                cloned_from: clonedFrom,
                created_by: loggedInUserEmail,
                need_alias: needAlias,
                access: access,
                status: "Live",
                custom_instructions: custom_instructions,
                agent_type: selectedAgentTypeId,
                llm_config: selectedAgentLLMId!,
            };
            const result = await createAgent(request);
            setAgentStatus("Live");
            setSelectedAgent(result.id)
            setSaveOrUpdateLoading(false);
            if (result && result.id) {
                localStorage.setItem("Active-Prompt-Id", result.id.toString());
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Agent created successfully!',
                    confirmButtonText: 'Ok'
                }).then((res) => {
                    if (res.isConfirmed) {
                        setAgentName("");
                        setDomain("");
                        setSampleQuestion1("");
                        setSampleQuestion2("");
                        setSampleQuestion3("");
                        setPromptValue("");
                        setAgentImageUrl(agentImageUrl);
                        setClonedFrom(null);
                        setCustomInstructions(custom_instructions)
                        setChipCustomInstructions(custom_instructions)                      
                        const agentID = encodeURIComponent(encryptData(result.id));
                        window.dispatchEvent(new Event("activeAgent"));
                        setRefreshChatPageOnAgentCreation(true)
                        navigate(`/agent/${agentID}?tab=chat`)
                    }
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to Create Agent'
                });
                return
            };
        } catch (error) {
            setSaveOrUpdateLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to Create Agent'
            });
            return
        }
    };

    const editAgentConfig = async () => {
        setSaveOrUpdateLoading(true);
        try {

            const request: EditAgentCardRequest = {
                id: category!,
                agent_name: agentName,
                domain: domain,
                sample_q1: sampleQuestion1,
                sample_q2: sampleQuestion2,
                sample_q3: sampleQuestion3,
                prefix_prompt: promptValue,
                agent_image: agentImageUrl,
                cloned_from: clonedFrom,
                need_alias: needAlias,
                access: access,
                status: "Live",
                custom_instructions: custom_instructions,
                created_by: loggedInUserEmail,
                agent_type: selectedAgentTypeId,
                llm_config: selectedAgentLLMId!
            };
            const result = await editAgent(request);
            setAgentStatus("Live");
            setSaveOrUpdateLoading(false);
            if (result && result.id) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: isEditView ? 'Agent updated successfully!' : 'Agent created successfully!',
                    confirmButtonText: 'Ok'
                }).then((res) => {
                    if (res.isConfirmed) {
                        const agentID = encodeURIComponent(encryptData(result.id));
                        setRefreshChatPageOnAgentCreation(true)
                        navigate(`/agent/${agentID}?tab=chat`)
                        // setCurrentTab && setCurrentTab('chat');
                        // window.dispatchEvent(new Event("activeAgent"));
                        const eventData = { agentId: category };
                        const customEvent = new CustomEvent("activeAgent", { detail: eventData })
                        // Dispatch the custom event
                        window.dispatchEvent(customEvent);


                    }
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to Update Agent'
                });
            };
        } catch (error) {
            setSaveOrUpdateLoading(false);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to Update Agent'
            });
        }
    };



    const saveAgentCustomInstructions = async () => {
        try {
            const request: CreateCustomInstructionRequest = {
                agent: category!,
                custom_instructions: custom_instructions.slice(0, custom_instructions.length - 1),
                created_by: loggedInUserEmail,
            };
            if(category == null){
                setModalOpen(false)
                setCustomInstructions(custom_instructions)
                setChipCustomInstructions(custom_instructions)

            }
            if (category != null) {
                const result = await createCustomInstructions(request);
                if (result) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Custom instructions saved successfully!',
                        confirmButtonText: 'Ok'
                    }).then((res) => {
                        if (res.isConfirmed) {
                            setCustomInstructions(custom_instructions)
                            setChipCustomInstructions(custom_instructions)
                            setModalOpen(false)
                        }
                    })
                } 
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Failed to save custom instructions'
                    });
                    return
                };
            }
           
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to Create Custom Instructions'
            });
            return
        }

    };

    const goToAgentsPage = () => {
        navigate("/project/agents/agents")
    }

    const fetchAgentInfo = async (agentId) => {
        try {
            if (agentId) {
                const agentID = decodeURIComponent(decryptData(agentId));
                const parsedAgentID = parseInt(agentID, 10);
                let response;
                if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
                    response = await getOneAgentForMagicLink(parsedAgentID);
                } else {
                    response = await getOneAgent(parsedAgentID);
                }
                if (response) {
                    setCategory(response.id);
                    setAgentName(response.agent_name);
                    setAgentImageUrl(response.agent_image);
                    setDomain(response.domain);
                    setPromptValue(response.prefix_prompt);
                    setSampleQuestion1(response.sample_q1);
                    setSampleQuestion2(response.sample_q2);
                    setSampleQuestion3(response.sample_q3);
                    setClonedFrom(response.cloned_from);
                    setComparedAgentName(response.agent_name);
                    setAccess(response.access);
                    setAgentStatus(response.status);
                    setCustomInstructions(response.instructions.map((item: { instruction: any; }) => item.instruction))
                    setChipCustomInstructions(response.instructions.map((item: { instruction: any; }) => item.instruction))
                    setSelectedAgentType(response.agent_type.name)
                    setSelectedAgentTypeId(response.agent_type.id)
                    setAgentAlias(response.alias)
                }

            }
        } catch (error) {
            console.error(error);
        }
    };




    const getPromptsByAgent = async (id: number) => {
        try {
            setPromptsLoading(true);
            const response = await getPastPromptsByAgent(id);
            const filteredPrompts = response.slice(0, -1);
            const latestPrompts = filteredPrompts
                .filter((value, index, self) =>
                    index === self.findIndex((t) => t.prefix_prompt === value.prefix_prompt)
                )
                .slice(-20);
            const prompts = latestPrompts.map(item => ({
                prompt: item.prefix_prompt,
                date: item.history_date,
            }));
            setPastInstructions(prompts);
            setPromptsLoading(false);
        }
        catch (error) {
            console.error(error);
            setPromptsLoading(false);
        }
    };

    const handleUsePastPromptsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsePastPrompts(event.target.checked);
        if (!event.target.checked) {
            setSelectedPastPrompt(null);
        }
    };


    const handlePastPromptChange = (event: SelectChangeEvent<string>) => {
        setSelectedPastPrompt(event.target.value);
        const selectedPrompt = event.target.value;
        if (selectedPrompt) {
            setPromptValue(selectedPrompt)
        }
    };

    const showAllTemplates = () => {
        setShowTemplates(!showTemplates);
    };
    useEffect(() => {
        if (agentImageUrl === null || agentImageUrl === "null") {
            setAgentImageUrl(agentImageUrl);
        }
    }, [agentImageUrl]);

    const handleAgentCloneClick = async (id: number) => {
        try {
            let response;
            if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
                response = await getOneAgentForMagicLink(id);
            } else {
                response = await getOneAgent(id);
            }
            if (response) {
                setAgentName(response.agent_name);
                setMyAgentName(response.agent_name);
                setAgentImageUrl(response.agent_image);
                setDomain(response.domain);
                setPromptValue(response.prefix_prompt);
                setClonedFrom(response.id);
                setClonedFrom(response.id);
                setShowTemplates(!showTemplates);
                setSampleQuestion1(response.sample_q1);
                setSampleQuestion2(response.sample_q2);
                setSampleQuestion3(response.sample_q3);
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    function convertTextToLists(text) {
        const lines = text.split("\n");
        let isListActive = false;
        let html = "";

        lines.forEach(line => {
            const listMatch = line.match(/^(\d+\.|\*)\s+(.*)/);

            if (listMatch) {
                if (!isListActive) {
                    html += "<ul>";
                    isListActive = true;
                }
                html += `<li>${listMatch[2]}</li>`;
            } else {
                if (isListActive) {
                    html += "</ul>";
                    isListActive = false;
                }
                html += `<p>${line}</p>`;
            }
        });

        if (isListActive) {
            html += "</ul>";
        }

        return html;
    }


    const getAgentsByCategory = async () => {
        try {
            const response = await getAgentByCategory();
            setAgentsByCategoryData(response);
        } catch (error) {
            console.error(error);

        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !disabledSendButton) {
            handleChatWithDoc();
        }
    };
    const getAgentsOfProject = async (project_id: number) => {
        try {

            const response = await getAgentByProject(project_id);
            if (response) {
                setAgents(response)
            }

        } catch (error) {
            console.error(error);
        }
    }

    const makeApiRequest = async (question: string, cache_skip: boolean) => {
        setEmpty(false)
        let threadId;
        threadId = null
        lastQuestionRef.current = question;
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setIsLoadings(true);
        try {
            let activeAgentPrimaryId;
            if (category) {
                activeAgentPrimaryId = category;
            }
            else {
                if (agentId) {
                    activeAgentPrimaryId = decodeURIComponent(decryptData(agentId))

                } else {
                    activeAgentPrimaryId = localStorage.getItem("Active-Agent-Id")!;
                }
            }
            const userInfo = fetchLoginCookie();
            const decryptedRes = decryptData(userInfo);
            const userEmail = decryptedRes?.user;
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ReadRetrieveRead,
                category: activeAgentPrimaryId,
                user_id: loggedInUserId!,
                created_by: userEmail,
                chat_thread_id: threadId,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: true,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: true,
                    sources: useSources && toggleUseSources,
                    cache_skip: cache_skip,
                },
                model: selectedAgentLLMDeployement!,
            };
            const result = await chatApi(request);
            setAnswers([...answers, [question, result, result.chatlog_id, false, false, []]]);
            const botResponse: UserBotChatInterface = { role: 'bot', message: result?.answer };
            setUserBotChat(prevHistory => [...prevHistory, botResponse])
            setIsLoadings(false);
            setDisabledSendButton(false)
            setResponseIndex(responseIndex + 1);
        }
        catch (e) {
            setError(e);
            setIsLoadings(false);
            setBotError(true);
        }
        finally {
            setIsLoadings(false);

        }
    };

    const passQuery = () => {
        setStarterClick(true)
    }

    const handleAgentConfigName = async (id: number) => {
        try {

            let response;
            if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
                response = await getOneAgentForMagicLink(id);
            } else {
                response = await getOneAgent(id);
            }
            if (response) {
                fetchAgentLLM(response)
                setAgentConfigData(response);
                setAgentConfigName(response.agent_name);
                setAgentAlias(response.alias);
                if (response?.created_by === 'System') {
                    setUseSources(false)
                }
            }
        }
        catch (error) {
            console.error(error);

        }
    };

    const fetchUserActiveAgentConfig = async (id: any) => {
        try {
            if (agentId) {
                const agentID = decodeURIComponent(decryptData(agentId));
                const parsedAgentID = parseInt(agentID, 10);
                setAgentActiveConfig(parsedAgentID)
                setIsAgentConfigLoading(false);
                handleAgentConfigName(parsedAgentID);
            } else {
                fetchDefaultLLM()
                setIsAgentConfigLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsAgentConfigLoading(false);

        }
    };

    const handleChatWithDoc = async () => {

        if (question.trim() !== '') {
            setPreviousUserQuery(question);
            setQuestion('');
            const newMessage: UserBotChatInterface = { role: 'user', message: question };
            if (!regenResp) {
                setUserBotChat([...userBotChat, newMessage]);
            }
            setChatBtnClicked(true)
            setDisabledSendButton(true)
            try {
                await makeApiRequest(question, false);
            }
            catch (error) {
                console.error('Error:', error);
            }
        }

    };
    const handleRegenerateBotResponse = () => {
        setQuestion(previousUserQuery);
        setBotError(false);
        setRegenResp(true)

    };

    const handleConfirm = () => {
        if (category) {
            setShowAccessModal(false);
            setLoading(true)
            editAgentConfig();
            setLoading(false)
        }
        else {
            setShowAccessModal(false);
            setLoading(true)
            createAgentConfig();
            setLoading(false)
        }
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAccessValueChange = (event) => {
        setAccess(event.target.value);
    };
    const handleAccessChange = async (accessLevel) => {
        setAccess(accessLevel);
    };

    const validInstructions = chipCustomInstructions.filter(instruction => instruction.trim() !== '').slice(0, 4);
    const showMore = chipCustomInstructions.filter(instruction => instruction.trim() !== '').length > 4;

    const handleDelete = (indexToDelete) => {
        setChipCustomInstructions((currentInstructions) =>
            currentInstructions.filter((_, index) => index !== indexToDelete)
        );
        setCustomInstructions((currentInstructions) =>
            currentInstructions.filter((_, index) => index !== indexToDelete)
        );
       
    }

    return (

        <div style={{ backgroundColor: "white", height: "90vh", width: "100%", }}>
            <Modal size="sm" show={showAccessModal} onHide={() => setShowAccessModal(false)}>
                <Modal.Header closeButton className={styles.modalPadding}>
                    <Modal.Title>Share Agent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className={styles.shareAgentButtonContainer}>
                            <div className={styles.labelWithIcon}>
                                <i className="bi bi-people-fill me-2"></i>Public
                            </div>
                            <Form.Check
                                type="radio"
                                className={`customRadioButton ${styles.customRadioButton}`}
                                id={`public-access`}
                                name="accessLevel"
                                value="Public"
                                onChange={handleAccessValueChange}
                                checked={access === 'Public'}
                            />
                        </div>
                        <div className={styles.shareAgentButtonContainer}>
                            <div className={styles.labelWithIcon}>
                                <i className="bi bi-people-fill me-2"></i>Only team
                            </div>
                            <Form.Check
                                type="radio"
                                className={`customRadioButton ${styles.customRadioButton}`}
                                id={`public-access`}
                                name="accessLevel"
                                value="Only team"
                                onChange={handleAccessValueChange}
                                checked={access === 'Only team'}
                            />
                        </div>

                        <div className={styles.shareAgentButtonContainer}>
                            <div className={styles.labelWithIcon}>
                                <i className="bi bi-lock-fill me-2"></i>Only me
                            </div>
                            <Form.Check
                                type="radio"
                                className={`customRadioButton ${styles.customRadioButton}`}
                                id={`private-access`}
                                name="accessLevel"
                                value="Only me"
                                onChange={handleAccessValueChange}
                                checked={access === 'Only me'}
                            />
                        </div>
                        <div className={styles.agentInfoContainer}>
                            <img src={defaultImg} alt="Agent Image" className={styles.agentImage} />

                            <div className={styles.agentDetails}>
                                <h6 className={styles.agentName}>{myagentName}</h6>

                                <p className={styles.creatorUsername}>By {loggedInUserEmail}</p>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={styles.modalPadding}>
                    {loading && (
                        <button
                            type='button'
                            className={`btn me-3 ${styles.shareButton}`}
                        >
                            <span className='indicator-progress d-block' >
                                Please wait
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span></button>
                    )}
                    {!loading && (<>
                        <button
                            type='button'
                            className={`btn me-0 ${styles.shareButton}`}
                            data-kt-stepper-action='previous'
                            onClick={handleConfirm}
                            disabled={!access}
                        >
                            Share</button>
                    </>)}
                </Modal.Footer>
            </Modal>
            <div style={{ height: "100%" }}>
                <div className="d-flex align-items-center justify-content-between bg-white p-3 " style={{ borderBottom: "1px solid lightgray", width: "100%" }} >
                    <div style={{ width: "30%" }} className="d-flex align-items-center ">
                        <span className="mr-7 cursor-pointer " onClick={goToAgentsPage}>
                            <i className="fa fa-chevron-left" aria-hidden="true"></i>
                        </span>

                        {agentImageUrl ? (
                            typeof agentImageUrl === 'object' ? (
                                <img src={URL.createObjectURL(agentImageUrl)} alt="Agent Img" className={styles.circleAvatar} />
                            ) : (
                                <img src={agentImageUrl} alt="Agent Img" className={styles.circleAvatar} />
                            )
                        ) : (
                            <img src={agentImageUrl} alt="Agent Img" className={styles.circleAvatar} />
                        )}

                        <div style={{ marginLeft: "7px" }}>
                            <h6 style={{ marginTop: "3px", marginBottom: "0", fontWeight: "300" }}>{myagentName}</h6>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p className={styles.status}>
                                    {agentStatus === "Live" ? (
                                        <>
                                            <span className={styles.liveDot}></span>Live
                                        </>
                                    ) : (
                                        agentStatus
                                    )}
                                </p>
                                <span style={{ marginLeft: '8px', display: 'flex', alignItems: 'center', cursor: "pointer" }} onClick={handleClick}>
                                    {access === 'Public' ? (
                                        <>
                                            <Users className={styles.access} />
                                            <span style={{ fontSize: '0.75rem' }}>Public</span>
                                        </>
                                    ) : access === 'Only me' ? (
                                        <>
                                            <Lock className={styles.access} />
                                            <span style={{ fontSize: '0.75rem' }}>Only me</span>
                                        </>
                                    ) : (
                                        <>
                                            <UsersThree className={styles.access} />
                                            <span style={{ fontSize: '0.75rem' }}>Only team</span>
                                        </>
                                    )}
                                </span>
                                <Menu style={{ paddingTop: "0px", paddingBottom: "0px" }}
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <List>
                                        <ListItem
                                            onClick={() => handleAccessChange('Public')}
                                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                        >
                                            <div style={{ width: '24px', marginRight: '8px' }}>
                                                {access === 'Public' && <CheckIcon style={{ color: 'green' }} />}
                                            </div>
                                            Public
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                            onClick={() => handleAccessChange('Only team')}
                                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                        >
                                            <div style={{ width: '24px', marginRight: '8px' }}>
                                                {access === 'Only team' && <CheckIcon style={{ color: 'green' }} />}
                                            </div>
                                            Only team
                                        </ListItem>
                                        <Divider />
                                        <ListItem
                                            onClick={() => handleAccessChange('Only me')}
                                            style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                        >
                                            <div style={{ width: '24px', marginRight: '8px' }}>
                                                {access === 'Only me' && <CheckIcon style={{ color: 'green' }} />}
                                            </div>
                                            Only me
                                        </ListItem>
                                    </List>
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button style={{ backgroundColor: "#20808d", color: "white" }} className="btn btn-sm" disabled={saveOrUpdateLoading} onClick={handleSaveOrUpdateClick}>
                            {!saveOrUpdateLoading && (
                                <span>
                                    {isEditView ? 'Update' : 'Save'}
                                </span>)}
                            {saveOrUpdateLoading && (
                                <span className='indicator-progress d-block' >
                                    Please wait
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </div>

                <div style={{ width: "100%", paddingLeft: "2%", height: "85%" }}>
                    <div className="row flex-md-row" >

                        <div className="col-md-6" style={{ height: "78vh", borderRight: "2px solid lightgray" }}>
                            {chatAgentCreationFlag && <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col-md-8 col-sm-10">
                                        <div className={`${styles.buttonsBackground} d-flex justify-content-center`}>
                                            <button
                                                type="button"
                                                className={`btn btn-sm ${isCreateDivVisible ? '' : 'btn-outline-secondary'}`}
                                                style={{ width: "50%", marginRight: "5px", backgroundColor: isCreateDivVisible ? "white" : '#f9f9f9', border: isCreateDivVisible ? '1px solid #CED4DA' : 'none', borderRadius: "5px" }}
                                                onClick={() => {
                                                    setIsCreateDivVisible(true);
                                                    setIsConfigureDivVisible(false);
                                                }}
                                            >
                                                Create
                                            </button>
                                            <button
                                                type="button"
                                                className={`btn btn-sm ${isConfigureDivVisible ? '' : 'btn-outline-secondary'}`}
                                                style={{ width: "50%", backgroundColor: isConfigureDivVisible ? "white" : '#f9f9f9', border: isConfigureDivVisible ? '1px solid #CED4DA' : 'none', borderRadius: "5px" }}
                                                onClick={() => {
                                                    setIsCreateDivVisible(false);
                                                    setIsConfigureDivVisible(true);
                                                }}
                                            >
                                                Configure
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>}


                            {(isCreateDivVisible && !agentCreationLoading) && (
                                <div>
                                    <div className='d-flex flex-column' >
                                        <ChatInterface
                                            updateAgentName={updateAgentName}
                                            updateAgentDesc={updateAgentDesc}
                                            updateAgentImageUrl={updateAgentImageUrl}
                                            chatHistory={chatHistory}
                                            setChatHistory={setChatHistory}
                                            messages={messages}
                                            setMessages={setMessages}
                                            agentName={agentName}
                                            setAgentName={setAgentName}
                                            agentImageUrl={agentImageUrl}
                                            setAgentImageUrl={setAgentImageUrl}
                                            agentDesc={agentDesc}
                                            setAgentDesc={setAgentDesc}
                                            showImg={showImg}
                                            setShowImg={setShowImg}
                                            userMessage={userMessage}
                                            setUserMessage={setUserMessage}
                                            loading={loading}
                                            setLoading={setLoading}
                                            promptValue={promptValue}
                                            setPromptValue={setPromptValue}
                                            category={category}
                                            setCategory={setCategory}
                                            loggedInUserId={loggedInUserId!}
                                            setLoggedInUserId={setLoggedInUserId}
                                            loggedInUserEmail={loggedInUserEmail}
                                            setLoggedInUserEmail={setLoggedInUserEmail}
                                            projectId={projectId}
                                            setProjectId={setProjectId}
                                            domain={domain}
                                            setDomain={setDomain}
                                            edit={isEditView}
                                            disabled={disabled}
                                            setDisabled={setDisabled}
                                        />

                                    </div>
                                </div>
                            )}
                            {agentCreationLoading && <div
                                className='d-flex justify-content-center align-items-center'
                                style={{ minHeight: '600px' }}
                            >
                                <CircularProgress color='primary' />
                            </div>}

                            {isConfigureDivVisible && !agentCreationLoading && (
                                <div>
                                    <div className={`${styles.configureDiv} d-flex flex-column align-items-center`}>
                                        {!isEditView && (
                                            <div className="w-100 p-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-sm"
                                                    style={{ backgroundColor: 'white', border: '1px solid #CED4DA' }}
                                                    onClick={showAllTemplates}>
                                                    Use Templates
                                                </button>
                                            </div>
                                        )}
                                        {showTemplates && (
                                            <div className='modal-body py-lg-10 px-lg-10'>
                                                <PreconfigAgents setRefreshChatPageOnAgentCreation={setRefreshChatPageOnAgentCreation} onCloneClick={handleAgentCloneClick} currentUserId={loggedInUserId!}
                                                    onCloseClick={showAllTemplates} agentsByCategory={agentsByCategoryData} />
                                            </div>
                                        )}
                                        <div className='mt-3'>
                                            <label htmlFor="upload-btn">
                                                {agentImageUrl ? (
                                                    typeof agentImageUrl === 'object' ? (
                                                        <img src={URL.createObjectURL(agentImageUrl)} alt="Agent Img" className={styles.configureImage} />
                                                    ) : (
                                                        <img src={agentImageUrl} alt="Agent Img" className={styles.configureImage} />
                                                    )
                                                ) : (
                                                    <img src={agentImageUrl} alt="Agent Img" className={styles.circleAvatar} />
                                                )}


                                            </label>
                                            <input
                                                type="file"
                                                id="upload-btn"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                onChange={handleImgFileUpload}
                                            />
                                        </div>

                                        <div className=" w-100 p-3">
                                            <Tooltip title='The name of your agent' placement='top-start'><label htmlFor="input1" className="form-label cardHeadings">Name</label></Tooltip>
                                            <input type="text" className={`${styles.inputText} form-control`} id="input1" placeholder="Name your agent" value={myagentName} onChange={(e) => {
                                                setMyAgentName('')
                                                setAgentName(e.target.value)
                                                updateAgentName(e.target.value);
                                                handleNameChange(e.target.value);

                                            }} />
                                            {(isNameExists) && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        Agent with this name already exists
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className='mb-3 d-flex flex-column p-3 align-items-start' style={{ width: '100%', }}>
                                            <Tooltip title='Select Agent default LLM' placement='top-start'><label htmlFor="input1" className="mb-3 form-label cardHeadings">Agent Default LLM</label></Tooltip>

                                            <FormControl className='form-select form-select-solid fw-light' style={{ width: "100%", backgroundColor: "transparent" }}>
                                                <InputLabel className={`${styles.inputLabel} fw-light`} style={{ color: "#5e6278", }} shrink={false}>
                                                    {selectedAgentLLM}
                                                </InputLabel>
                                                <Select
                                                    className={`fw-light ${styles.customDropdown}`}
                                                    style={{ cursor: "pointer" }}
                                                    IconComponent={() => null}
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <div className={styles.customForm}>
                                                        {agentLLMConfigs.map(item => (
                                                            <FormControlLabel
                                                                key={item.id}
                                                                onClick={(e) => handleLLMRadioClick(item.id, item.model_name, item.model_deployment, e)}
                                                                control={<Radio checked={selectedAgentLLM === item.model_name} style={{ padding: '4px', marginLeft: "1%" }} />}
                                                                label={<Typography className="d-flex" style={{ fontSize: '0.8rem' }}>{item.model_name} </Typography>}
                                                            />
                                                        ))}

                                                    </div>
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className='mb-3 d-flex flex-column p-3 align-items-start' style={{ width: '100%', }}>
                                            <Tooltip title='Select Agent Type' placement='top-start'><label htmlFor="input1" className="mb-3 form-label cardHeadings">Agent Type</label></Tooltip>

                                            <FormControl className='form-select form-select-solid fw-light' style={{ width: "100%", backgroundColor: "transparent" }}>
                                                <InputLabel className={`${styles.inputLabel} fw-light`} style={{ color: "#5e6278", }} shrink={false}>
                                                    {selectedAgentType}
                                                </InputLabel>
                                                <Select
                                                    className={`fw-light ${styles.customDropdown}`}
                                                    style={{ cursor: "pointer" }}
                                                    IconComponent={() => null}
                                                    size="small"
                                                    fullWidth
                                                >
                                                    <div className={styles.customForm}>
                                                        {agentTypes.map(item => (
                                                            <FormControlLabel
                                                                key={item.id}
                                                                onClick={(e) => handleTypeRadioClick(item.id, item.name, e)}
                                                                control={<Radio checked={selectedAgentType === item.name} style={{ padding: '4px', marginLeft: "1%" }} />}
                                                                label={<Typography className="d-flex" style={{ fontSize: '0.8rem' }}>{item.name} {
                                                                    item.description ? <Tooltip title={item.description} placement='top-start'><Info style={{ marginLeft: "4%", marginTop: "-0.4%", cursor: "pointer" }} size={15} color="gray" weight="light" /></Tooltip> : <></>
                                                                } </Typography>}
                                                            />
                                                        ))}

                                                    </div>
                                                </Select>
                                            </FormControl>
                                        </div>



                                        <div className="mb-2 w-100 p-3">
                                            <Tooltip title='Short description of what this agent does' placement='top-start'><label htmlFor="input1" className="form-label cardHeadings">Description</label></Tooltip>
                                            <input type="text" className={`${styles.inputText} form-control`} id="input2" placeholder="Add a short description about what this agent does" value={domain} onChange={(e) => {
                                                setMyAgentDesc('')
                                                setDomain(e.target.value)
                                                updateAgentDesc(e.target.value);

                                            }} />
                                        </div>
                                        <div className="mb-4 w-100 p-3">
                                            <Tooltip title='What does this agent do? How does it behave? What should it avoid doing?' placement='top-start'><label htmlFor="input1" className="form-label cardHeadings">Instructions</label></Tooltip>
                                            <textarea style={{ fontWeight: "300", fontSize: "13px" }} rows={4} className="form-control" id="input3" placeholder="What does this agent do? How does it behave? What should it avoid doing?" value={promptValue} onChange={(e) => {
                                                setPromptValue(e.target.value);
                                            }} />
                                        </div>

                                        {isEditView && (
                                            <div className="mb-2 w-100" style={{ paddingLeft: "7px" }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={usePastPrompts}
                                                            onChange={handleUsePastPromptsChange}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                        />
                                                    }
                                                    label="Use past instructions"
                                                />
                                                {usePastPrompts && (
                                                    <FormControl fullWidth className='mb-2'>
                                                        <InputLabel id="past-instructions-label">Past instructions</InputLabel>
                                                        <Select
                                                            labelId="past-instructions-label"
                                                            id="past-instructions-select"
                                                            value={selectedPastPrompt || ''}
                                                            onChange={handlePastPromptChange}
                                                            input={<OutlinedInput label="Past Instructions" />}
                                                            MenuProps={{ PaperProps: { style: { width: 250, maxHeight: 300 } } }}>
                                                            {pastInstructions && pastInstructions.length > 0 ? (
                                                                pastInstructions.map((instruction, index) => (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={instruction.prompt}
                                                                        style={{ display: 'block', whiteSpace: 'normal', overflow: 'hidden' }}
                                                                        title={instruction.prompt + " - " + new Date(instruction.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                                                    >
                                                                        <div className={styles.ellipsistext}>
                                                                            {instruction.prompt}
                                                                        </div>
                                                                        <div className={styles.dateTextStyle}>
                                                                            {"Updated on: " + new Date(instruction.date).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
                                                                        </div>
                                                                    </MenuItem>
                                                                ))
                                                            ) : (
                                                                <MenuItem style={{ display: 'block', whiteSpace: 'normal', overflow: 'hidden' }}>
                                                                    No past instructions found.
                                                                </MenuItem>
                                                            )}

                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </div>
                                        )}

                                        <div className="w-100 mb-1" style={{ paddingLeft: "1.7%" }}>
                                            <label htmlFor="input1" className="form-label cardHeadings">Custom Instructions </label><Tooltip title='Custom instructions take priority and will not be replaced by standard instructions' placement='top-start'><Info style={{ marginLeft: "1%", marginTop: "-0.5%", cursor: "pointer" }} size={16} color="#20808d" weight="light" /></Tooltip>
                                            <span>
                                                <Tooltip title={custom_instructions.length > 0 && custom_instructions.some(instruction => instruction.trim() !== '') ? "Edit" : "Add"} placement='top-start'>
                                                    {custom_instructions.length > 0 && custom_instructions.some(instruction => instruction.trim() !== '') ?
                                                        <PencilSimple onClick={() => openCustomInstModal()} className={styles.customIcon} size={16} color="#20808d" weight="light" />
                                                        :
                                                        <Plus onClick={() => openCustomInstModal()} className={styles.customIcon} size={16} color="#20808d" weight="light" />
                                                    }
                                                </Tooltip>
                                            </span>
                                        </div>

                                        <div className="mb-2 w-100 p-3">
                                            <div className="w-100">
                                                <Grid container spacing={1}>
                                                    {validInstructions.map((instruction, index) => (
                                                        <Grid item xs={6} key={index}>
                                                            <Chip
                                                                label={instruction}
                                                                variant="outlined"
                                                                onDelete={() => handleDelete(index)}
                                                                style={{ width: '100%', maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', justifyContent: 'space-between' }}
                                                            />
                                                        </Grid>
                                                    ))}

                                                    {showMore && (
                                                        <Grid item style={{ display: 'flex', alignItems: 'center', paddingLeft: 8 }}>
                                                            <span className={styles.seeMorebtn} ><a onClick={openCustomInstModal} >
                                                                See more
                                                            </a></span>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </div>
                                        </div>

                                        <Modal style={{top:"7%"}} aria-labelledby="example-custom-modal-styling-title" size='lg' show={modalOpen} onHide={() => setModalOpen(false)} keyboard={false} backdrop="static" >
                                            <Modal.Header style={{ paddingBottom: "8px" }} className='d-flex flex-column align-items-start' >
                                                <h4 className='cardHeadings'>Custom Instructions</h4>
                                                <div className='d-flex' style={{ justifyContent: "space-between", width: "100%" }}>
                                                    <p style={{ marginBottom: '0', marginTop: '4px' }}>Note: These custom instructions will be saved automatically when you update your agent.</p>
                                                    
                                                </div>
                                            </Modal.Header>

                                            <Modal.Body className={styles.instructionsModal} >
                                                <div className='d-flex flex-column' >
                                                    {custom_instructions.map((instruction, index) => (
                                                        <div style={{ borderRadius: '7px' }} key={index} className={`input-group mt-2 w-100 `} >
                                                            <input
                                                                type="text"
                                                                className={`${styles.conversationInput} form-control ${index > 19 && index <= 29 ? 'border border-warning' : index >= 30 ? 'border border-danger' : ''}`}
                                                                value={instruction}
                                                                onChange={(e) => handleInstructionChange(e, index)}
                                                                autoFocus={emptyInputIndex !== -1 ? index === emptyInputIndex : index === 0}
                                                            />

                                                            <div className="input-group-append" >
                                                                <span className={`${styles.closeIcon} input-group-text ${index > 19 && index <= 29 ? 'border border-warning' : index >= 30 ? 'border border-danger' : ''}`} onClick={() => removeInstruction(index)}>
                                                                    <X size={16} weight="light" />
                                                                </span>
                                                            </div>
                                                        </div>

                                                    ))}
                                                    {custom_instructions.length > 20 && custom_instructions.length <= 30 && (
                                                        <span className="d-flex mt-6">
                                                            <Warning size={20} weight="light" />
                                                            <p className={styles.warningMsg}>Providing extensive instructions may lead to responses that are less clear and potentially stray from the main topic</p>
                                                        </span>
                                                    )}
                                                    {custom_instructions.length > 30 && (
                                                        <span className="d-flex mt-6">
                                                            <WarningOctagon size={20} weight="light" />
                                                            <p className={styles.warningMsg}>Such a large number of instructions are not recommended; answers may deviate significantly and may contain inaccuracies</p>
                                                        </span>
                                                    )}
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                    <button style={{ backgroundColor: "#20808d", color: "white", marginTop: "-1%", textAlign:"right" }} className="btn btn-sm" onClick={saveAgentCustomInstructions}>
                                                            <span  >
                                                                Save
                                                            </span>
                                                        </button> 
                                            </Modal.Footer>
                                        </Modal>
                                        <div className="mb-2 w-100 p-3" >
                                            <Tooltip title='Examples for the user to start conversation' placement='top-start'><label htmlFor="input1" className="form-label cardHeadings">Conversation Starters</label></Tooltip>
                                            <div className='d-flex flex-column'>
                                                <div className="input-group mt-2 w-100">
                                                    <input
                                                        type="text"
                                                        className={`${styles.conversationInput} form-control`}
                                                        value={sampleQuestion1}
                                                        onChange={handleConversationStarter1}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span className={`${styles.customCloseIcon} input-group-text`} onClick={handleRemove}>
                                                            <X size={18} weight="light" />

                                                        </span>
                                                    </div>

                                                </div>
                                                {sampleQuestion1 != "" && <div className="input-group mt-2 w-100">
                                                    <input
                                                        type="text"
                                                        className={`${styles.conversationInput} form-control`}
                                                        value={sampleQuestion2}
                                                        onChange={handleConversationStarter2}
                                                    />
                                                    <div className="input-group-prepend cursor-pointer">
                                                        <span className={`${styles.customCloseIcon} input-group-text`} onClick={handlesecRemove}>
                                                            <X size={18} weight="light" />

                                                        </span>
                                                    </div>

                                                </div>}
                                                {sampleQuestion2 && <div className="input-group mt-2 w-100">
                                                    <input
                                                        type="text"
                                                        className={`${styles.conversationInput} form-control`}
                                                        value={sampleQuestion3}
                                                        onChange={handleConversationStarter3}
                                                    />
                                                    <div className="input-group-prepend cursor-pointer">
                                                        <span className={`${styles.customCloseIcon} input-group-text`} onClick={handlethirdRemove}>
                                                            <X size={18} weight="light" />
                                                        </span>
                                                    </div>

                                                </div>}
                                            </div>

                                        </div>
                                        <div className="mb-2 w-100 p-3">
                                            <Tooltip title="Add data for indexing" placement='top-start'><label htmlFor="input3" className="form-label cardHeadings mb-4">Knowledge Base</label></Tooltip>
                                            {category === null ? (
                                                <Tooltip title="This button will become enabled once you create an agent" placement="top-start">
                                                    <p>
                                                        <button
                                                            disabled={category === null}
                                                            type="button"
                                                            className="btn btn-sm"
                                                            style={{ backgroundColor: 'white', border: '1px solid #CED4DA' }}
                                                        // onClick={() => setShowIndexModal(true)}
                                                        >
                                                            Upload
                                                        </button>
                                                    </p>
                                                </Tooltip>
                                            ) : (
                                                <p>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm"
                                                        style={{ backgroundColor: 'white', border: '1px solid #CED4DA' }}
                                                        onClick={() => setShowIndexModal(true)}
                                                    >
                                                        Upload
                                                    </button>
                                                </p>
                                            )}
                                        </div>
                                        {showIndexModal ? <IndexData showIndexModal={showIndexModal} setShowIndexModal={setShowIndexModal} handleButtonClick={handleButtonClick} categ={category!} emailAlias={agentAlias}></IndexData> : <></>}
                                    </div>
                                </div>
                            )
                            }
                        </div>

                        <div className={`${styles.previewMain} col-md-6 pt-2`}  >
                            <div className={styles.botChatContainer} ref={chatBotContainerRef} >
                                {chatBtnClicked ? (
                                    <div style={{ padding: "8px" }}>
                                        {userBotChat.map((message, index) => (
                                            <React.Fragment key={index}>
                                                <div style={{ width: "100%" }}>
                                                    {message.role === "user" && message.message && (
                                                        <>
                                                            <h6><PersonIcon style={{ color: "#20808d" }} />You</h6>
                                                            <p style={{ textAlign: "justify", lineHeight: "1.6" }}>{message.message}</p>
                                                        </>
                                                    )}
                                                    {message.role !== "user" && message.message && (
                                                        <>
                                                            <div style={{ display: "flex", justifyContent: "start" }}>
                                                                <img style={{ marginRight: "5px", borderRadius: "50%" }} src={newUrl} width={18} height={18} />
                                                                <h6 >{agentName}</h6>
                                                            </div>
                                                            <div style={{ textAlign: "justify", lineHeight: "1.6" }} dangerouslySetInnerHTML={{ __html: convertTextToLists(message.message) }} />
                                                        </>
                                                    )}
                                                </div>
                                            </React.Fragment>
                                        ))}
                                        {isLoadings && <div className='d-flex'><div className={styles.loader}>
                                            <BuildIcon className={styles.buildIcon} style={{ fontSize: "12px", color: "#20808d", marginLeft: "2px", textAlign: "center", marginBottom: "4px" }} />
                                        </div>
                                            <p style={{ marginLeft: "4px", paddingTop: "2px" }}>Generating Response...</p></div>
                                        }
                                        {boterror && (
                                            <div style={{ marginTop: '10px', textAlign: 'center' }}>
                                                <p style={{ color: 'gray' }}>There was an error generating a response</p>
                                                <button className={styles.regenerateBtn} onClick={handleRegenerateBotResponse}><LoopOutlinedIcon style={{ color: "white" }} /> Regenerate</button>
                                            </div>
                                        )}

                                    </div>
                                ) : (
                                    <div>
                                        <h3 className="text-center mt-5 ">Preview</h3>
                                        <div className="text-center mt-18">
                                            {agentImageUrl ? (
                                                typeof agentImageUrl === 'object' ? (
                                                    <img src={URL.createObjectURL(agentImageUrl)} alt="Agent Img" style={{ width: '75px', height: '75px', borderRadius: '50%' }} />
                                                ) : (
                                                    <img src={agentImageUrl} alt="Agent Img" style={{ width: '75px', height: '75px', borderRadius: '50%' }} />
                                                )
                                            ) : (
                                                <img src={agentImageUrl} alt="Agent Img" style={{ width: '75px', height: '75px', borderRadius: '50%' }} />
                                            )}
                                            <br />
                                            <h6 style={{ marginLeft: "8px", marginTop: "4px", fontWeight: "300" }}>{myagentName}</h6>
                                            <p style={{ textAlign: "center" }}>{domain}</p>
                                        </div>
                                        <div className={styles.sampleStarters} >
                                            {(sampleQuestion1 || sampleQuestion2 || sampleQuestion3) && (
                                                <div className={styles.showSampleQues}>

                                                    {sampleQuestion1 && (
                                                        <Tooltip title={sampleQuestion1}>
                                                            <div className={styles.sampleQuestion} onClick={() => {
                                                                setQuestion(sampleQuestion1);
                                                                passQuery();
                                                            }}>
                                                                {sampleQuestion1}
                                                            </div>
                                                        </Tooltip>
                                                    )}

                                                    {sampleQuestion2 && (
                                                        <Tooltip title={sampleQuestion2}>

                                                            <div className={styles.sampleQuestion} onClick={() => {
                                                                setQuestion(sampleQuestion2);
                                                                passQuery();
                                                            }}>
                                                                {sampleQuestion2}
                                                            </div>
                                                        </Tooltip>
                                                    )}

                                                    {sampleQuestion3 && (
                                                        <Tooltip title={sampleQuestion3}>
                                                            <div className={styles.sampleQuestion} onClick={() => {
                                                                setQuestion(sampleQuestion3);
                                                                passQuery();
                                                            }}>
                                                                {sampleQuestion3}
                                                            </div>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Input section */}
                            <div className={styles.previewInput}>
                                <div className="col-12">
                                    <div className="input-group " >
                                        <div className="input-group-prepend">
                                            <span className="input-group-text cursor-pointer" onClick={() => { setShowIndexModal(true); }}><AttachFileIcon></AttachFileIcon></span>
                                        </div>

                                        {showIndexModal ? <IndexData showIndexModal={showIndexModal} setShowIndexModal={setShowIndexModal} handleButtonClick={handleButtonClick} categ={category!} emailAlias={agentAlias} /> : null}

                                        <input style={{ borderRadius: "4px", height: "40px", }}
                                            type="text"
                                            className="form-control"
                                            placeholder={disabled ? "Start by defining your agent" : agentName ? `Message ${agentName}` : "Start By Defining Your Agent"}
                                            value={question}
                                            onChange={(e) => setQuestion(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                            disabled={disabled}

                                        />
                                        <button style={{ border: "none", padding: "0px", borderRadius: "4px" }} disabled={disabledSendButton} className=" input-group-prepend" onClick={handleChatWithDoc}>
                                            <div className="input-group-text cursor-pointer" >
                                                <ArrowUpwardIcon />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <Tooltip title="By enabling this toggle, the agent will respond only from your provided documents and links. If disabled, it will respond from publicly available information">
                                    <span>
                                        <FormControlLabel
                                            control={
                                                <IOSSwitch sx={{ m: 1 }}
                                                    checked={toggleUseSources}
                                                    // onChange={(event) => setUseSources(event.target.checked)}
                                                    onChange={(event) => {
                                                        setUseSources(event.target.checked);
                                                        setToggleUseSources(!toggleUseSources);
                                                    }}
                                                    name="toggleSwitch"
                                                    color="primary"
                                                    disabled={disabled}
                                                    size="small"
                                                />}
                                            label="Knowledge Base" />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >

    );



}
export { ConfigureAgent }

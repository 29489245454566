import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import KeyboardTabSharpIcon from '@mui/icons-material/KeyboardTabSharp';
import { decryptData, getOneAgent, getOneAgentForMagicLink } from "../../../api";
import { Example } from "./Example";
import styles from "./Example.module.css";
import { useLocation } from "react-router-dom";
import { GraduationCap, LightbulbFilament, PencilLine, SealQuestion, Sparkle } from "@phosphor-icons/react";

export type ExampleModel = {
    text: string;
    value: string;
    icon: string;
}
interface Props {
    onExampleClicked: (value: string) => void;
    agentPromptUpdate: boolean;
    setAgentPromptUpdate: Dispatch<SetStateAction<boolean>>;
    agentid: number;
}

export const ExampleList = ({ onExampleClicked, agentid, agentPromptUpdate, setAgentPromptUpdate }: Props) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shareQueryParam = decodeURIComponent(decryptData(queryParams.get('share')));
    const [sampleQ1, setSampleQ1] = useState<string | null>(null)
    const [sampleQ2, setSampleQ2] = useState<string | null>(null)
    const [sampleQ3, setSampleQ3] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const getActiveAgentDetail = async (id: any) => {
        try {
            let response;
            if (shareQueryParam === 'Allow-Edit-Only' || shareQueryParam === 'Allow-View-Only') {
                response = await getOneAgentForMagicLink(id);
            } else {
                response = await getOneAgent(id);
            }
            if (response) {
                setSampleQ1(
                    response.sample_q1
                      ? response.sample_q1?.length > 70
                        ? response.sample_q1?.slice(0, 71) + "..."
                        : response.sample_q1
                      : null
                  );
                  
                  setSampleQ2(
                    response.sample_q2
                      ? response.sample_q2?.length > 70
                        ? response.sample_q2?.slice(0, 71) + "..."
                        : response.sample_q2
                      : null
                  );
                  
                  setSampleQ3(
                    response.sample_q3
                      ? response.sample_q3?.length > 70
                        ? response.sample_q3?.slice(0, 71) + "..."
                        : response.sample_q3
                      : null
                  );
                  
                setIsLoading(false);
            }
        } catch (error) {

            console.error(error);

            setIsLoading(false);

            // Handle the error, e.g., show a default logo or display an error message

        }

    };




    useEffect(() => {

        if (agentid) {

            getActiveAgentDetail(agentid);
            if (agentPromptUpdate) {
                setAgentPromptUpdate(false)
            }
        }
    }, [agentid, agentPromptUpdate]);





    const exampleList = useMemo(

        () => [

            { text: sampleQ1 || "", value: sampleQ1!, icon: <LightbulbFilament size={20} color="#E2C541" weight="regular" />  },

            { text: sampleQ2 || "", value: sampleQ2!, icon: <PencilLine size={20} color="#CB8BD0" weight="regular" /> },

            { text: sampleQ3 || "", value: sampleQ3! , icon: <GraduationCap size={20} color="#76D0EB" weight="regular" /> }

        ],

        [sampleQ1, sampleQ2, sampleQ3]

    );



    return (
        <>
            <ul className={styles.examplesNavList}>
                {isLoading ? <></> : exampleList.map((example, index) => (
                    // Check if the text is not an empty string and not equal to default values
                    example.text !== "" &&
                    example.text !== "sampleQuestion1" &&
                    example.text !== "sampleQuestion2" &&
                    example.text !== "sampleQuestion3" &&
                    example.text !== "Sample Prompt 1" &&
                    example.text !== "Sample Prompt 2" &&
                    example.text !== "Sample Prompt 3" &&

                    <li key={index}>

                        <Example

                            text={example.text}

                            value={example.value}

                            onClick={onExampleClicked}

                            icon={example.icon}

                        />

                    </li>

                ))}



            </ul>
        </>
    );

};


import { useState, RefObject, useEffect, SetStateAction, Dispatch } from "react";
import { IconButton } from "@fluentui/react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'; // Import speech recognition library
import styles from './QuestionInput.module.scss'
import Tooltip from '@mui/material/Tooltip';
import { Microphone, MicrophoneSlash, ArrowUp, Lightning, CaretDown } from "@phosphor-icons/react";
import { decryptData, fetchLoginCookie, getActiveConfig, getAllConfigs, getAllData, getOneAgent, getOneAgentForMagicLink } from "../../../api";
import { toast } from 'react-toastify';
import { styled, useMediaQuery } from "@mui/material";
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton, MenuButton } from '@mui/base/MenuButton';
interface ConfigModel {
    id: number;
    model_name: string;
    model_deployment: string;
}
interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    style: string;
    sendStyles: string;
    useSources?: boolean;
    handleToggleChange: () => void;
    agentId: string;
    selectedModel: string;
    setSelectedOption: Dispatch<SetStateAction<string>>;
    question: string
    setQuestion: Dispatch<SetStateAction<string>>;
    llmConfigId: number | null;
}
const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#0275d8',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const QuestionInputEmpty = ({ question, setQuestion, agentId, sendStyles, style, onSend, disabled, placeholder, clearOnSend, handleToggleChange, useSources, selectedModel, setSelectedOption, llmConfigId }: Props) => {

    const { transcript, resetTranscript, listening } = useSpeechRecognition(); // Initialize speech recognition
    const [activeModelName, setActiveModelName] = useState<string>();
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [configModels, setConfigModels] = useState<ConfigModel[]>([])
    const [increaseHeight, setIncreaseHeight] = useState(false)
    const customWidth = '1160px';
    const isSmallScreen = useMediaQuery(`(max-width: ${customWidth})`);
    const [selectedDefaultAgentLLM, setSelectedDefaultAgentLLM] = useState<number|null>(-1);
    const [dataAvailable,  setDataAvailable] = useState<boolean>(false)
    const KBtooltipMessage = !dataAvailable
    ? "Upload data to enable it"
    : "By enabling Sources toggle, the agent will respond only from your loaded documents and links. By disabling it, the agent will respond from publicly available information.";

    useEffect(() => {
        const agentID = decodeURIComponent(decryptData(agentId));
        const parsedAgentID = parseInt(agentID, 10);
        fetchAllData((Number(parsedAgentID)));
      }, [])

      const fetchAllData = async (id: number, query?: string) => {
       
        try {
          const response = await getAllData(id, 1, query);
          if (response.data.length > 0) {
             setDataAvailable(true)
          }
          else{
             setDataAvailable(false);
          }
        } 
        catch (e) {
            console.error(e);
          } 
    }
    
    useEffect(() => {
        getAllLLMConfig()
    }, [])

    const fetchAgentInfo = async (agentId) => {
        try {
            if (agentId) {
                const agentID = decodeURIComponent(decryptData(agentId));
                const parsedAgentID = parseInt(agentID, 10);
                let response;
                response = await getOneAgent(parsedAgentID);
                if(response){
                    setSelectedDefaultAgentLLM(response.llm_config)
                }
            }
        } catch (error) {
            console.error(error);
        }
    };


    const updateActiveModelName = async () => {
        try {
            const configs = await getAllConfigs();
            const matchedConfig = configs.find(config => config.id === selectedDefaultAgentLLM);
         
            if (matchedConfig) {

                const llmConfigModel = matchedConfig.model_deployment[0];
                setSelectedOption(llmConfigModel)
                setActiveModelName(matchedConfig.model_name);
            }
        } 
        catch (error) {
            console.error("Error fetching configurations:", error);
        }
    }

    const updateModelForOrg = async () => {
        const response = await getActiveConfig();
        const llmConfigModelDep = response?.model_deployment;
        setSelectedOption(llmConfigModelDep);
        setActiveModelName(response?.model_name)
        localStorage.setItem('selectedLLMConfigModel', llmConfigModelDep);
    }

    useEffect(() => {
        if (selectedDefaultAgentLLM !== null) {
            updateActiveModelName();
        }
        else{
            updateModelForOrg()
        }

    }, [selectedDefaultAgentLLM]);

    useEffect(() => {
        fetchAgentInfo(agentId)
    }, [])

    const getAllLLMConfig = async () => {
        try {
            const response = await getAllConfigs();
            const configs = response.map(config => ({
                id: config.id,
                model_name: config.model_name,
                model_deployment: config.model_deployment
            }));
            setConfigModels(configs);
            
        } catch (error) {
            console.error(error);
        }
    };


    const getLLMConfig = async (configModels) => {
        const LLMConfigModel = localStorage.getItem('selectedLLMConfigModel');
        try {

            if (LLMConfigModel) {
                setSelectedOption(LLMConfigModel);
                const activeModel = configModels.filter((model) => model.model_deployment == LLMConfigModel)
                setActiveModelName(activeModel[0]?.model_name)
                
            } else if (llmConfigId) {
                const llmConfigModel = configModels.filter((model) => model.id === llmConfigId)
                setSelectedOption(llmConfigModel[0]?.model_deployment);
                setActiveModelName(llmConfigModel[0]?.model_name)
                localStorage.setItem('selectedLLMConfigModel', llmConfigModel[0]?.model_deployment);
            }
            else{
                const response = await getActiveConfig();
                const llmConfigModelDep = response?.model_deployment;
                setSelectedOption(llmConfigModelDep);
                setActiveModelName(response?.model_name)
                localStorage.setItem('selectedLLMConfigModel', llmConfigModelDep);
            }

        } catch (error) {
            console.error(error);
        }
    }
    const onModelChange = async (model) => {
        const llmConfigModel = model.model_deployment[0];
        if (llmConfigModel) {
            localStorage.setItem('selectedLLMConfigModel', llmConfigModel);
            setSelectedOption(llmConfigModel);
        }
        setActiveModelName(model.model_name)
    };
    useEffect(() => {
        if (transcript) {
            setQuestion(transcript); // Update the question with the recognized speech
        }
    }, [transcript]);

    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
        resetTranscript();
    };
    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);
        stopListening();

        if (clearOnSend) {
            setQuestion("");
            resetTranscript();
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = ev.target.value;
        if (newValue.length > 155) {
            setIncreaseHeight(true)
        }
        else {
            setIncreaseHeight(false)
        }
        if (!newValue) {
            setQuestion("");
            resetTranscript();
        } else if (newValue.length > 0) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();



    return (
        <div style={{ height: 'fit-content', display: 'flex', flexDirection: 'column', marginBottom: '1rem', background: activeTheme === 'dark' ? "#1e1e2d" : '#ffffff', justifyContent: 'space-between', borderRadius: '100px', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)' }} className={style}>

            <div style={{ height: increaseHeight ? '8rem' : '60px', width: '100%' }}>
                <textarea
                    style={{
                        border: 'none',
                        resize: 'none',
                        outline: 'none',
                        width: '100%',
                        height: increaseHeight ? '8rem' : '55px',
                        background: 'transparent',
                        color: activeTheme === 'dark' ? 'white' : 'black',
                        fontWeight: "300",
                    }}
                    placeholder={placeholder}
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    className={styles.scroll}
                    data-kt-element='input'
                />
            </div>

            <div className='d-flex ms-1 mt-1' style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <div>
                    <Dropdown >
                        <MenuButton className={styles.customButton} ><span><Lightning size={14} className="me-1" weight="fill" /><span className={styles.chipLabel}>{
                            activeModelName
                        }</span></span><CaretDown size={13} weight="light" /></MenuButton>
                        <Menu slots={{ listbox: Listbox }} className={styles.custom} style={{ zIndex: '10000', backgroundColor: 'white', margin: 0 }}>
                            <h5 className="pt-2 pb-2 text-start" style={{ fontWeight: 400, fontSize: '13px' }}>Select a custom model,<br></br> enhanced with Ejento AI</h5>
                            {
                                configModels.length > 0 && configModels.map((model) => (
                                    <MenuItem
                                        key={model.id}
                                        className={styles.customItem}
                                        style={{ color: activeModelName == model.model_name ? 'rgb(0, 122, 255)' : '', textTransform: 'capitalize' }}
                                        onClick={() => onModelChange(model)}
                                    ><span style={{ fontSize: '12px' }}>{model.model_name}</span></MenuItem>
                                ))
                            }
                        </Menu>
                    </Dropdown>

                </div>
                <div className='d-flex align-items-center'>
                    <div className='d-flex' style={{ justifyContent: 'center', alignItems: 'center' }} >
                        <Tooltip title = {KBtooltipMessage}>
                            <FormControlLabel
                                control={
                                    <IOSSwitch sx={{ mr: !isSmallScreen ? 1 : 0 }}
                                        disabled={!dataAvailable}
                                        checked={dataAvailable && useSources}
                                        onChange={handleToggleChange}
                                        name="toggleSwitch"
                                        color="primary"
                                    />}
                                label={!isSmallScreen ? "Knowledge Base" : ""}
                            />
                        </Tooltip>
                    </div>
                    <IconButton className={` ${sendStyles}`}
                        style={{ marginLeft: "3px" }}
                        onClick={listening ? stopListening : startListening}
                    >
                        {listening ? <Tooltip title='Stop Recording'><MicrophoneSlash size={20} weight="light" className={`${activeTheme === 'dark' ? 'iconStylesDarkTheme' : 'iconStylesLightTheme'}`} /></Tooltip> : <Tooltip title='Start recording'><Microphone className={`${activeTheme === 'dark' ? 'iconStylesDarkTheme' : 'iconStylesLightTheme'}`} size={20} weight="light" /></Tooltip>}</IconButton>
                    <IconButton
                        className={` ${sendStyles} d-flex justify-content-center m-0 p-0`}
                        type='button'
                        data-kt-element='send'
                        onClick={sendQuestion}
                        disabled={sendQuestionDisabled}
                        style={{ backgroundColor: sendQuestionDisabled ? '#e8e8ed' : '#196ed7', borderRadius: '50%' }}
                    >
                        <Tooltip title='Send'><ArrowUp size={20} color="white" weight="regular" className={`${activeTheme === 'dark' ? 'iconStylesDarkTheme' : 'iconStylesLightTheme'}`} /></Tooltip>
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#99CCF3',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E6',
    700: '#0059B3',
    800: '#004C99',
    900: '#003A75',
};

const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};

const Listbox = styled('ul')(
    ({ theme }) => `
    padding: 0px;
    min-width: 100px;
    width:fit-content;
padding:1rem;
padding-left:1.5rem;
padding-right:0;
    margin:0 !important;
    outline: 0px;
    z-index: 1;
    `,
);

const MenuItem = styled(BaseMenuItem)(
    ({ theme }) => `
    list-style: none;
    cursor: default;
    user-select: none;
    padding:6px;
  
    &:last-of-type {
      border-bottom: none;
    }
  
    &.${menuItemClasses.disabled} {
      color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
    }
    `,
);

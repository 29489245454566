import { initializeIcons } from "@fluentui/react";
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
// import { Modal, Button, Form } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, Autocomplete, Checkbox,} from "@mui/material";
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Swal from "sweetalert2";
import { UserTeamResponseByTeam, decryptData, fetchLoginCookie, deleteTeam, getTeamsByUser, UserTeamRequest, addUsersToTeam, EditUserTeamRequest, deleteUserFromTeam, EditTeamRequest, editTeam, getUsersByTeam, getAllUsers, User, TeamResponse, ProjectResponse, editUserRole, EditUserRoleRequest, EditUserRoleResponse } from "../../../../../api";
import { Modal } from "react-bootstrap";
import { SidebarMenuItem } from './SidebarMenuItem'
import { useNavigate } from "react-router-dom";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditRoleMenu from "./EditRoleMenu";
import styles from './sidemenuStyles.module.scss'
import { UserPlus } from "@phosphor-icons/react";

initializeIcons();

type Props = {
    orgId: number;
    userEmail: string;
    team_name?: string;
    team_id: number;
    created_by?: string;
    onTeamProjectEditSuccess: () => void;
    setTeam: Dispatch<SetStateAction<TeamResponse | null>> | undefined
    projectsExist: boolean
    setActiveTeam: Dispatch<SetStateAction<number>>
    // userProjectsData: UserProjectResponseByUser[]
    userProjectsData: ProjectResponse[]
    setRenderedProjectItems: Dispatch<SetStateAction<React.ReactNode[]>>
    
};

export const EditButtonTeam = ({ projectsExist, orgId, userEmail, team_name, team_id, created_by, userProjectsData, setRenderedProjectItems, onTeamProjectEditSuccess, setTeam, setActiveTeam }: Props) => {
    const navigate = useNavigate()
    const [allOrgUsers, setAllOrgUsers] = useState<User[]>([]);
    const [openTeamSettingsDialog, setOpenTeamSettingsDialog] = useState(false);
    const [teamName, setTeamName] = useState<string>("");
    const [teamCreatorEmail, setTeamCreatorEmail] = useState<string>("");
    const [editTeamId, setEditTeamId] = useState<number | null>(null);
    const [openTeamUsersAddDialog, setOpenTeamUsersAddDialog] = useState(false);
    const [teamUsersEmailsToAdd, setTeamUsersEmailsToAdd] = useState<string[]>([]);
    const [teamUsersData, setTeamUsersData] = useState<UserTeamResponseByTeam[]>([]);
    const [isTeamNameRequired, setIsTeamNameRequired] = useState(false);
    const activeTheme = localStorage.getItem("kt_theme_mode_value");
    const [inputValue, setInputValue] = useState<string>('');
    const userInfo = fetchLoginCookie();
    const decryptedRes = decryptData(userInfo);
    const isSuperUser = decryptedRes?.is_superuser?.toLowerCase() === "true";
    const uncheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [userRoleData, setUserRoleData] = useState<EditUserRoleResponse>();
    const [role, setRole] = useState<string>("");

    const removeMyTeam = async () => {
        if (projectsExist) {
            Swal.fire({
                title: 'Alert',
                text: "First delete all projects",
                icon: 'warning',

                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',

            })
        }
        else {
            handleTeamSettingsDialogClose();
            Swal.fire({
                title: 'Are you sure?',
                text: "This will delete the team.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        
                        const response = await deleteTeam(editTeamId!);
                        if (response.id) {
                            setTeam && setTeam(null)
                            const remainingTeams = await setNextTeam()
                            window.dispatchEvent(new Event("teams"));
                            window.location.reload();

                        }
                        setActiveTeam(-1)
                    } catch (error) {
                        console.error(error);
                    }
                }
            })
        }
    }

    const setNextTeam = async () => {
        try {
            const userInfo = fetchLoginCookie();
            const decryptedRes = decryptData(userInfo);
            const userId = parseInt(decryptedRes?.id);
            const response = await getTeamsByUser(userId);
            const active_team = response[response.length - 1].team.id

            if (response.length > 1) {
                localStorage.setItem("Active-Team", `${active_team.toString()}-${Math.random().toString(36).substring(2, 7)}-${Math.random().toString(36).substring(2, 7)}`)

                if (active_team && userProjectsData && userProjectsData.length > 0) {
                    const projectItems = userProjectsData
                        .map((userProject) => (
                            <SidebarMenuItem
                                key={userProject.id}
                                onclick={() =>
                                    localStorage.setItem(
                                        "Active-Project",
                                        `${userProject.id}-${Math.random().toString(36).substring(2, 7)}-${Math.random().toString(36).substring(2, 7)}`
                                    )
                                }
                                to="/project/agents/agents"
                                title={userProject.project_name}
                                hasBullet={false}
                                project_id={userProject.id}
                                userId={userId}
                                truncate={true}
                            />
                        ));

                    setRenderedProjectItems(projectItems);
                }
                navigate("/project/agents/agents")
            } else {
                {
                    localStorage.removeItem("Active-Team")
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    const addUsersToMyTeam = async () => {
        try {
            if (teamUsersEmailsToAdd.length == 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select the users from the list first',
                });

                return;
            }
            const selectedUserIds = allOrgUsers
                .filter((user) => teamUsersEmailsToAdd.includes(user.email))
                .map((user) => user.id);

            const request: UserTeamRequest = {
                team_id: editTeamId!,
                users: selectedUserIds,
                created_by: userEmail
            };
            const response = await addUsersToTeam(request);
            if (response.length) {
                setTeamUsersData(response);
                handleTeamUsersAddDialogClose();
                setTeamUsersEmailsToAdd([]);
                let element: HTMLElement = document.getElementsByClassName('MuiAutocomplete-clearIndicator')[0] as HTMLElement;
                element.click();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const removeOneTeamUser = async (user_id: number) => {
        try {
            const request: EditUserTeamRequest = {
                team_id: editTeamId!,
                user_id: user_id
            };
            const response = await deleteUserFromTeam(request);
            if (response.length) {
                setTeamUsersData(response);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const editMyTeam = async () => {
        if (!teamName) {
            setIsTeamNameRequired(true);
            return;
        }
        else {
            setIsTeamNameRequired(false);
            try {
                const request: EditTeamRequest = {
                    team_id: editTeamId!,
                    team_name: teamName,
                    modified_by: userEmail
                };
                const response = await editTeam(request);
                if (response.id) {
                    onTeamProjectEditSuccess();
                    setTeam && setTeam(response)
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    const getUsersOfTeam = async () => {
        try {
            const response = await getUsersByTeam(team_id);
            if (response.length) {
                setTeamUsersData(response);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getAllOrgUsers = async () => {
        try {
            const response = await getAllUsers(orgId);
            if (response.length) {
                setAllOrgUsers(response);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleTeamSettingsDialogOpen = () => {
        getAllOrgUsers();
        getUsersOfTeam();
        setTeamName(team_name!);
        setTeamCreatorEmail(created_by!);
        setEditTeamId((team_id)!);
        setOpenTeamSettingsDialog(true);
    };

    const handleTeamSettingsDialogClose = () => {
        setOpenTeamSettingsDialog(false);
        setTeamName('');
        setEditTeamId(null);
        setTeamUsersData([]);
    };


    const handleTeamNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setTeamName(event.target.value);
    };


    const handleTeamUsersAddDialogOpen = () => {
        setOpenTeamSettingsDialog(false);
        setOpenTeamUsersAddDialog(true);
    };

    const handleTeamUsersAddDialogClose = () => {
        setOpenTeamUsersAddDialog(false);
        setOpenTeamSettingsDialog(true);
    };
    const handleRoleUpdateClick = (userId) => (event) => {
        setAnchorEl(event.currentTarget);
        setSelectedUserId(userId);
        const selectedUser = teamUsersData.find(user => user.user.id === userId);
        setRole(selectedUser!.role);
    };

    const handleRoleUpdateClose = () => {
        setAnchorEl(null);
        setSelectedUserId(null);
    };
    const handleRoleUpdate = async (newRole) => {
        try {
            const request: EditUserRoleRequest = {
                team_id: editTeamId!,
                user_id: selectedUserId!,
                role: newRole,
                modified_by: userEmail
            };
            const response = await editUserRole(request);
            if (response) {
                // Update the state with the new user data
                setTeamUsersData(prevData => {
                    return prevData.map(user => {
                        if (user.user.id === selectedUserId) {
                            return { ...user, role: newRole };
                        }
                        return user;
                    });
                });
            }
        } catch (error) {
            console.error(error);
        }
        handleRoleUpdateClose();
    };
    const userRole = teamUsersData.find((entry) => entry.user.email === userEmail)?.role;


    return (
        <>
            {/* <p onClick={handleTeamSettingsDialogOpen} className="d-inline" ><Tooltip title='Edit Team'><ChevronRight fontSize="small"></ChevronRight></Tooltip></p> */}
            <div onClick={handleTeamSettingsDialogOpen}  style={{cursor:"pointer",fontSize: "15px",fontWeight:"300",lineHeight:"10px"}} ><span className="d-flex align-items-center"style={{gap:'0.2rem'}}><UserPlus size={20} weight="light" className={`${activeTheme === 'dark' ? 'iconStylesDarkTheme' : 'iconStylesLightTheme'}`}  /><span style={{cursor:"pointer",fontSize: "15px",fontWeight:"300",lineHeight:"10px"}}>Edit team and members</span> </span></div>
            <Modal className={`${styles.modalon}`} show={openTeamSettingsDialog}  onHide={handleTeamSettingsDialogClose} keyboard={true}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: '16px', fontWeight: '300' }}>Team Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-6'>
                        <TextField
                            required
                            hiddenLabel
                            disabled={created_by !== userEmail && !isSuperUser}
                            id="standard-required"
                            value={teamName}
                            style={{ color: activeTheme === 'dark' ? '#92929f' : 'black' }}
                            onChange={handleTeamNameChanged}
                            onBlur={editMyTeam}
                            inputProps={{ style: { fontSize: 16, width: 400, color: activeTheme === 'dark' ? 'white' : 'black' } }}
                            variant="standard"
                            autoComplete="on"
                            sx={{
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: activeTheme === 'dark' ? 'white' : 'black',
                                },
                            }}
                        />
                    </div>
                    {isTeamNameRequired && (
                        <div className='mb-5'>
                            <span style={{ color: 'red' }}>Team name is required</span>
                        </div>
                    )}
                    <div className='mb-8'>
                        {(userRole === "Owner" || isSuperUser) ? (<Button id="add" variant="outlined" startIcon={<GroupAddIcon />} onClick={handleTeamUsersAddDialogOpen}>
                            Add Users
                        </Button>) : null}
                    </div>
                    <div className='mb-10'>
                        <h3 style={{ marginLeft: "2%", fontSize: "16px", fontWeight: "500", lineHeight: "20px" }} className='mb-5'>
                            Users <span className="text-muted ">{"(" + teamUsersData.length + ")"}</span>
                        </h3>
                        <div className='mh-300px scroll-y me-n7 pe-7'>
                            {teamUsersData.map((useritem, index) => {
                                return (
                                    <div
                                        className='d-flex flex-stack py-1 border-bottom border-gray-300 border-bottom-dashed'
                                        key={index}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <div style={{ border: "2px solid #d3d3d3" }} className='symbol symbol-15px symbol-circle'>
                                                {useritem.user.email && (
                                                    <div className='symbol symbol-25px symbol-circle'>
                                                        <span
                                                            className={`symbol-label bg-light-info text-info fw-bold`}
                                                        >
                                                            {useritem.user.email.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className='ms-5'>
                                                <p style={{ fontSize: "13px", fontWeight: "540", }}
                                                    className=' text-hover-primary mb-1 '
                                                    color={activeTheme === 'dark' ? 'white' : 'text.secondary'}

                                                >{useritem.user.first_name + " " + useritem.user.last_name}
                                                </p>
                                                <p style={{
                                                    fontSize: "11px", fontWeight: "400", color: "rgb(109, 110, 111)"
                                                }}
                                                    className='fs-7 text-hover-primary mb-1'
                                                >
                                                    <div >{useritem.user.email}</div>
                                                </p>
                                                {(userRole === "Viewer" && !isSuperUser) ? (
                                                    <div style={{
                                                        fontSize: "10px", fontWeight: "300", color: "rgb(109, 110, 111)"
                                                    }} className=' fs-7 '>{useritem.role === "Owner" ? "Owner" : "Viewer"}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className='ms-2 w-100px'>
                                            {(userRole === "Owner" || isSuperUser) ? (
                                                <div
                                                    style={{ color: "grey", cursor: "pointer", display: "flex", alignItems: "center", fontSize: "11px" }}
                                                    // onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}
                                                    onClick={handleRoleUpdateClick(useritem.user.id)}
                                                >
                                                    {useritem.role.toLowerCase().charAt(0).toUpperCase() + useritem.role.slice(1)}
                                                    <ExpandMoreIcon style={{ marginLeft: "4px" }} />
                                                </div>
                                            ) : null}
                                            {(userRole === "Owner" || isSuperUser) ? (
                                                <EditRoleMenu
                                                    anchorEl={anchorEl}
                                                    handleClose={handleRoleUpdateClose}
                                                    handleRoleUpdate={handleRoleUpdate}
                                                    userRoleId={role}
                                                    removeOneTeamUser={removeOneTeamUser}
                                                    userRole={userRole}
                                                    userId={selectedUserId}
                                                    isSuper={isSuperUser}
                                                />
                                            ) : null}
                                            
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" onClick={handleTeamSettingsDialogClose}>
                        Close
                    </Button>
                    {(userRole === "Owner" || isSuperUser) ? (<Button sx={{ mx: 1.5 }} variant="contained" color="error" onClick={removeMyTeam}>
                        Delete Team
                    </Button>) : null}
                </Modal.Footer>
            </Modal>

            <Modal show={openTeamUsersAddDialog} onHide={handleTeamUsersAddDialogClose} keyboard={true}>
            <Modal.Header closeButton>
                    <Modal.Title style={{ fontSize: "113%" ,fontWeight:'300'}}>Select Users from your Organization to add to your Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-2'>
                        <div>
                        <Autocomplete
    size="small"
    limitTags={2}
    disableCloseOnSelect
    style={{ cursor: 'pointer', width: "93%", backgroundColor: activeTheme === 'dark' ? '#f0f0f0' : 'inherit', }}
    multiple
    onChange={(event: any, selectedOptions) => {
        const uniqueSelectedOptions = [...new Set(selectedOptions)];
        if (
            JSON.stringify(teamUsersData.sort()) !==
            JSON.stringify(uniqueSelectedOptions.sort())
        ) {
            setTeamUsersEmailsToAdd(uniqueSelectedOptions.map(option => option.email));
        }
    }}
    inputValue={inputValue}
    onInputChange={(event: any, newInputValue: string) => {
        setInputValue(newInputValue);
    }}
    options={allOrgUsers
        .filter((user) => user.email !== userEmail)
        .filter((user) => !teamUsersData.some((teamUser) => teamUser.user.id === user.id))
        .filter((user) => {
            const searchValue = inputValue.toLowerCase();
            const userName = `${user.first_name} ${user.last_name}`.toLowerCase();
            return userName.includes(searchValue);
        })
        .map((user) => ({
            name: `${user.first_name} ${user.last_name}`,
            email: user.email
        }))}
    sx={{
        marginLeft: "2%",
        ".MuiInputBase-root": {
            borderRadius: "7px !important", width: "99%",
        }
    }}
    getOptionLabel={(option) => option.name}
    isOptionEqualToValue={(option, value) => option.email === value.email}
    renderInput={(params) => (
        <TextField
            {...params}
            label="Add users by name"
            size="small"
            style={{ width: "100%" }}
            variant={activeTheme === 'dark' ? 'filled' : 'outlined'}
        />
    )}
    renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: "0px", borderBottom: "0.2px solid lightgray" }}>
            <Checkbox
                icon={uncheckedIcon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 3 }}
                checked={selected}
            />
            <span style={{ display: "flex", color: "Grey", fontSize: "0.94rem" }}>
                {option.name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{option.email}
            </span>
        </li>
    )}
/>

                        </div>

                    </div>

                </Modal.Body>
                <br></br>
                <Modal.Footer>
                    <Button variant="outlined" onClick={handleTeamUsersAddDialogClose}>
                        Close
                    </Button>
                    {(userRole === "Owner" || isSuperUser) ? (<Button sx={{ mx: 1.5 }} variant="contained" onClick={addUsersToMyTeam}>
                        Add
                    </Button>) : null}
                </Modal.Footer>
            </Modal>



        </>
    );
};



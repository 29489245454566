import {TotalCounts, getTotalCounts} from '../../../../../api'
import {useEffect, useState} from 'react'
import styles from './style.module.css'

import {Card, CardContent, CardHeader, Grid, Typography} from '@mui/material'

export const TopPanel = () => {
  const [totalCount, setTotalCount] = useState<TotalCounts>()
  useEffect(() => {
    getTotalCount()
  }, [])

  const getTotalCount = async () => {
    try {
      const response = await getTotalCounts()
      if (response.count_data) {
        setTotalCount(response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const tiles = [
    {
      title: 'Users',
      number: totalCount?.count_data.userCount,
      content: `${totalCount?.count_data?.userPercentage}% increase in last 30 days`,
    },
    {
      title: 'Teams',
      number: totalCount?.count_data.teamCount,
      content: `${totalCount?.count_data?.teamPercentage}% increase in last 30 days`,
    },
    {
      title: 'Projects',
      number: totalCount?.count_data.projectCount,
      content: `${totalCount?.count_data?.projectPercentage}% increase in last 30 days`,
    },
    {
      title: 'Agents',
      number: totalCount?.count_data.agentCount,
      content: `${totalCount?.count_data?.agentPercentage}% increase in last 30 days`,
    },
  ]

  return (
    <>
      <div className={`d-flex w-100 justify-content-center align-item-center flex-wrap`}>
        <Grid container spacing={2} style={{marginLeft:"10%", }}>
          {tiles.map((tile, index) => (
            <Grid item xs={12} sm={6} md={2.5} key={index}>
              <Card sx={{backgroundColor: 'white', color: 'grey'}}>
                <CardHeader
                  title={
                    <Typography variant='h6' align='center'>
                      {tile.title}
                    </Typography>
                  }
                  sx={{paddingBottom: 0}}
                />
                <CardContent sx={{paddingTop: 1}}>
                <Typography  align="center" sx={{ marginBottom: 1, fontSize: "2rem", color:"gray" }}>
                    {tile.number}
                  </Typography>
                  <Typography variant='body2' align='center'>
                    {tile.content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        {/* <div className={`${styles.tile} d-flex flex-column align-items-center`}>
                    <div className={`${styles.count}`}>{totalCount?.count_data.userCount}</div>
                    <span className={`${styles.text}`} >Users</span>
                    <p className={`${styles.percentageChange}`} >{totalCount?.count_data.userPercentage}% increase in last 30 days</p>
                </div>

                <div className={`${styles.vr}`}></div>

                <div className={` ${styles.tile} d-flex flex-column align-items-center`}>
                    <div className={`${styles.count}`}>{totalCount?.count_data.teamCount}</div>
                    <span className={`${styles.text}`} >Teams</span>
                    <p className={`${styles.percentageChange}`} >{totalCount?.count_data.teamPercentage}% increase in last 30 days</p>
                </div>

                <div className={`${styles.vr}`}></div>

                <div className={` ${styles.tile} d-flex flex-column align-items-center`}>
                    <div className={`${styles.count}`}>{totalCount?.count_data.projectCount}</div>
                    <span className={`${styles.text}`} >Projects</span>
                    <p className={`${styles.percentageChange}`} >{totalCount?.count_data.projectPercentage}% increase in last 30 days</p>
                </div>

                <div className={`${styles.vr}`}></div>

                <div className={` ${styles.tile} d-flex flex-column align-items-center`}>
                    <div className={`${styles.count}`}>{totalCount?.count_data.agentCount}</div>
                    <span className={`${styles.text}`} >Agents</span>
                    <p className={`${styles.percentageChange}`} >{totalCount?.count_data.agentPercentage}% increase in last 30 days</p>
                </div>

 */}
      </div>
    </>
  )
}
